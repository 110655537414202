import axios from 'axios';
import useAxios, { ResponseValues, UseAxiosResult } from 'axios-hooks';

declare global {
  interface Window {
    Config: any;
  }
}

const auth = async (token: Promise<string>) => {
  if (!token) throw new Error("no token")
  else return ({ headers: { Authorization: `Bearer ${await token}` } });
}

export const loginBySessionId = async (sessionId: string) =>
  axios.get(`${window.Config.SERVER_API_URL}/u/onetime-login/${sessionId}`);

export const loginBySessionId2 = async (sessionId: string) => {
  const { data: { access_token: accessToken } } = await axios.get(`${window.Config.SERVER_API_URL}/u/onetime-login/${sessionId}`);
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}

// interview plan
// export const sendInterviewSetup = async (interview, token: Promise<string>) => {
//     try {
//         const authHeaders = await auth(token);

//         return await axios.post(`${window.Config.SERVER_API_URL}/api/interviews`, interview, authHeaders);
//     } catch (e) {
//         console.log('e', e);
//         throw e;
//     }
// }

// // interview plan
// export const fetchInterviewPlan = async (interviewId, token: Promise<string>) => {
//     try {
//         const authHeaders = await auth(token);

//         return await axios.get(`${window.Config.SERVER_API_URL}/api/interviews/${interviewId}`, authHeaders);
//     } catch (e) {
//         console.log('e', e);
//         throw e;
//     }
// }


// export const fetchInterviewPlans = async (token: Promise<string>) => {
//     try {
//         const authHeaders = await auth(token);

//         return (await axios.get(`${window.Config.SERVER_API_URL}/api/interviews/`, authHeaders)).data;
//     } catch (e) {
//         console.log('e', e);
//         throw e;
//     }
// }

// interview session


export const findInterviewSessionByPlanId = async (planId, token: Promise<string>) => {
  try {
    const authHeaders = await auth(token);

    return await axios.get(`${window.Config.SERVER_API_URL}/api/interviews/${planId}/sessions`, authHeaders);
  } catch (e) {
    console.log('e', e);
    throw e;
  }
}
export const fetchInterviewSessionById = async (sessionId: string) => {
  try {
    return await axios.get(`${window.Config.SERVER_API_URL}/api/interviews/sessions/${sessionId}`);
  } catch (e) {
    console.log('e', e);
    throw e;
  }
}

export const generateQuestionsForRole = async (role): Promise<string[]> => {
  const response = await axios.post(`${window.Config.SERVER_API_URL}/api/interviews/questions`, { role });
  return response.data;
}

export const generateQuestionsByTags = async (role, tags) => {
  const response = await axios.post(`${window.Config.SERVER_API_URL}/api/interviews/tags`, { role, tags });
  return JSON.parse(response.data?.data?.interviewQs?.text);
}

export const generateTagsByMainTag = async (role: string) => {
  const response = await axios.post(`${window.Config.SERVER_API_URL}/api/questiny/generate-facets`, { role });
  return response.data;
}

export const fetchInsightsFromQuestions = async (sessionId: string, token: Promise<string>) => {
  try {
    const authHeaders = await auth(token);
    const { data } = await axios.get(`${window.Config.SERVER_API_URL}/api/interviews/sessions/${sessionId}`, authHeaders);

    console.log('data', data);
    return data?.assessment;

  } catch (e) {
    console.log('e', e)
    throw e;
  }
}

export const uploadAudioToServer = async (sessionId: string, questionId, blob, mimeType) => {

  try {
    const fileExtension = mimeType.split('/')[1].split(";")[0]; // Extract the file extension from the MIME type
    const fileName = `interview-audio-${sessionId}-${questionId}.${fileExtension}`; // Create the file name with the appropriate extension

    const formData = new FormData();
    formData.append('audio', blob, fileName);
    const response = await axios.post(`${window.Config.SERVER_API_URL}/api/upload`, formData, {
      params: { sessionId, questionId }
    });
    console.log(response.data);
    await new Promise((resolve) => setTimeout(resolve, 2500));
    return response.data;
  } catch (err) {
    console.error('Error uploading audio:', err);
    throw err;
  }

}

export const uploadAudioToWelshServer = async (sessionId: string, questionId, blob, mimeType) => {

  try {
    const fileExtension = mimeType.split('/')[1].split(";")[0]; // Extract the file extension from the MIME type
    const fileName = `interview-audio-${sessionId}-${questionId}.${fileExtension}`; // Create the file name with the appropriate extension

    const formData = new FormData();
    formData.append('audio', blob, fileName);
    const response = await axios.post(`${window.Config.SERVER_API_URL}/api/upload/welsh-audio`, formData, {
      params: { sessionId, questionId }
    });
    console.log(response.data);
    await new Promise((resolve) => setTimeout(resolve, 2500));
    return response.data;
  } catch (err) {
    console.error('Error uploading audio:', err);
    throw err;
  }

}

export const uploadAnswerTextToWelshServer = async (sessionId: string, questionId, answer) => {
  const data = { answer };
  console.log('data', data)
  try {
    const response = await axios.post(`${window.Config.SERVER_API_URL}/api/upload/welsh-text-answer`, data, {
      params: { sessionId, questionId }
    });
    console.log(response.data);
    await new Promise((resolve) => setTimeout(resolve, 2500));
    return response.data;
  } catch (err) {
    console.error('Error uploading audio:', err);
    throw err;
  }
}


export const downloadAudio = async (audioId: string) => {
  // ${interviewMetadata.questions[0].audio
  const downloadUrl = `${window.Config.SERVER_API_URL}/api/audio/${audioId}`;

  return axios.get(downloadUrl, {
    responseType: 'blob',
  });
}

export const chatCandidateSession = async (sessionId: string, token: Promise<string>, body) => {
  try {
    const authHeaders = await auth(token);
    const { data } = await axios.post(`${window.Config.SERVER_API_URL}/api/interviews/chat/${sessionId}`, body, authHeaders);

    console.log('data', data);
    return data;

  } catch (e) {
    console.log('e', e)
    throw e;
  }
}


export const sendEmail = async (sessionId: string, token: Promise<string>) => {
  try {
    const authHeaders = await auth(token);
    await axios.post(`${window.Config.SERVER_API_URL}/api/sessions/${sessionId}/email`, {}, authHeaders);
  } catch (e) {
    console.log('e', e)
    throw e;
  }

};

export const sendEmailBulk = async (appIds, interviewPlanId, token: Promise<string>) => {
  try {
    const authHeaders = await auth(token);
    await axios.post(`${window.Config.SERVER_API_URL}/api/sessions/bulk/email`, { appIds, interviewPlanId }, authHeaders);
  } catch (e) {
    console.log('e', e)
    throw e;
  }
};

export const uploadProjectDataWelsh = async (appIds, interviewPlanId, token: Promise<string>) => {
  try {
    const authHeaders = await auth(token);
    await axios.post(`${window.Config.SERVER_API_URL}/api/sessions/bulk/email`, { appIds, interviewPlanId }, authHeaders);
  } catch (e) {
    console.log('e', e)
    throw e;
  }
};


export const useFetchPregeneratedQuestions = (): UseAxiosResult => useAxios(
  `${window.Config.SERVER_API_URL}/api/questiny/pregenerate-questions`
);

export const useFetchJobs = (publicToken: string): UseAxiosResult => useAxios(
  `${window.Config.SERVER_API_URL}/api/data-sync/jobs?public_token=${publicToken}`
);

export const useFetchLinkTokenStart = (): UseAxiosResult => useAxios(
  `${window.Config.SERVER_API_URL}/api/data-sync/create-token`
);

export const fetchLinkTokenStart = async (email = "") => {
  try {
    return await axios.post(`${window.Config.SERVER_API_URL}/api/data-sync/create-token`, { email });
  } catch (e) {
    console.log('e', e);
    throw e;
  }
}

export const saveAccountToken = async (public_token) => {
  console.log('making save account token request')
  try {
    const response = await axios.post(`${window.Config.SERVER_API_URL}/api/data-sync/save-account-token?public_token=${public_token}`);
    console.log('making save account token request')
    return response;
  } catch (e) {
    console.log('e', e);
    throw e;
  }
}

export const fetchAccountIntegrationDetails = async () => {
  try {
    const response = await axios.get(`${window.Config.SERVER_API_URL}/api/data-sync/company-ats-integration`);
    return response.data;
  } catch (e) {
    console.log('e', e);
    throw e;
  }
}

export const deleteAccountIntegrationDetails = async () => {
  try {
    const response = await axios.delete(`${window.Config.SERVER_API_URL}/api/data-sync/company-ats-integration`);
    return response.data;
  } catch (e) {
    console.log('e', e);
    throw e;
  }
}

const useGenerateQuestionssAxio = (): UseAxiosResult => useAxios(
  {
    url: `${window.Config.SERVER_API_URL}/api/questiny/generate-questions`,
    method: 'POST',
  },
  { manual: true, autoCancel: false, useCache: true }
);


type GenericMetada = ResponseValues<any, any, any>;
type GenericResponse = Promise<string[] | undefined>;
type GenerateParams = { role: string, group?: string, subgroup?: string };
type Fetcher = (args: GenerateParams) => GenericResponse;

const catchException = (metadata: GenericMetada, callable: Fetcher): [GenericMetada, Fetcher] => [metadata, 
  (args: GenerateParams): GenericResponse => {
    try {
      return callable(args);
    } catch (e) {
      metadata.error = e.message
      return Promise.resolve(undefined);
    }
  }];

export const useGenerateQuestionsForRoleAndGroup = (): [GenericMetada, Fetcher] => {
  const [metadata, generateQuestion] = useGenerateQuestionssAxio();

  const generateQuestionsFromRole = async (params: GenerateParams): Promise<string[]> => {
    const response = await generateQuestion({ data: params });
    return response.data;
  };

  return catchException(metadata, generateQuestionsFromRole);
}