import { Navigate, RouteObject, useRoutes, useSearchParams } from 'react-router-dom';

// layouts
import { Screening2 } from 'pages/Screening2';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import UserPage from './pages/Candidates';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import Interview from './pages/Interview';
import AutomatedInterviewSetup from './pages/AutomatedInterviewSetup';
import Screening from './pages/Screening';
import InterviewDetail from './pages/InterviewDetail';
import InterviewSession from './pages/InterviewSession';
import WaitingQueue from './pages/WaitingQueue';
import Settings from './pages/Settings';
import ProtectedRoute from './components/auth/RequireAuth';
import WelshInterview from './components/welsh/WelshInterview';
import WelshInterviewSetup from './components/welsh/WelshInterviewSetup';
import { WelshScreening } from './components/welsh/WelshScreening';
import { RequireATSDetails } from './components/RequireATSDetails';
import JobPosts from './pages/JobPosts';
import JobPost from './pages/JobPost';
import JobPostSession from './pages/JobPostSession';
import EditInterview from './pages/EditInterview';
import AgentWelsh from './pages/AgentWelsh';
import ClientsView from './components/agent-welsh/ClientsView';
import AddWelshClient from './pages/AddWelshClient';

type ProtectedRouteObject = RouteObject & { 
  unprotect?: boolean
  children?: ProtectedRouteObject[]
}

const protectRoute = (route: ProtectedRouteObject) => 
  route.unprotect? route : ({ ...route, element: <ProtectedRoute>{route.element}</ProtectedRoute>})

const protectChildren = (route: ProtectedRouteObject) =>
 // eslint-disable-next-line @typescript-eslint/no-use-before-define
 !route.children ? route : ({ ...route, children: protectRoutesAndChildren(route.children) });

function protectRoutesAndChildren(routes: ProtectedRouteObject[]) {
  return routes
  .map(protectRoute)
  .map(protectChildren);
}

const useProtectedRoute = (routes: ProtectedRouteObject[]) => 
  useRoutes(protectRoutesAndChildren(routes));  


export default function Router() {
  const [searchParams] = useSearchParams()

  return useProtectedRoute([

    {
      path: '/dashboard',
      element: <DashboardLayout/>,
      children: [
        {
          path: 'agent-welsh',
          element: <ClientsView />,
          index: true
        },
        { path: 'client/:clientId', element: <AgentWelsh /> },
        { path: 'agent-welsh/add', element: <AddWelshClient /> },
        { path: 'agent-welsh/client/:clientId', element: <AgentWelsh /> },
        {
          path: 'settings',
          element: <Settings />,
        },
        { element: <Navigate to='/dashboard/agent-welsh' />, index: true }, // Changed fallback
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'jobs', element: (
            <RequireATSDetails>
              <JobPosts />
            </RequireATSDetails>
          ), },
        { path: 'jobs/:jobId', element: (
            <RequireATSDetails>
              <JobPost />
            </RequireATSDetails>
          ), },
        { path: 'jobs/:jobId/:applicationId', element: (
            <RequireATSDetails>
              <JobPostSession />
            </RequireATSDetails>
          ), },
        { path: 'interview', element: <Interview />,
          children: [
            { path: 'live', element: <AutomatedInterviewSetup /> },
            { path: ':id', element: <InterviewDetail /> },
            { path: 'edit/:id', element: <EditInterview /> },
            { path: 'session/:id', element: <InterviewSession /> },
          ]
        },
        {
          path: 'welsh',
          element: <WelshInterview />,
          children: [
            { path: 'live', element: <WelshInterviewSetup /> },
            { path: ':id', element: <InterviewDetail /> },
            { path: 'session/:id', element: <InterviewSession /> },
          ]
        },
        { path: 'candidates', element: <UserPage /> },
      ],
    },
    // {
    //   path: 'welshscreening/:interviewId',
    //   element: <WelshScreening />,
    //   unprotect: true,
    // },
    {
      path: 'screening/:interviewId',
      element: <Screening2 />,
      unprotect: true,
    },
    {
      path: 'waiting-queue',
      element: <WaitingQueue />,
      unprotect: true,
    },
    {
      path: 'callback',
      element: <SimpleLayout />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to={`/dashboard/agent-welsh?${searchParams}`} />, index: true },
        { path: '404', element: <Page404 />
      },
        { path: '*', element: <Navigate to='/404' />},
      ],
    },
    {
      path: '*',
      element: <Navigate to='/404' replace />,
    },
  ]);
}

