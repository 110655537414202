import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LockIcon from '@mui/icons-material/Lock';

const LockedView = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          mt: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <LockIcon sx={{ fontSize: 60, color: 'action.active', mb: 2 }} />
        <Typography variant="h4" component="h1" gutterBottom>
          Feature Locked
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          This section requires ATS integration. Please add your ATS details to access this feature.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/dashboard/settings')}
        >
          Go to Settings
        </Button>
      </Box>
    </Container>
  );
};

export default LockedView;
