import { PayloadAction, createSlice } from '@reduxjs/toolkit'


interface InterviewPlan {
    id?: string,
    processedData?: object
    metadata?: object
}

// Define the initial state using that type
const initialState: InterviewPlan = {
    id: undefined,
    metadata: undefined,
    processedData: undefined
}

export const interviewPlanSlice = createSlice({
    name: 'interviewPlan',
    initialState,
    reducers: {
        load: (state, { payload: { id } }: PayloadAction<InterviewPlan>) => {
            state.id = id
        },
        setMetadata: (state, { payload }: PayloadAction<{ metadata: object }>) => {
            state.metadata = payload.metadata
        },
        setProcessedData: (state, { payload }: PayloadAction<{ processedData: object }>) => {
          state.processedData = payload.processedData
      }
    }
})


export const { load, setMetadata, setProcessedData } = interviewPlanSlice.actions
