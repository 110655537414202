import { createListenerMiddleware, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { TbUser, userSlice } from './user'

export const listenerMiddleware = createListenerMiddleware()


const injectAuthorizationHederInAxios = async (action: PayloadAction<TbUser>) => {
  console.log('User added: ', action.payload.name);
  if (action.payload.token) {
    console.log('Found token:');
    axios.defaults.headers.common.Authorization = `Bearer ${action.payload.token}`
  }
}

listenerMiddleware.startListening({ actionCreator: userSlice.actions.setUser, effect: injectAuthorizationHederInAxios });


