import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Box, Typography, Paper, Button, CircularProgress, Tooltip, Snackbar, Alert, LinearProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { useMergeLink } from '@mergeapi/react-merge-link'; // Ensure this path is correct
import { useAuth0 } from '@auth0/auth0-react';
import Lottie from 'lottie-react';
import PowerBtnOn from 'animations/power-btn.json'; // Ensure this path is correct
import PowerBtnOff from 'animations/power-btn-off.json';
import { fetchAccountIntegrationDetails, fetchLinkTokenStart, saveAccountToken, deleteAccountIntegrationDetails } from '../utils/service';
import { companyATSDetailsSelector, loadCompany } from '../store/company';




export default function Settings() {
  const [isPowerOn, setIsPowerOn] = useState(false); // State to track if the power is on or off
  const lottieRef = useRef();
  const [linkToken, setLinkToken] = useState(''); // State to store the link token
  const [publicTokenFromServer, setPublicToken] = useState(''); // State to store the public token
  const companyATSDetails = useSelector(companyATSDetailsSelector);
  const syncCompleted = companyATSDetails?.syncCompleted;
  const user = useSelector(state => state.user);
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const atsName = companyATSDetails ? companyATSDetails.name : null;



  // Enhanced togglePower function to play animation once
  const togglePower = () => {

    // Ensure the Lottie component is correctly referenced and play or reset the animation
    if (lottieRef.current) {
      lottieRef.current.play(); // Reset the animation to start
    }
  };

  const onSuccess = useCallback(async (public_token) => {
    setPublicToken(public_token);
    await saveAccountToken(public_token);
    const company = await fetchAccountIntegrationDetails()
    dispatch(loadCompany(company))
  }, []);

  const { open, isReady } = useMergeLink({
    linkToken, // Use the state here
    onSuccess,
  });

  const createToken = async () => {
    try {
      const response = await fetchLinkTokenStart(user?.email)
      setLinkToken(response.data.linkToken);
    } catch (e) {
      console.error('error', e);

    }
  }

  const handleDisconnect = async () => {
    togglePower();
    await deleteAccountIntegrationDetails();
    const company = await fetchAccountIntegrationDetails();
    dispatch(loadCompany(company));
  };



  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Settings
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Settings and options for your application.
        </Typography>

        <Paper variant="outlined" sx={{ p: 2, my: 2 }}>
          <Typography variant="body1" gutterBottom>
            Your application is currently on the Enterprise plan.
          </Typography>
        </Paper>
        <Box component="form" noValidate autoComplete="off" sx={{width: '60%', margin: '0 auto', display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
          {
            atsName ? (
                <div style={{width: '80%', margin: '0 auto'}}>
                  <Lottie lottieRef={lottieRef} autoplay loop={false} animationData={PowerBtnOn} />
                </div>

            ) : (
                <div style={{width: '80%', margin: '0 auto'}}>
                  <Lottie lottieRef={lottieRef} autoplay={false} loop={false} animationData={PowerBtnOff} onClick={async (ev) => {
                    togglePower()
                    await createToken()
                    open(ev)
                  }}/>
                </div>
            )
          }

          {
            atsName ? (
                <Box sx={{ margin: '0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                  <Typography variant="h4" textAlign="center" gutterBottom>
                    Connected to {atsName}
                  </Typography>



                  {!syncCompleted ? (
                      <div>
                        <Typography variant="body1" textAlign="center" gutterBottom>
                          Your data synchronization with {atsName} is currently underway. Please check back in about 5 to 10 minutes. Thank you for your patience!
                        </Typography>
                        <LinearProgress />
                        <Typography variant="body1" textAlign="center" gutterBottom>
                          In the meantime, you can still use the platform to create and manage your interview plans.
                        </Typography>
                      </div>

                  ) : (
                      <div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <Typography variant="subtitle1" textAlign="center" gutterBottom sx={{marginRight: '10px'}}>
                            Sync Complete
                          </Typography>
                          <CheckCircleIcon color="success" />
                        </div>
                        <Typography variant="body" textAlign="center" gutterBottom>
                          Your data synchronization with with {atsName} is complete. You are now able to send interviews to applicants within your job posts. We appreciate your patience!
                        </Typography>

                      </div>

                  )}

                  <Button
                      variant="contained"
                      color="error"
                      onClick={handleDisconnect}
                      sx={{marginTop: '20px'}}
                  >
                    Disconnect ATS
                  </Button>




                </Box>

            ) : <Button variant="contained" color="primary" onClick={async (ev) => {
              togglePower()
              await createToken()
              open(ev)
            }}>
              Connect ATS
            </Button>
          }
          {/* Use the Lottie component with the reference and dynamic animation data */}
          { /* <Lottie lottieRef={lottieRef} autoplay={false} loop={false} animationData={PowerBtnOff} onClick={togglePower}/> */}
        </Box>


      </Box>
    </Container>
  );
}
