import React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';

const DonutChart = ({ label, num = 0 }) => {
    let color = '';
    let renderLabel = label;
    if (num > 70) {
      color = '#7AFF87'
      renderLabel = 'Optimal Fit'
    }
    if (num >= 50 && num <= 70) {
      color = '#ffe27a'
      renderLabel = 'Potential Fit'
    }
    if (num < 50) {
      color = '#ff7a7a'
      renderLabel = 'Not Fit'
    }

    const data = [
        { label: 'Rating', value: num, color },
        { label: "", value: 100 - num, color: 'white'},
    ];

    return (
        <div style={{ position: 'relative', width: '70% !important'}}>
            <PieChart
                series={[
                    {
                        innerRadius: 100,
                        outerRadius: 120,
                        data,
                    },
                ]}
                width={400}
                height={300}
                legend={{ hidden: true }}
            />
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '45%',
                transform: 'translate(-50%, -50%)',
                fontWeight: 'bold'
            }}>
                <div>
                    <p style={{fontSize: '26px', marginBottom: '0px'}}>{num}</p>
                    <p style={{fontSize: '16px', fontWeight: 500}}>{renderLabel}</p>
                </div>

            </div>
        </div>
    );
};

export default DonutChart;
