import React, { useState, useEffect } from 'react';
import {
	Grid,
	TextField,
	Button,
	styled,
	Chip,
	FormControlLabel,
	Checkbox,
	Typography,
	Tooltip,
	IconButton,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import styledComp from "styled-components"
import Skeleton from 'react-loading-skeleton'
import { Stack } from '@mui/system';
import Iconify from "../components/iconify";
import 'react-loading-skeleton/dist/skeleton.css'
import { AnyAnswerAcceptablePrompt } from '../utils/constants';



const AnswerBtn = styled(Button)`
		position: absolute;
		top: -40px;
		left: 4px;
`

const TempQuestions = styledComp.div`
		display: flex;
		flex-wrap: wrap;
		
		& > div {
				margin-right: 5px;
				margin-bottom: 5px;
		}
`

const AnswerPreferences = styledComp.div`
		display: flex;
		flex-direction: column;
		background: #f4f5f6;
		padding: 15px 20px;
		border-radius: 6px;
		border: 1px solid rgb(211 212 212);
`

const MustsContainer = styledComp.div`
		display: flex;
		flex-direction: row;
		margin-top: 10px;
`

const QnAForm = ({ questions, setQuestions, roleQs = [], isLoading = false, selected, handleDeleteQuestion, handleEditQuestionMusts, handleEditQuestion, handleEditQuestionDoNotRate, editIndex = null, initialQuestion = "", initialDoNotRate = false,  initialMustHave = "",  initialMustNotHave = "", doneEditQuestion  }) => {
	const [currentQuestion, setCurrentQuestion] = useState(initialQuestion);
	const [currentMustHave, setCurrentMustHave] = useState(initialMustHave);
	const [currentMustNotHave, setCurrentMustNotHave] = useState(initialMustNotHave);
	const [showAnswer, setShowAnswer] = useState(false);
	const [isNotRated, setIsNotRated] = useState(initialDoNotRate);
	const isEditMode = editIndex !== null;
	useEffect(() => {
		setCurrentQuestion(initialQuestion);
		setCurrentMustHave(initialMustHave);
		setCurrentMustNotHave(initialMustNotHave);
		setIsNotRated(initialDoNotRate);
	}, [initialQuestion, initialMustHave, initialMustNotHave, initialDoNotRate]);

	const handleQuestionChange = (event) => {
		setCurrentQuestion(event.target.value);

	};

	const handleMustChange = (event) => {
		setCurrentMustHave(event.target.value);
	};

	const handleMustNotChange = (event) => {
		setCurrentMustNotHave(event.target.value);
	};

	const handleCancel = () => {
		doneEditQuestion()
	}

	const handleDelete = () => {
		setCurrentQuestion("");
		setCurrentMustHave("");
		setCurrentMustNotHave("");
		setIsNotRated(false);
		handleDeleteQuestion(editIndex)
	}

	const handleSubmit = () => {
	if(isEditMode) {
		handleEditQuestion(editIndex, currentQuestion)
		handleEditQuestionMusts(editIndex, currentMustHave, currentMustNotHave)
		handleEditQuestionDoNotRate(editIndex, isNotRated)
		doneEditQuestion()
		return;
	}

	let preferredAnswer = `Must: ${currentMustHave}\n Must Not: ${currentMustNotHave}`;
	if(isNotRated) {
			preferredAnswer = AnyAnswerAcceptablePrompt
		}
		setQuestions([
			...questions,
			{
				question: currentQuestion,
				isNotRated,
				preferredAnswer
			}
		]);
		setIsNotRated(false)
		setCurrentQuestion("");
		setCurrentMustHave("");
		setCurrentMustNotHave("");
		setShowAnswer(false); // Hide the answer input after submitting
};

	const toggleAnyAnswerAcceptable = () => {
		setIsNotRated(!isNotRated);
	};

	const handleAddTempQuestion = (event) => {
		setCurrentQuestion(event.target.innerText)
	}

	return (
		<Grid container spacing={3} id='question-start' marginBottom='50px'>
			<Grid item xs={12}>

				<Stack direction={'column'} alignItems={'start'} gap="5px" >
					{
						roleQs.map((label, i) => (
							<Chip key={i} label={label} value={label} variant="outlined"
								onClick={handleAddTempQuestion} />
						))
					}
				</Stack>
				{isLoading && <Skeleton count={3} />}
			</Grid>
			<Grid item xs={12}>
				<TextField
					value={currentQuestion}
					onChange={handleQuestionChange}
					label="Question"
					variant="outlined"
					fullWidth
					multiline
					rows={4}
				/>
			</Grid>
			<Grid item xs={12}>
				<AnswerPreferences>
					<Typography variant="subtitle1" gutterBottom>
						Answer Preferences (optional)
					</Typography>
					<MustsContainer>
						<TextField
							value={currentMustHave}
							disabled={isNotRated}
							onChange={handleMustChange}
							label="Must"
							variant="outlined"
							fullWidth
							multiline
							rows={4}
						/>
						<TextField
							sx={{ marginLeft: 1 }}
							value={currentMustNotHave}
							disabled={isNotRated}
							onChange={handleMustNotChange}
							label="Must Not"
							variant="outlined"
							fullWidth
							multiline
							rows={4}
						/>
					</MustsContainer>
					<FormControlLabel
						sx={{ marginTop: 1 }}
						control={
							<Checkbox
								checked={isNotRated}
								onChange={toggleAnyAnswerAcceptable}
							/>
						}
						label="Do not rate"
						// label="Any reasonable answer is acceptable"
					/>
				</AnswerPreferences>

			</Grid>
			{/* )} */}
			<Grid item xs={12}>
				<Grid container columnGap={'5px'} justifyContent={'space-between'} alignItems={'center'}>
					<Grid item>
						<Grid container columnGap={'5px'}>
							<Grid item>
								<Button variant="contained" color="info" onClick={handleSubmit} disabled={currentQuestion === ''}>
									Save Question
								</Button>
							</Grid>
							{isEditMode && (
								<Grid item>
									<Button variant="contained"  color="inherit" onClick={handleCancel}>
										Cancel
									</Button>
								</Grid>
							)}
						</Grid>
					</Grid>
					<Grid item>
						{isEditMode && (
							<Tooltip title="Delete" placement="top">
								<IconButton onClick={handleDelete}>
									<DeleteOutlineIcon color="error" />
								</IconButton>
							</Tooltip>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default QnAForm;
