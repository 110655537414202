import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tabs,
  Tab,
  FormControl, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Checkbox, Typography, Radio, RadioGroup
} from '@mui/material';
import Candidates from 'pages/Candidates';
import { useCreateSessionMutation } from 'store/api';
import CsvUpload from 'components/CsvUpload';

const AddCandidate = ({ open, close, planId }) => {
  const [selected, setSelected] = useState<any[]>([]);
  const [tabIndex, setTabIndex] = useState(0); // New state for tab index
  const [createSession] = useCreateSessionMutation();
  const [jobPost, setJobPost] = useState('');
  const [importOption, setImportOption] = React.useState('allApplicants');
  // const [importOptions, setImportOptions] = useState({
  //   allApplicants: false,
  //   phoneScreened: false,
  // });

  const handleJobPostChange = (event) => {
    setJobPost(event.target.value);
  };

  const handleImportOptionChange = (event) => {
    setImportOption(event.target.value);
  };

  const handleImport = () => {
    // Your import logic here
    console.log(`Importing: ${importOption}`);
  };

  const add = (candidates: string[]) => {
    candidates.forEach((candidateId: string) => {
      createSession({ planId, candidateId });
    });
    close();
  };


  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return open ? (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Select Candidates</DialogTitle>
      <DialogContent style={{ height: '50vh' }}>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="candidate tabs">
          <Tab label="Upload" />
          <Tab label="Add Existing" />
          {/* <Tab label="ATS Job Post" /> */}
        </Tabs>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          {tabIndex === 0 && (
            <CsvUpload planId={planId} close={close}/>
            )}
          {tabIndex === 1 && (
            <Candidates
              onSelectionChanged={(s) => setSelected(s)}
              expandedView={false}
            />
          )}
          {tabIndex === 2 && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', flexDirection: 'column', padding: '0px 20px'}}>
              <Typography variant="h6" paddingTop={0} paddingLeft={2} marginBottom={4}>
                Sync applicants Job Posts
              </Typography>
              <FormControl>
                <InputLabel id="job-post-select-label">Job Post</InputLabel>
                <Select
                  labelId="job-post-select-label"
                  id="job-post-select"
                  value={jobPost}
                  label="Job Post"
                  onChange={handleJobPostChange}
                  sx={{marginBottom: '20px'}}
                >
                  <MenuItem value={1}>Front End Engineer</MenuItem>
                  <MenuItem value={2}>Account Executive</MenuItem>
                  <MenuItem value={3}>Director of Finance</MenuItem>
                </Select>

                <RadioGroup
                  aria-labelledby="import-option-radio-buttons-group"
                  name="import-option-radio-buttons"
                  value={importOption}
                  onChange={handleImportOptionChange}
                >
                  <FormControlLabel value="allApplicants" control={<Radio />} label="Import All Applicants" />
                  <FormControlLabel value="phoneScreened" control={<Radio />} label="Import Applicants Marked for Phone Screen" />
                </RadioGroup>

                <Button variant="contained" onClick={handleImport} sx={{marginTop: '20px'}}>Import</Button>
              </FormControl>
            </div>
            )}

        </div>
      </DialogContent>

    </Dialog>

  ) : null;
};

export const useAddCandidate = (planId: string) => {
  const [visible, setVisible] = useState(false);

  const show = () => setVisible(true);
  const close = () => setVisible(false);
  return { visible, show, close, AddCandidate: <AddCandidate open={visible} close={close} planId={planId} /> };
};
