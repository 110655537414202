import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Papa from 'papaparse';
import { Button, List, ListItem, ListItemText, Input, Typography, Paper, Container, Box } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/system';
import { useAddCandidateMutation } from 'store/api';
import { useAuth0 } from '@auth0/auth0-react';
import { sendEmail } from '../utils/service';
import { companyName } from '../store/user';

const UploadButton = styled(Button)({
  marginTop: '16px',
  marginBottom: '8px',
});

const CandidatesContainer = styled(Paper)({
  marginTop: '16px',
  padding: '20px',
});

function CsvUpload({ planId, close }) {
  const [candidates, setCandidates] = useState([]);
  const company = useSelector(companyName);
  const [ addCandidate ] = useAddCandidateMutation();
  const { getAccessTokenSilently } = useAuth0()

  const handleFileUpload = event => {
    const file = event.target.files[0];

    Papa.parse(file, {
      header: true,
      complete(results) {
        setCandidates(results.data);
      }
    });
  };

  const uploadCandidates = async () => {
    const candidatePromises = candidates.map(async (candidate) => {

      const data = {
        "name": candidate.name,
        "email": candidate.email,
        "roles": [],
        "client": company,
        "status": "active",
        "planId": planId
      };

      try {
        const response = await addCandidate(data);

        if (response?.data) {
          const email = response.data.candidate?.email;
          const sessionId = response.data.session?.insertedId;
          if (email && sessionId) {
            await sendEmail(sessionId, getAccessTokenSilently());
          }
          return true;
        } else {
          console.error('An error occurred:', response.error);
          return null;
        }
      } catch (error) {
        console.error('An error occurred during the API call:', error);
        return null;
      }
    });

    // Wait for all promises to resolve
    await Promise.all(candidatePromises);
    close()
  };



  return (
    <Container>
      <Box display="flex" flexDirection="column" marginTop="20px">
        <Typography variant="h6" gutterBottom>
          Upload Candidates
        </Typography>
        <Typography variant="h7" gutterBottom>
          Supported file formats: <b>CSV</b>
        </Typography>
        <Typography variant="h7" gutterBottom>
          Required Columns: <b>name, email</b>
        </Typography>
        <Typography variant="h7" gutterBottom>
          <b>All candidate's with an email will be sent the interview.</b>
        </Typography>
        <Input
          accept=".csv"
          id="contained-button-file"
          multiple
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileUpload}
        />
      </Box>

      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="contained-button-file">
        <UploadButton variant="outlined" component="span" startIcon={<CloudUploadIcon />}>
          Choose file
        </UploadButton>
      </label>
      {candidates.length > 0 && (
        <CandidatesContainer elevation={3}>
          <Typography variant="h6" gutterBottom>
            Candidates List
          </Typography>
          <List>
            {candidates.map((candidate, index) => (
              <ListItem key={index}>
                <ListItemText primary={`${candidate.name} - ${candidate.email}`} />
              </ListItem>
            ))}
          </List>
          <Button onClick={uploadCandidates}>Upload Candidates</Button>
        </CandidatesContainer>
      )}
    </Container>
  );
}

export default CsvUpload;
