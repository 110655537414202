import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Typography,
  Button,
  Grid,
  Container,
  Stack,
  Box,
  Tab,
  TextField,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Card,
  CardContent,
  LinearProgress,
  ButtonGroup,
  Alert,
  Toolbar,
  Snackbar,
  Skeleton,
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Lottie from 'lottie-react';
import moment from 'moment-mini';
import { Helmet } from 'react-helmet-async';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import fileDownload from 'js-file-download';

import MarkdownPreview from '@uiw/react-markdown-preview';
import LoopIcon from '@mui/icons-material/Loop';
import DownloadIcon from '@mui/icons-material/Download';
import CopyIcon from '@mui/icons-material/FileCopy';
import LoadingReport from 'animations/loading-report.json';
import { useFetchWelshClientQuery, useFetchWelshClientFilesQuery, useCreateWelshReportMutation,
  useRewriteSectionReportMutation, useDeleteWelshClientFileMutation, useGenerateResearchMutation } from '../store/api';
import Label from "../components/label";


const AgentWelsh = () => {
  const [companyName, setCompanyName] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [report, setReport] = useState([]);
  const [savedReport, setSavedReport] = useState([]);
  const [welshCall, setWelshCall] = useState(false);
  const [reportSummary, setReportSummary] = useState('Lorem ipsum');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState(null);
  const [showCopy, setShowCopy] = useState(false);
  const [isReportLoading, setIsReportLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('1');
  const [existingReports, setExistingReports] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [researchReport, setResearchReport] = useState('');

  const [notifyBarDelete, setNotifyBarDelete] = useState(false);
  const [sectionNameRewriteLoading, setSectionNameRewriteLoading] = useState(false);

  const [openNotify, setOpenNotify] = useState(false);
  const vertical = 'top';
  const horizontal = 'center';


  const handleClick = (newState) => () => {
    setOpenNotify(true);
  };

  const handleClose = () => {
    setOpenNotify(false);
  };

  const { clientId } = useParams();

  const [createWelshReport, { isSuccess: createWelshReportSuccess, error: createWelshReportError, data: createReportResponse, isLoading }] = useCreateWelshReportMutation();

  const [rewriteSectionReport, { isSuccess: rewriteSectionReportSuccess, error: rewriteSectionReportError, data: rewriteSectionReportResponse, isLoading: rewriteSectionReportLoading }] = useRewriteSectionReportMutation();

  const [deleteWelshClientFile, { isSuccess: deleteWelshClientFileSuccess, error: deleteWelshClientFileError, data: deleteWelshClientFileResponse, isLoading: deleteWelshClientFileLoading }] = useDeleteWelshClientFileMutation();

  const [generateResearch, { isSuccess: generateResearchSuccess, error: generateResearchError, data: generateResearchResponse, isLoading: generateResearchLoading }] = useGenerateResearchMutation();



  const { data: clientData, refetch } = useFetchWelshClientQuery(clientId, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    pollingInterval: 60000,
  });

  const { data: clientFiles, refetch: clientFilesRefetch } = useFetchWelshClientFilesQuery(clientId, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    pollingInterval: 60000,
  });

  const clientDataResponse = clientData?.response;

  useEffect(() => {
    if(clientFiles && clientFiles.files) {
      setDataSource(clientFiles.files)
    }
  }, [clientFiles]);

  // useEffect for setCompanyName
  useEffect(() => {
    if (clientDataResponse && clientDataResponse.companyName) {
      setCompanyName(clientDataResponse.companyName);
    }

    if (clientDataResponse && clientDataResponse.companyWebsite) {
      setCompanyWebsite(clientDataResponse.companyWebsite)
    }

  } , [clientDataResponse]);


  useEffect(() => {

    if (clientDataResponse && clientDataResponse.report) {
      setExistingReports(clientDataResponse.report);
    }
  }, [clientDataResponse]);

  useEffect(() => {
    if (generateResearchSuccess) {
      setResearchReport(generateResearchResponse?.response?.choices[0]?.message?.content)
    }
  }, [generateResearchSuccess]);

  const generateReport = async () => {
    try {
      setIsReportLoading(true);
      setCurrentTab('2');
      // Call the API to generate the report
      const client = {
        companyName,
        clientId,
      }
      const response = await createWelshReport(client)
    } catch (error) {
      setIsReportLoading(false);
      console.error('Error generating report:', error);
    } finally {
      setIsReportLoading(false);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    // Define the maximum file size limit (500 MB)
    const MAX_FILE_SIZE_MB = 500;
    const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

    if (!file) {
      console.warn("No file selected.");
      return;
    }
    console.log('file', file)

    const formData = new FormData();
    formData.append('file', file);
    formData.append('clientId', clientId);
    try {
      const response = await axios.post(
          `${window.Config.SERVER_API_URL}/api/agent-welsh/upload`,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              console.log('Upload progress:', Math.round((progressEvent.loaded / progressEvent.total) * 100));
              console.log('Upload progress:', progressEvent);
              const progress = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
              );
              setOpenNotify(true)
            },
          }
      );

      console.info("File upload successful:", response.data);
      console.debug("Transcription data:", response.data.transcription);

    } catch (error) {
      console.error("File upload failed:", error);

      setDataSource((prevData) => [
        ...prevData,
        {
          name: file.name,
          progress: 0,
          error: true,
          errorMessage: 'Upload failed. Please try again later.',
        },
      ]);

      setUploadError(error.message);
    } finally {
      await clientFilesRefetch();
      setOpenNotify(false)
    }
  };



  const handleDownloadClick = () => {
    const copiedReport = existingReports.map((section) => {
      // Remove **text** to just text without any bold formatting
      return section?.content?.replace(/\*\*(.*?)\*\*/g, '$1');
    });

    const plainTextContent = copiedReport.join('\n\n'); // Join with new lines

    fileDownload(plainTextContent, `${companyName}-exhibit-c-${moment().format('MM-DD-YY-HHmm')}.txt`, 'text/plain');

    setShowCopy({ text: 'Downloaded' });

    setTimeout(() => {
      setShowCopy(false);
    }, 1000); // 1000 milliseconds = 1 second
  };
  const handleCopyClick = async () => {
    const copiedReport = existingReports.map((section) => {
      return section?.content?.replace(/\*\*(.*?)\*\*/g, '$1');
    });
    setShowCopy({ text: 'Copied' });
    await navigator.clipboard.writeText(copiedReport.join('\n\n')); // Adding a newline after each join
    setTimeout(() => {
      setShowCopy(false);
    }, 1000); // 1000 milliseconds = 1 second
  };



  const formatBytes = (bytes) => {
    if (!bytes || Number.isNaN(bytes)) return 'N/A'; // Use Number.isNaN for safety
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / (k ** i)).toFixed(2))} ${sizes[i]}`; // Use template literals and ** operator
  };


  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const rewriteSection = async (section) => {
    setSectionNameRewriteLoading(section?.name)
    const sectionReady = { ...section, clientId };
    await rewriteSectionReport(sectionReady);
    setSectionNameRewriteLoading(false);
  };

  const toggleDescription = () => {
      setIsCollapsed(!isCollapsed);
  };

  const deleteUploadedFile = async (file) => {
      setNotifyBarDelete(true);
      await deleteWelshClientFile({clientId, fileId: file.id});

      // wait 5 seconds
      await new Promise(resolve => setTimeout(resolve, 5000));
      await clientFilesRefetch();

  }

  const handleCloseDelete = () => {
    setNotifyBarDelete(false);
  }

  const clickResearch = async () => {
    await generateResearch({ clientId, companyName, companyWebsite });
  }

  let content = isCollapsed ? `${clientDataResponse?.companyDescription.substring(0, 200)}` : clientDataResponse?.companyDescription;
  if(content && content.length > 200) content = `${content}...`;

  return (
    <>
      <Helmet>
        <title>Agent Welsh</title>
      </Helmet>
      <Container>
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={notifyBarDelete}
            onClose={handleCloseDelete}
            autoHideDuration={3000}
            message="Deleting File"
            key={`${vertical + horizontal  }delete`}
        />
        <TabContext value={currentTab}>
        <Grid container marginBottom={'60px'}>
            <Grid container>
                <Grid item xs={12} sx={{ margin: '20px 0px 0px 16px' }} >
                    <Box display={'flex'} justifyContent="space-between">
                        <Typography variant='h5' marginRight="10px">
                          {companyName}
                        </Typography>
                        <Typography variant='h5' color="#747474" marginRight="20px">
                            Welsh Client
                        </Typography>

                    </Box>

                    <Grid sx={{ marginTop: '25px' }} container>



                        <Grid item xs={3}>
                            <Typography variant='body1' noWrap>
                                Company Website
                            </Typography>
                            <Typography variant='subtitle1' noWrap>
                                {clientDataResponse?.companyWebsite}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant='body1'>
                                Agent Status
                            </Typography>
                            {
                                (!isLoading && !rewriteSectionReportLoading) && <Label color={'success'}>Ready for task</Label>
                            }
                            {
                                (isLoading) && <Label color={'info'}>Generating Report</Label>
                            }
                            {
                                (rewriteSectionReportLoading) && <Label color={'info'}>Rewriting Section</Label>
                            }
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '20px' }}>
                <Grid container columnSpacing={2} rowSpacing={{ xs: 2 }}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'column', padding: '16px', marginTop: '10px' }}>
                            <Typography variant="subtitle1" display="block">
                                Company Description
                            </Typography>
                            <Typography variant="body1">
                                <div dangerouslySetInnerHTML={{ __html: content }} />
                                {
                                  content && content.length > 200 && <Button onClick={toggleDescription}>
                                        {isCollapsed ? 'View More' : 'View Less'}
                                    </Button>
                                }

                            </Typography>
                        </Box>

                        {/* <KpiWidget title='Total Applicants' total={totalApplicants} color='info' icon={'basil:document-outline'} /> */}
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={3}> */}
                    {/*  <KpiWidget title='Completed Interviews' total={completeCount} color='success' icon={'mdi:check-circle'} /> */}
                    {/* </Grid> */}

                    {/* <Grid item xs={12} sm={6} md={3}> */}
                    {/*  <KpiWidget title='Pending Interviews' total={pendingCount} color='warning' icon={'mdi:clock'} /> */}
                    {/* </Grid> */}

                    {/* <Grid item xs={12} sm={6} md={3}> */}
                    {/*  <KpiWidget title='Cancelled Interviews' total={rejectedCount} color='error' icon={'mdi:cancel'} /> */}
                    {/* </Grid> */}
                </Grid>
            </Grid>
          {/* <Grid item xs={12} md={12}> */}
          {/*  <Typography variant="h4" gutterBottom align="start"> */}
          {/*    {clientDataResponse?.companyName} */}
          {/*  </Typography> */}
          {/* </Grid> */}
          {/* <Grid item xs={6} md={6} sx={{paddingTop: '0 !important'}}> */}
          {/*  <Typography variant="subtitle2" gutterBottom align="start"> */}
          {/*    {clientDataResponse?.companyWebsite} */}
          {/*  </Typography> */}
          {/* </Grid> */}
          {/* <Grid item xs={6} md={6} sx={{paddingTop: '0 !important'}}> */}
          {/*  <Typography variant="subtitle2" gutterBottom align="start"> */}
          {/*    {clientDataResponse?.companyDescription} */}
          {/*  </Typography> */}
          {/* </Grid> */}
        </Grid>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Client Data" value="1" />
            <Tab label="Reports" value="2" />
            <Tab label="Research" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Grid container spacing={3} justifyContent="center" sx={{ mt: 3 }}>
              <Grid item xs={6} md={6} justifyContent="start" sx={{ mt: 3 }} display={'grid'} alignItems={'center'}>
                <Typography variant="subtitle1">Data sources used to generate Exhibit C</Typography>
              </Grid>

              <Grid item xs={6} md={6} display={'grid'} justifyContent={'end'} alignItems={'center'}>
                <label htmlFor="raised-button-file">
                  <input
                      accept=".pdf,.doc,.docx,.xls,.xlsx,.txt"
                      style={{ display: 'none' }}
                      id="raised-button-file"
                      type="file"
                      onChange={handleFileUpload}
                  />
                  <Button component="span" startIcon={<UploadIcon />} variant={'outlined'}>
                    {
                      openNotify && <CircularProgress size={20}/> || 'Upload file'
                    }
                  </Button>
                </label>
              </Grid>
              <Grid item xs={12} md={12} marginBottom={'60px'}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>File Name</TableCell>
                        <TableCell>Upload Date</TableCell>
                        <TableCell>File Size</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Trash</TableCell>
                      </TableRow>
                    </TableHead>
                    {
                      !dataSource ? (
                          <TableBody>
                            <TableRow>
                              <TableCell colSpan={4}>
                                <Typography>No data source uploaded</Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                      ) : (
                          <TableBody>
                            {
                              dataSource?.map((data, index) => (
                                  <TableRow>
                                    <TableCell>
                                      <Typography>
                                        {data.filename}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography>{moment(data?.created_at * 1000).format('MM/DD/YY, h:mm a')}</Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography>{formatBytes(data?.bytes)}</Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography>{data?.status}</Typography>
                                    </TableCell>
                                    <TableCell>
                                      <IconButton color="error">
                                        <DeleteIcon onClick={() => deleteUploadedFile(data)} />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                              ))
                            }
                          </TableBody>
                      )
                    }

                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} md={12} display={'flex'} justifyContent={'center'}>
                <Button variant="contained" color="primary" sx={{ mt: 3, marginBottom: '30px' }} onClick={generateReport}>
                  Generate Report
                </Button>
              </Grid>
            </Grid>

          </Box>
        </TabPanel>
        <TabPanel value="2">
          <Grid style={{width: '100%'}}>
            <Toolbar sx={{background: '#F4F6F8'}}>

              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Exhibit C
              </Typography>
              <ButtonGroup
                  variant="text"
                  aria-label="Action Buttons"
              >
                {showCopy && (
                    <Alert severity="info">{showCopy.text} Exhibit C Report</Alert>
                )}
                <Button color='info' onClick={handleDownloadClick} startIcon={<DownloadIcon />}>Download</Button>
                <Button color='info' onClick={handleCopyClick} startIcon={<CopyIcon />}>Copy</Button>
              </ButtonGroup>
            </Toolbar>
            {
              isReportLoading ? (
                  <Grid container display={'flex'} justifyContent={'center'}>
                    <div style={{width: '50%'}}>
                      <Typography variant='subtitle1' sx={{marginTop: '30px'}}>Your report is being generated and will be ready in about 45 seconds. Feel free to check back shortly.</Typography>
                      <Lottie animationData={LoadingReport} loop />
                    </div>
                  </Grid>
              ) : (
                  existingReports.length > 0 && (
                      existingReports.map((section, index) => 
                        // const [title, ...contentArray] = section.split('\n');
                        // const content = contentArray.join(' ');
                        
                         (
                            <Card
                                key={index}
                                sx={{
                                  borderRadius: '0px',
                                  boxShadow: 'none',
                                  border: '1px solid transparent',
                                  transition: 'border 0.3s, box-shadow 0.3s',
                                  '&:hover': {
                                    border: '1px solid rgba(0, 0, 0, 0.23)', // mimic the outlined variant
                                    boxShadow: 'none', // Optionally keep box-shadow none if desired
                                    borderRadius: '4px',
                                  },
                                  '&:hover .rewrite-buttons': {
                                    visibility: 'visible', // or 'display: block' if using 'display: none'
                                  },
                                }}
                            >
                              <div>
                                <CardContent>
                                  <ButtonGroup
                                      className="rewrite-buttons"
                                      variant="text"
                                      aria-label="Rewrite Actions"
                                      sx={{
                                        position: 'absolute',
                                        right: '20px',
                                        visibility: 'hidden', // or 'display: none'
                                        transition: 'visibility 0.2s', // smooth transition
                                      }}
                                  >
                                    <Button startIcon={<LoopIcon />} onClick={() => rewriteSection(section)}>Rewrite</Button>
                                  </ButtonGroup>
                                </CardContent>
                                {

                                  (section?.name === sectionNameRewriteLoading) ? <div style={{padding: '20px'}}>
                                    <Skeleton animation="wave" variant="rounded" height={20} sx={{marginBottom: '20px'}} />
                                    <Skeleton animation="wave" variant="rounded" height={100} />
                                  </div> : (
                                    <MarkdownPreview source={section?.content} style={{ padding: 16 }} wrapperElement={{ "data-color-mode": "light" }}/>
                                  )
                                }


                              </div>
                            </Card>
                        )

                      )
                  )
              )
            }

          </Grid>
        </TabPanel>
        <TabPanel value="3">
          <Typography variant="h6" sx={{ mt: 3 }}>
            Research Subject
          </Typography>
          <Grid container columnGap={'20px'}>
            <Grid item xs={3} md={3} sx={{ marginTop: '20px' }}>
              <TextField
                  label="Company Name"
                  variant="outlined"
                  fullWidth
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
              />
            </Grid>
            <Grid item xs={3} md={3} sx={{ marginTop: '20px' }}>
              <TextField
                  label="Company Website"
                  variant="outlined"
                  fullWidth
                  value={companyWebsite}
                  onChange={(e) => setCompanyWebsite(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ marginTop: '20px' }}>
              <Button variant={'contained'} onClick={clickResearch}>Generate Research</Button>
            </Grid>
            <Grid item xs={12} md={12} sx={{ marginTop: '20px' }}>
              {
                generateResearchLoading ? (<div style={{padding: '20px'}}>
                  <Skeleton animation="wave" variant="rounded" height={20} sx={{marginBottom: '20px'}} />
                  <Skeleton animation="wave" variant="rounded" height={100} sx={{marginBottom: '20px'}} />
                  <Skeleton animation="wave" variant="rounded" height={20} sx={{marginBottom: '20px'}} />
                  <Skeleton animation="wave" variant="rounded" height={100} />
                </div>) : <MarkdownPreview source={researchReport} style={{ padding: 16 }} wrapperElement={{ "data-color-mode": "light" }}/>
              }

            </Grid>
          </Grid>
        </TabPanel>
        </TabContext>
      </Container>
    </>
  );
};

export default AgentWelsh;