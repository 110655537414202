import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Button,
  Grid,
  Container,
  Box,
  TextField,
  ButtonGroup,
  Alert,
  IconButton
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import Lottie from "lottie-react";
import 'react-loading-skeleton/dist/skeleton.css'
import _ from 'lodash';
import QuestionAnimation from 'animations/questions-animation.json';



import {
  useFetchPregeneratedQuestions, useGenerateQuestionsForRoleAndGroup
} from 'utils/service';
import { AnyAnswerAcceptablePrompt } from 'utils/constants';
import { useSendInterviewSetupMutation } from 'store/api';
import QnAForm from "./WelshQandAForm";

const FooterContainer = styled.div`
    display: flex;
    justify-content: end;
    margin-top: 40px;
`

const GROUPS = ['pre-screening', 'technical', 'behavioral', 'culture'];
type GroupsType = typeof GROUPS;
type GroupType = GroupsType[number];
type Group = { [subgroup: string]: string[] };
type GrouppedQuestions = { [group: string]: Group };

function deepMergeGroupedQuestions(...objects: GrouppedQuestions[]): GrouppedQuestions {
  const result: GrouppedQuestions = {};

  objects
    .filter(obj => !!obj)
    .forEach(obj => {
      Object.entries(obj)
        .filter(([, subgroups]) => !!subgroups)
        .forEach(([group, subgroups]) => {
          result[group] = result[group] || {};
          Object.entries(subgroups!)
        .filter(([, questions]) => !!questions)
            .forEach(([subgroup, questions]) => {
              const existingQuestions = result[group][subgroup] || [];
              const newQuestions = questions.filter(question => !existingQuestions.includes(question));
              result[group][subgroup] = [...existingQuestions, ...newQuestions]
            });
        });
    });

  return result;
}

type QnA = { question: string, preferredAnswer: string };

const WelshInterviewSetup = () => {
  const navigate = useNavigate();

  const [grouppedQuestions, setGrouppedQuestions] = useState<GrouppedQuestions>({});
  const [questions, setQuestions] = useState<string[]>([]);
  const [subgroups, setSubgroups] = useState<string[]>([]);
  const [group, setGroup] = useState<GroupType>('pre-screening');
  const [subgroup, setSubgroup] = useState<string>();
  const [role, setRole] = useState<string>('');
  const [roleEntered, setRoleEntered] = useState(false);
  const [editingIndex, setEditingIndex] = useState<any>(null);


  const [selectedQuestions, setSelectedQuestions] = useState<QnA[]>([]);
  const [error, setError] = useState<string>();
  const [{ data: pregeneratedQuestions, error: pregeneratedQuestionsError }]
    = useFetchPregeneratedQuestions();
  const [{ data: generatedQuestions, loading: isLoadingGeneratedQuestions, error: generatedQuestionsError }, generateQuestions]
    = useGenerateQuestionsForRoleAndGroup();
  const [{ data: generatedQuestionsForGroup, loading: isLoadingGeneratedQuestionsForGroup, error: generatedQuestionsForGroupError },
    generateQuestionsForGroup]
    = useGenerateQuestionsForRoleAndGroup();
  const [sendInterviewSetup, { isSuccess: isPlanSaved, error: savingPlanError, data: savedPlan }] = useSendInterviewSetupMutation();

  useEffect(() => setError(pregeneratedQuestionsError?.message || generatedQuestionsError?.message || generatedQuestionsForGroupError?.message
    || savingPlanError as string),
  [generatedQuestionsError?.message, generatedQuestionsForGroupError?.message, pregeneratedQuestionsError?.message, savingPlanError]);
  useEffect(() => {

    setGrouppedQuestions((original) => deepMergeGroupedQuestions(original, pregeneratedQuestions, generatedQuestions, generatedQuestionsForGroup));

  }, [pregeneratedQuestions, generatedQuestions, generatedQuestionsForGroup]);

  useEffect(() => {
    if (grouppedQuestions[group]) {
      setSubgroups(Object.keys(grouppedQuestions[group]));
    }


  }, [grouppedQuestions, group]);


  useEffect(() => {
    if (grouppedQuestions[group]) {

      let q;
      if (subgroup) {
        q = (grouppedQuestions[group][subgroup]);
      } else {
        q = Object.values(grouppedQuestions[group]).flatMap((facet) => facet).slice(0, 10);
      }
      q = q ?? [];
      q.sort((a, b) => a.length - b.length);

      setQuestions(q);
    }

  }, [grouppedQuestions, group, subgroup]);

  const handleSubgroupClick = async (subG: string) => {
    setSubgroup(subG);

    if (grouppedQuestions[group][subG] && grouppedQuestions[group][subG].length >= 10) {
      return;
    }
    if (subG) {
      generateQuestionsForGroup({ role: role!, group, subgroup: subG });
    }
  };

  const handleGroupClick = (newGroup: GroupType) => {
    setGroup(newGroup);
    setSubgroup(undefined);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleRoleEntered = async () => {
    try {
      if (role) {
        generateQuestions({ role });

        setRoleEntered(true);
      }

    } catch (e) {
      console.error("Error fetching role:", e);
    }
  };

  useEffect(() => {
    if(savedPlan) {
      navigate(`/dashboard/interview/${savedPlan._id}`)
    }
  }, [savedPlan, navigate]);

  const savePlan = async () => {
    if (selectedQuestions.length === 0) {
      setError('Please select at least one question');
      return;
    }
    sendInterviewSetup({ role, questions: selectedQuestions });
  }

  const handleEditQuestion = (index, newQuestion, newPreferredAnswer) => {
    const updatedQuestions = [...selectedQuestions];
    updatedQuestions[index] = { question: newQuestion, preferredAnswer: newPreferredAnswer };
    setSelectedQuestions(updatedQuestions);
  };

  const handleDeleteQuestion = (index) => {
    // Filter out the question at the specified index
    const updatedQuestions = selectedQuestions.filter((_, questionIndex) => questionIndex !== index);
    // Update the state
    setSelectedQuestions(updatedQuestions);
    setEditingIndex(null);
  };


  // @ts-ignore
  return (
    <>
      <Helmet>
        <title> Live Interview | Teambooster AI </title>
      </Helmet>

      <Container>
        <div className="error-banner">
          {
            error && (
              <Alert severity="error">{JSON.stringify(error)}</Alert>
            )
          }
        </div>

        <>
          {
            !roleEntered && (
              <Box marginTop="50px">
                <Grid container>
                  <Grid item marginBottom="10px" xs={12}>
                    <Typography variant="h4">Title</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      value={role}
                      onChange={handleRoleChange}
                      label="Role Name"
                      variant="outlined"
                      fullWidth
                    />
                    <Box marginTop="10px">
                      <Button disabled={role.length < 2 } onClick={handleRoleEntered}>
                        Enter
                      </Button>
                    </Box>
                    {isLoadingGeneratedQuestions && <Lottie animationData={QuestionAnimation} loop />}
                  </Grid>
                </Grid>

              </Box>
            )
          }

          {
            roleEntered && (
              <Box marginTop="70px">
                <Grid item xs={12}>
                  <Typography marginBottom="10px" variant="h4">Create Interview Plan</Typography>
                  <Typography variant="h5" fontWeight={400}>{_.upperFirst(role)}</Typography>
                </Grid>

                <QnAForm isLoading={isLoadingGeneratedQuestionsForGroup || isLoadingGeneratedQuestions} questions={selectedQuestions} setQuestions={setSelectedQuestions} roleQs={questions} selected={subgroup} />
                {selectedQuestions.map((qna, index) => (
                  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="start" marginTop="30px" key={index} position="relative">
                    <Box position="absolute" top={0} right={0}>
                      {
                        editingIndex === index && (
                          <IconButton sx={{marginRight: '10px'}} onClick={() => handleDeleteQuestion(index)}>
                            <DeleteOutlineIcon color={"error"} />
                          </IconButton>
                        )
                      }

                      <IconButton onClick={() => {
                        if(editingIndex === index) {
                          setEditingIndex(null);
                          return;
                        }
                        setEditingIndex(index);
                      }}>
                        <ModeEditIcon />
                      </IconButton>
                    </Box>
                    <Typography variant="h6" align="left">{index + 1}. {qna.question}</Typography>
                    {qna.preferredAnswer && <Typography variant="body1" align="left" marginTop="10px">Answer Preferences: {qna.preferredAnswer === AnyAnswerAcceptablePrompt ? "Any answer is acceptable" : qna.preferredAnswer}</Typography>}
                    {editingIndex === index && (
                      <Box display="flex" flexDirection="column" width="50%" marginTop="10px">
                        <TextField
                          value={selectedQuestions[editingIndex].question}
                          onChange={(e) => handleEditQuestion(editingIndex, e.target.value, selectedQuestions[editingIndex].preferredAnswer)}
                        />
                        <TextField
                          sx={{ marginTop: '10px' }}
                          value={selectedQuestions[editingIndex].preferredAnswer}
                          onChange={(e) => handleEditQuestion(editingIndex, selectedQuestions[editingIndex].question, e.target.value)}
                        />
                        <Button sx={{ marginTop: '10px' }} onClick={() => setEditingIndex(null)}>Done</Button>
                      </Box>
                    )}
                  </Box>

                ))}
              </Box>
            )
          }
        </>

        {isPlanSaved && (
          <Box marginTop="30px">
            <Typography variant="h5" color="primary">Interview Plan Saved Successfully!</Typography>
            <Typography variant="h6">Preview:</Typography>
            {selectedQuestions.map((qna, index) => (
              <Box display="flex" marginTop="20px" flexDirection="column" alignItems="start" key={index}>
                <Typography variant="h6" align="left">{index + 1}. Question: {qna.question}</Typography>
                {qna.preferredAnswer && <Typography variant="body1" align="left">Answer Preferences: {qna.preferredAnswer}</Typography>}
              </Box>
            ))}
          </Box>
        )}

        <FooterContainer>
          {
            roleEntered && questions && questions.length > 0 && !isPlanSaved && ( // Don't show the button if the plan is already saved
              <Button variant="contained" color="primary" onClick={savePlan} disabled={selectedQuestions.length === 0}>
                Save Interview Plan
              </Button>
            )
          }
        </FooterContainer>
      </Container>
    </>
  );
};

export default WelshInterviewSetup;

