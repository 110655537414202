import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { interviewPlanSlice } from './interview-plan';
import { userSlice } from './user';
import { companySlice } from './company';
import { api } from './api';
import { listenerMiddleware } from './listeners';

// Root reducer
const rootReducer = combineReducers({
  interviewPlan: interviewPlanSlice.reducer,
  user: userSlice.reducer,
  company: companySlice.reducer,
  [api.reducerPath]: api.reducer,
  // Add more reducers here
});

export type RootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false // if you're certain non-serializable values are safe, otherwise adjust accordingly
    })
    .prepend(listenerMiddleware.middleware)
    .concat(api.middleware),
});

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
