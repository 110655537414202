import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Candidate, Candidates } from "./candidates";
import { RootState } from ".";
import { Session, SessionStat } from './model';

declare global {
  interface Window {
    Config: any;
  }
}

const prepareHeaders = (headers, { getState }) => {
  const { token } = (getState() as RootState).user

  if (token) {
    headers.set('authorization', `Bearer ${token}`)
  }

  return headers
}

const CANDIDATES = 'CANDIDATES';
const SESSIONS = 'SESSIONS';
const INTERVIEWS = 'INTERVIEWS';
const INTERVIEW_PLAN = 'INTERVIEW_PLAN';
const JOB_POSTS = 'JOB_POSTS';
const JOB_POST = 'JOB_POST';
const APPLICANT = 'APPLICANT';
const WELSH_CLIENT = 'WELSH_CLIENT';
const WELSH_CLIENTS = 'WELSH_CLIENTS';
const WELSH_CLIENT_FILES = 'WELSH_CLIENT_FILES';
const WELSH_REPORT = 'WELSH_REPORT';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.Config.SERVER_API_URL}/api`,
    prepareHeaders
  }),
  refetchOnMountOrArgChange: 30,
  tagTypes: [CANDIDATES, SESSIONS, INTERVIEWS, JOB_POSTS, JOB_POST, APPLICANT, INTERVIEW_PLAN, WELSH_CLIENT, WELSH_CLIENTS, WELSH_CLIENT_FILES, WELSH_REPORT],
  endpoints: (builder) => ({
    fetchCompany: builder.query({
      query: () => `/company/`,
    }),
    fetchCandidatesList: builder.query<Candidates, void>({
      query: () => `/candidates/`,
      providesTags: [CANDIDATES],
    }),
    addCandidate: builder.mutation<Candidate, Candidate>({
      query: (candidate: Candidate) => {
        const formData = new FormData();
        if (candidate.resume) {
          formData.append('resume', candidate.resume);
        }
        formData.append('email', candidate.email);
        formData.append('name', candidate.name);
        formData.append('roles', candidate.roles?.join(', '));
        formData.append('client', candidate.client);
        formData.append('status', candidate.status);
        if (candidate.planId) formData.append('planId', candidate.planId);

        return ({
          url: `/candidates/`,
          method: 'POST',
          body: formData,
          formData: true
        })
      },
      invalidatesTags: [CANDIDATES, SESSIONS],
    }),
    removeCandidate: builder.mutation({
      query: (id) => ({
        url: `/candidates/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [CANDIDATES, SESSIONS],
    }),
    fetchSessions: builder.query({
      query: (planId) => `/interviews/${planId}/sessions`,
      providesTags: [SESSIONS], // TODO: tag by planId
    }),
    fetchSessionsStats: builder.query<SessionStat, void>({
      query: () => `/sessions/stats`,
      providesTags: [SESSIONS],
    }),

    fetchSession: builder.query<Session, string>({
      query: (sessionId) => `/interviews/sessions/${sessionId}`,
    }),
    createSession: builder.mutation({
      query: ({ planId, candidateId }: { planId: string, candidateId: string }) => ({
        url: `/interviews/${planId}/sessions`,
        method: 'POST',
        params: { candidateId }
      }),
      invalidatesTags: [SESSIONS],
    }),
    createSessionForApp: builder.mutation({
      query: ({ planId, applicationId }: { planId: string, applicationId: string }) => ({
        url: `/interviews/${planId}/sessions/application`,
        method: 'POST',
        params: { applicationId }
      }),
      invalidatesTags: [SESSIONS],
    }),
    updateSessionPlanId: builder.mutation({
      query: ({ planId, sessionId }: { planId: string, sessionId: string }) => ({
        url: `/interviews/${planId}/sessions/plan`,
        method: 'POST',
        body: { sessionId }
      }),
      invalidatesTags: [SESSIONS],
    }),
    deleteSession: builder.mutation({
      query: (sessionId: string) => ({
        url: `/sessions/${sessionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [SESSIONS],
    }),
    fetchInterviewPlans: builder.query({
      query: () => `/interviews`,
      providesTags: [INTERVIEWS],
    }),
    fetchInterviewPlan: builder.query({
      query: (planId) => `/interviews/${planId}`,
    }),
    sendInterviewSetup: builder.mutation({
      query: (interview) => ({
        url: `/interviews`,
        method: 'POST',
        body: interview
      }),
      invalidatesTags: [INTERVIEWS],
    }),
    deleteInterviewPlan: builder.mutation({
      query: ({ planId = '' }) => ({
        url: `/interviews/${planId}`,
        method: 'DELETE'
      }),
      invalidatesTags: [INTERVIEWS],
    }),
    editInterviewPlan: builder.mutation({
      query: ({ planId = '', interview }) => ({
        url: `/interviews/${planId}`,
        method: 'PUT',
        body: interview
      }),
    }),
    fetchWelshSession: builder.query({
      query: (sessionId: string) => ({
        url: `/upload/welsh-finish?sessionId=${sessionId}`,
        method: 'GET',
      }),
    }),
    fetchJobPostsAts: builder.query({
      query: () => ({
        url: `/data-sync/job-posts`,
        method: 'GET',
      }),
      providesTags: [JOB_POSTS],
    }),
    fetchJobPostApplicationAts: builder.query({
      query: (id) => ({
        url: `/data-sync/applications/${id}`,
        method: 'GET',
      }),
      providesTags: [APPLICANT],
    }),
    fetchJobPostCandidatesAts: builder.query({
      query: (id) => ({
        url: `/data-sync/job-posts/applications/${id}`,
        method: 'GET',
      }),
      providesTags: [JOB_POST],
    }),
    addInterviewPlanJobPost: builder.mutation({
      query: ({ planId = '', jobPostId = '' }) => ({
        url: `/data-sync/job-posts/${jobPostId}/interview-plan/${planId}`,
        method: 'PUT'
      }),
      invalidatesTags: [JOB_POST],
    }),
    deleteInterviewPlanJobPost: builder.mutation({
      query: ({ planId = '', jobPostId = '' }) => ({
        url: `/data-sync/job-posts/${jobPostId}/interview-plan/${planId}`,
        method: 'DELETE'
      }),
      invalidatesTags: [JOB_POST],
    }),
    fetchJobPostAts: builder.query({
      query: (id) => ({
        url: `/data-sync/job-posts/${id}`,
        method: 'GET',
      }),
    }),
    updateStatusApplicantAts: builder.mutation({
      query: ({ applicationId = '', stageId = '', email = '' }) => ({
        url: `/data-sync/applications/${applicationId}/${stageId}`,
        method: 'PUT',
        body: { email }  // This sends the email in the body of the request
      }),
      invalidatesTags: [APPLICANT],
    }),
    addNewWelshClient: builder.mutation({
      query: (client) => ({
        url: `/agent-welsh/client/add`,
        method: 'POST',
        body: client,
      }),
      invalidatesTags: [WELSH_CLIENT, WELSH_CLIENTS],
    }),
    fetchWelshClient: builder.query({
      query: (clientId) => ({
        url: `/agent-welsh/client/${clientId}`,
        method: 'GET',
      }),
      providesTags: [WELSH_CLIENT],
    }),
    fetchWelshClients: builder.query({
      query: () => ({
        url: `/agent-welsh/clients`,
        method: 'GET',
      }),
      providesTags: [WELSH_CLIENTS],
    }),
    fetchWelshClientFiles: builder.query({
      query: (clientId) => ({
        url: `/agent-welsh/client/${clientId}/files`,
        method: 'GET',
      }),
      providesTags: [WELSH_CLIENT_FILES],
    }),
    createWelshReport: builder.mutation({
      query: (client) => ({
        url: `/agent-welsh/generate-report`,
        method: 'POST',
        body: client,
      }),
      invalidatesTags: [WELSH_CLIENT, WELSH_REPORT],
    }),
    rewriteSectionReport: builder.mutation({
      query: (sectionDetails) => ({
        url: `/agent-welsh/generate-report/rewrite-section`,
        method: 'PUT',
        body: sectionDetails,
      }),
      invalidatesTags: [WELSH_CLIENT, WELSH_REPORT],
    }),
    deleteWelshClientFile: builder.mutation({
        query: ({clientId, fileId}) => ({
            url: `/agent-welsh/client/${clientId}/files/${fileId}`,
            method: 'DELETE'
        }),
        invalidatesTags: [WELSH_CLIENT_FILES],
    }),
    deleteWelshClient: builder.mutation({
        query: (clientId) => ({
            url: `/agent-welsh/client/${clientId}`,
            method: 'DELETE'
        }),
        invalidatesTags: [WELSH_CLIENTS],
    }),
    generateResearch: builder.mutation({
      query: ({ clientId, companyName, companyWebsite }) => ({
        url: `/agent-welsh/generate-research/${clientId}`,
        method: 'POST',
        body: { companyName, companyWebsite },
        invalidateTags: [WELSH_CLIENT],
      }),
    }),
  }),
});

export const {
  useFetchCandidatesListQuery,
  useAddCandidateMutation,
  useRemoveCandidateMutation,
  useFetchSessionQuery,
  useFetchSessionsQuery,
  useCreateSessionMutation,
  useCreateSessionForAppMutation,
  useUpdateSessionPlanIdMutation,
  useDeleteSessionMutation,
  useFetchInterviewPlansQuery,
  useFetchInterviewPlanQuery,
  useSendInterviewSetupMutation,
  useFetchSessionsStatsQuery,
  useDeleteInterviewPlanMutation,
  useEditInterviewPlanMutation,
  useFetchWelshSessionQuery,
  useFetchJobPostsAtsQuery,
  useFetchJobPostApplicationAtsQuery,
  useFetchJobPostCandidatesAtsQuery,
  useAddInterviewPlanJobPostMutation,
  useDeleteInterviewPlanJobPostMutation,
  useFetchJobPostAtsQuery,
  useUpdateStatusApplicantAtsMutation,
  useAddNewWelshClientMutation,
  useFetchWelshClientQuery,
  useFetchWelshClientsQuery,
  useFetchWelshClientFilesQuery,
  useCreateWelshReportMutation,
  useRewriteSectionReportMutation,
  useDeleteWelshClientFileMutation,
  useDeleteWelshClientMutation,
  useGenerateResearchMutation,
} = api;
