import { BrowserRouter, useNavigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { Auth0Context, Auth0Provider, useAuth0 } from '@auth0/auth0-react';
// routes
import { Auth0ProviderWithRedirectCallback } from 'components/auth/Auth0ProviderWithRedirectCallback';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { store } from './store/index';
// ----------------------------------------------------------------------


export default function App() {
  return (
    <Provider store={store}>
      
      <HelmetProvider>
        <BrowserRouter>
          <Auth0ProviderWithRedirectCallback
            domain={window.Config.AUTH_DOMAIN}
            clientId={window.Config.CLIENT_ID}
            authorizationParams={{
              redirect_uri: `${window.location.origin}`,
              audience: window.Config.AUDIENCE
            }}
          >

            <ThemeProvider>
              <ScrollToTop />
              <StyledChart />
              <Router />
            </ThemeProvider>
          </Auth0ProviderWithRedirectCallback>

        </BrowserRouter>
      </HelmetProvider>
    </Provider>
  );
}
