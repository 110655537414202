import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';
import welshLogo from 'img/welsh.png';

import logoImage from './logo-words.png'

import { welshEnabled } from '../../layouts/dashboard/nav/config';
// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );




  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        // width: 40,
        // height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      {/* { */}
      {/*  welshEnabled ? (<img src={welshLogo} alt="Logo" style={{ width: '100%', height: '100%', maxWidth: '200px' }} />) : (<img src={logoImage} alt="Logo" style={{ width: '100%', height: '100%', maxWidth: '200px' }} />) */}
      {/* } */}

      <img src={welshLogo} alt="Logo" style={{ width: '100%', height: '100%', maxWidth: '200px' }} />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
