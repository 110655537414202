import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

// @mui
import { styled } from '@mui/material/styles';
//
import { setUser } from 'store/user';
import { loadCompany } from 'store/company';

import Header from './header';
import Nav from './nav';
import { fetchAccountIntegrationDetails } from '../../utils/service';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const {getAccessTokenSilently, user} = useAuth0();

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently({})
        dispatch(setUser({...user, token}))
        const company = await fetchAccountIntegrationDetails()
        dispatch(loadCompany(company))
      } catch (e) {
        console.error(e);
      }
    })()
  }, [getAccessTokenSilently, dispatch, user]);

  const userFromState = useSelector((state) => state.user);

  return (
    userFromState.token ? 
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>
        <Outlet />
      </Main>
    </StyledRoot> : null
  );
}
