import React, { useState } from 'react';
import { Grid, TextField, Button, styled, Chip, FormControlLabel, Checkbox } from '@mui/material';
import styledComp from "styled-components"
import Skeleton from 'react-loading-skeleton'
import { Stack } from '@mui/system';
import Iconify from "../iconify";
import 'react-loading-skeleton/dist/skeleton.css'
import { AnyAnswerAcceptablePrompt } from '../../utils/constants';



const AnswerBtn = styled(Button)`
    position: absolute;
    top: -40px;
    left: 4px;
`

const TempQuestions = styledComp.div`
    display: flex;
    flex-wrap: wrap;
    
    & > div {
        margin-right: 5px;
        margin-bottom: 5px;
    }
`

const WelshQnAForm = ({ questions, setQuestions, roleQs = [], isLoading = false, selected }) => {
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [currentAnswer, setCurrentAnswer] = useState("");
  const [showAnswer, setShowAnswer] = useState(false);
  const [anyAnswerAcceptable, setAnyAnswerAcceptable] = useState(false);

  const handleQuestionChange = (event) => {
    setCurrentQuestion(event.target.value);
  };

  const handleAnswerChange = (event) => {
    setCurrentAnswer(event.target.value);
  };

  const handleSubmit = () => {
    let preferredAnswer = currentAnswer;
    if(anyAnswerAcceptable) {
      preferredAnswer = AnyAnswerAcceptablePrompt
    }
    setQuestions([
      ...questions,
      { question: currentQuestion, preferredAnswer }
    ]);
    setAnyAnswerAcceptable(false)
    setCurrentQuestion("");
    setCurrentAnswer("");
    setShowAnswer(false); // Hide the answer input after submitting
  };

  const toggleAnyAnswerAcceptable = () => {
    setAnyAnswerAcceptable(!anyAnswerAcceptable);
  };

  const handleAddTempQuestion = (event) => {
    setCurrentQuestion(event.target.innerText)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          value={currentQuestion}
          onChange={handleQuestionChange}
          label="Question"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={currentAnswer}
          disabled={anyAnswerAcceptable}
          onChange={handleAnswerChange}
          label="Answer Preferences (optional)"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={anyAnswerAcceptable}
              onChange={toggleAnyAnswerAcceptable}
            />
          }
          label="Any reasonable answer is acceptable"
        />
      </Grid>
      {/* )} */}
      <Grid item xs={12}>
        <Button disabled={currentQuestion === ""} variant="contained" color="secondary" onClick={handleSubmit}>
          Save Question
        </Button>
      </Grid>
    </Grid>
  );
};

export default WelshQnAForm;
