import { useState, useRef, useEffect, useCallback, Key, useReducer, ReactNode, createContext, useContext,  } from 'react';
import { RecordRTCPromisesHandler } from 'recordrtc';
import hark from 'hark';
import { AudioElement, Wave } from "@foobar404/wave";
import { Card, CardContent, Grid, Typography, CardActions, Button, AppBar, Alert, Icon, Box, BoxProps, Stack, Step, Stepper, StepLabel, useMediaQuery} from '@mui/material';
import { downloadAudio, fetchInterviewSessionById, loginBySessionId2, uploadAudioToServer } from 'utils/service';
import Loading from "animations/loading-rocket.json";
import styled from 'styled-components';
import Lottie from 'lottie-react';
import { useParams } from 'react-router-dom';
import Questiny from 'components/Questiny';
import { Question, Session } from 'store/model';
import logo from 'img/logo-long.png';  // Import the local image
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import { Helmet } from 'react-helmet-async';
import Vapi from '@vapi-ai/web';
import loadingImage from 'img/Fast loading-amico.png'; 
import NumberCircle from '../components/NumberCircle';
import MicrophoneSelect from '../components/MicrophoneSelect';

import styles from './Home.module.css';



const NEXT_BUTTON_DELAY = 5000; // 2 seconds delay

enum InterviewStep {
  INTRO = 'INTRO',
  LOADING = 'LOADING',
  INTERVIEW = 'INTERVIEW',
  FINISHED = 'FINISHED'
}

type ScreeningState = {
  finished: boolean,
  audioUrls: string[];
  loggedIn: boolean,
  error?: string,
  interviewId?: string,
  isRecording: boolean,
  stream?: MediaStream,
  talking: boolean,
  disclaimerAgreed: boolean,
  loading: boolean,
  currentQuestion: number,
  session?: Session,
  micSelected: boolean,
  isLoadingQuestion: boolean,
  currentStep: InterviewStep 
};

const initialState: ScreeningState = {
  finished: false,
  audioUrls: [],
  loggedIn: false,
  error: undefined,
  interviewId: undefined,
  isRecording: false,
  stream: undefined,
  talking: false,
  disclaimerAgreed: false,
  loading: false,
  currentQuestion: 0,
  session: undefined,
  micSelected: false,
  isLoadingQuestion: false,
  currentStep: InterviewStep.INTRO
};

type Action = { type: string, payload?: any };

const setLoggedIn = () => ({ type: 'SET_LOGGED_IN' });
const nextQuestion = () => ({ type: 'SET_NEXT_QUESTION' });
const setError = (payload: string) => ({ type: 'SET_ERROR', payload });
const hideDisclaimer = () => ({ type: 'HIDE_DISCLAIMER' });
const hideLoadingScreen = () => ({type: 'HIDE_LOADING'})
const setSession = (payload: Session) => ({ type: 'SET_SESSION', payload });
const setAudioUrls = (payload: { url: string; index: number; }) => ({ type: 'SET_AUDIO_URLS', payload });
const setLoadingQuestion = (isLoading: boolean) => ({ type: 'SET_LOADING_QUESTION', payload: isLoading });
const setCurrentStep = (step: InterviewStep) => ({ type: 'SET_CURRENT_STEP', payload: step });


function reducer(state: ScreeningState, action: Action): ScreeningState {
  switch (action.type) {
    case 'SET_LOGGED_IN':
      return { ...state, loggedIn: true };
    case 'SET_NEXT_QUESTION': {
      const nextQ = state.currentQuestion + 1;
      const lastQ = state.session?.plan?.questions?.length ?? 0;
      if (nextQ >= lastQ) {
        return { ...state, finished: true };
      }
      return { ...state, currentQuestion: nextQ };
    }
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'HIDE_DISCLAIMER':
      return { ...state, disclaimerAgreed: true, loading: true };
    case 'HIDE_LOADING':
      return {...state, loading: false}
    case 'SET_SESSION':
      return { ...state, session: action.payload };
    case 'SET_AUDIO_URLS': {
      const copyUrls = state.audioUrls.slice();
      copyUrls[action.payload.index] = action.payload.url;
      return { ...state, audioUrls: copyUrls };
    }
    case 'SET_MIC_SELECTED':
      return { ...state, micSelected: true };
    case 'SET_LOADING_QUESTION':
      return { ...state, isLoadingQuestion: action.payload };
    case 'SET_CURRENT_STEP':
      return { ...state, currentStep: action.payload };
    default:
      return state;
  }
}

const Visualizer = ({ isRecording, stream, ...props }: { isRecording: boolean, stream: MediaStream } & BoxProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current === null) return;
    const canvas = canvasRef.current;
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
  }, [canvasRef]);
  useEffect(() => {
    if (!stream) return;
    const audioCtx = new AudioContext();

    const audioElement: AudioElement = {
      context: audioCtx,
      source: audioCtx.createMediaStreamSource(stream)
    }

    if (canvasRef && canvasRef.current) {
      const wave = new Wave(audioElement, canvasRef.current, true);

      wave.addAnimation(new wave.animations.Arcs({
        lineWidth: 1,
        lineColor: '#2065D1',
        diameter: 30,
      }));

    }
  }, [stream, canvasRef])

  return <Box {...props} sx={{ position: 'relative' }}>
    <Icon sx={{
      color: isRecording ? '#2065D1' : 'gray',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%);'
    }}>
      {isRecording ? <MicIcon /> : <MicOffIcon />}
    </Icon>
    <canvas ref={canvasRef} width="1000" height="1000" style={{ width: '100%', height: '100%' }} />
  </Box>
}


const mimeType = 'audio/wav';
export const useSpeechDetection = (stream?: MediaStream, options = {}) => {
  const [talking, setTalking] = useState(false);

  if (stream) {
    const speechEvents = hark(stream, options);

    speechEvents.on('speaking', () => setTalking(true));
    speechEvents.on('stopped_speaking', () => setTalking(false));
  }
  return talking;
}


export const useRecorder = (onRecordingData: (blob?: Blob) => void) => {
  const [recorder, setRecorder] = useState<RecordRTCPromisesHandler>();
  const [stream, setStream] = useState<MediaStream>();
  const [isRecording, setIsRecording] = useState(false);

  const getPermissionInitializeRecorder = useCallback(async () => {
    const internalStream = await (navigator as any).mediaDevices.getUserMedia({
      video: false,
      audio: true,
    });

    const rtcRecorder = new RecordRTCPromisesHandler(internalStream, {
      type: 'audio',
      mimeType,
      timeSlice: 1000
    });
    setRecorder(rtcRecorder);
    setStream(internalStream);
    return rtcRecorder;
  }, []);

  const checkIfRecording = useCallback(async (rtcRecorder?: RecordRTCPromisesHandler) => {
    if (!rtcRecorder) return;

    const state = await rtcRecorder.getState();
    setIsRecording(state === 'recording');
  }, [setIsRecording]);

  const startRecording = useCallback(async () => {
    const rtcRecorder = await getPermissionInitializeRecorder();
    await rtcRecorder?.startRecording();
    checkIfRecording(rtcRecorder);
  }, [checkIfRecording, getPermissionInitializeRecorder]);

  const stopRecording = useCallback(async () => {
    await recorder?.stopRecording();
    checkIfRecording(recorder);
    const blob = await recorder?.getBlob();
    onRecordingData(blob);
  }, [onRecordingData, recorder, checkIfRecording]);

  const snapshotData = useCallback(async () => {
    await stopRecording();
    await startRecording();
  }, [stopRecording, startRecording]);

  return { startRecording, stopRecording, snapshotData, isRecording, stream };
};

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;
  
const CandidateLogin = ({ interviewId, onSuccessfullLogin, onLoginError }) => {

  useEffect(() => {
    if (!interviewId) return;
    (async () => {
      await loginBySessionId2(interviewId)
      onSuccessfullLogin();
    })().catch((e) => {
      onLoginError(e.message);
    });
  }, [interviewId, onSuccessfullLogin, onLoginError]);


  return <Centered>
    <Lottie animationData={Loading} />
  </Centered>
};

const IsMobileContext = createContext<boolean | undefined>(undefined);

  const useIsMobileContext = () => {
    const context = useContext(IsMobileContext);
    if (context === undefined) {
      throw new Error('useIsMobileContext must be used within an IsMobileProvider');
    }
    return context;
  };
  
  const IsMobileProvider = ({ children }: { children: ReactNode }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
  
    return (
      <IsMobileContext.Provider value={isMobile}>
        {children}
      </IsMobileContext.Provider>
    );
  };

const Disclaimer = ({ onDisclaimerClosed, isMicSelected, session, MicrophoneSelect, setCurrentStepComponent }: { onDisclaimerClosed: () => void; isMicSelected: boolean; session: any, MicrophoneSelect: any ,setCurrentStepComponent: any}) =>
{

  const isMobile = useIsMobileContext();

  const nextStep = () => {
    setCurrentStepComponent(InterviewStep.LOADING);
  }
  
  const questionCount = session?.plan?.questions?.length || 0;
  const candidateName = session?.candidate?.name || 'Candidate';
  const role = session?.plan?.role || 'role';
  return (
      <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          height={'100%'}
      >
        <Grid id='welcome' item xs={2} justifyContent='start' width='100%' marginTop={'30px'}>
          <Typography sx={{ fontWeight: 500, fontSize: '24px', marginLeft: isMobile ? '50px' : '75px', width: isMobile ? '75%' : '50%' }} component="div">
            Hello {candidateName},
          </Typography>
          <Typography sx={{ fontWeight: 500, fontSize: '24px', marginLeft: isMobile ? '50px' : '75px', marginBottom: '12px', width: isMobile ? '75%' : '50%' }} component="div">
            Welcome to the {role} interview
          </Typography>
        </Grid>
        <Grid container>
          <Grid item sm={12} md={6} marginTop={'30px'}>
          <div style={{ justifyContent: 'center', marginLeft: isMobile ? '0px' : '75px' }}>                      
              <Typography sx={{ marginBottom: '15px', fontSize: '20px', fontWeight: 500 }}>
                Here's what to expect:
              </Typography>
              <div style={{display: 'flex', alignItems: 'center', marginBottom: '40px'}}>
                <NumberCircle number={1} backgroundColor="#797979" size={20}/>
                <Typography marginLeft={'12px'} fontSize={'18px'} variant="body1" component="div" lineHeight={'21.78px'}>
                  You will be asked a total of {questionCount} questions. Should take about {questionCount * 2} minutes.
                </Typography>
              </div>
              <div style={{display: 'flex', alignItems: 'center', marginBottom: '40px'}}>
                <NumberCircle number={2} backgroundColor="#797979" size={20}/>
                <Typography marginLeft={'12px'} fontSize={'18px'} variant="body1" component="div" lineHeight={'21.78px'}>
                  Questions will be asked one at a time. After answering, press save answer and move to the next question.
                </Typography>
              </div>
              <div style={{display: 'flex', alignItems: 'center', marginBottom: '40px'}}>
                <NumberCircle number={3} backgroundColor="#797979" size={20}/>
                <Typography marginLeft={'12px'} fontSize={'18px'} variant="body1" component="div" lineHeight={'21.78px'}>
                  Relax and be yourself!
                </Typography>
              </div>
              <div style={{display: 'flex', alignItems: 'center', marginBottom: '40px'}}>
                <NumberCircle number={4} backgroundColor="#797979" size={20}/>
                <Typography marginLeft={'12px'} fontSize={'18px'} variant="body1" component="div" lineHeight={'21.78px'}>
                  Make sure you are in a quiet place, and choose your microphone below.
                </Typography>
              </div>
              <div style={{display: 'flex', alignItems: 'center', marginBottom: '40px'}}>
                <NumberCircle number={5} backgroundColor="#797979" size={20} />
                <div style={{marginLeft: '12px'}}>
                  {MicrophoneSelect}
                </div>

              </div>

              <Typography variant="body2" component="div" sx={{ marginTop: '35px' }}>
                Disclaimer: Interviews are recorded for evaluation purposes only.
              </Typography>
              <Button
                  variant="contained"
                  color="primary"
                  sx={{marginTop: '10px'}}
                  disabled={!isMicSelected}
                  onClick={nextStep}
              >Continue</Button>
            </div>
          </Grid>
          <Grid item sx={{ position: 'relative', height: 447 }} sm={12} md={6}>
            <Box
                component="img"
                src="/assets/illustrations/background_grey.svg"
                sx={{
                  position: 'absolute',
                  height: 447,
                  width: '100%', // Assuming you want to stretch it across the Grid item
                  left: 0,
                  top: 0,
                  zIndex: 1 // Lower z-index for the background
                }}
            />
            <Box
                component="img"
                src="/assets/illustrations/interview.svg"
                sx={{
                  position: 'absolute',
                  height: 447,
                  marginLeft: '100px',
                  top: 0,
                  zIndex: 2 // Higher z-index for the foreground image
                }}
            />
          </Grid>
        </Grid>

      </Grid>
  )
}

const LoadingScreen = ({ onStartInterview, setCurrentStepComponent }: { onStartInterview: () => void, setCurrentStepComponent: any }) => {
  const nextStep = () => {
    setCurrentStepComponent(InterviewStep.INTERVIEW);
  }

  const isMobile = useIsMobileContext();

  return (
      <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          height={'100%'}
      >
      <Grid container spacing={2} alignItems="center" direction="row" >
        <Grid item sm={12} md={6} container justifyContent="center">
        {/* <ComputerIcon style={{ fontSize: 180 }}/> */}
        <img src={loadingImage} alt="Loading screen logo" style={{ width: '100%', maxWidth: '500px' }} />
        </Grid>
        <Grid item  sm={12} md={6}>
        <Box sx={{ marginRight: isMobile ? '50px' : '75px', marginLeft:'25px' }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '36px', marginBottom: '20px' }} component="div" variant="h6">
          Ready to get started?
          </Typography>
          <Typography fontSize={'18px'} variant="body1" component="div">
          Press the microphone when you are ready
          </Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: '36px', marginBottom: '20px', marginTop: '60px' }} component="div" variant="h6" >
          ✨ Interview Tip
          </Typography>
          <Typography marginBottom={'12px'} fontSize={'18px'} variant="body1" component="div">
          Prepare to be asked about times in the past when you used a specific skill and answer with a story using the STAR method—situation, task, action and result.
          </Typography>
          <Button
            variant="contained"
            startIcon={<MicIcon />}
            color="primary"
            sx={{ marginTop: '10px' }}
            onClick={nextStep}
          >
            Start Interview
          </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};


const AudioQuestion = ({ audioUrl, handleEnded, quiet }: { audioUrl: string, handleEnded?: () => void, quiet: boolean }) => {
  const vapi = new Vapi('8e7a31df-146f-47fe-902b-de3bc05a8aa4');
  return <Questiny sx={{ width: '300px', display: 'flex', justifyContent: 'center', margin: '60px 0px' }} play handleEnded={handleEnded} quiet={quiet}  vapi={vapi}/>
}


const TextQuestion = ({ text }) => (
  <Typography variant='subtitle1' fontSize='1.2rem'>
    {text}
  </Typography>
)

const InterviewQuestion = ({ audioUrl, textData, handleEnded, talking }:
  { audioUrl: string, textData?: string, handleEnded?: () => void, talking: boolean }) => 
    <Stack direction='column' alignItems='center' padding='0 30px'>
      <AudioQuestion audioUrl={audioUrl} handleEnded={handleEnded} quiet={talking} />
      <TextQuestion text={textData} />
    </Stack>

const Recording = ({ isRecording, stream, finishedAnswering, isLoadingQuestion, setCurrentStepComponent, currentQuestion, questionsLength }) => {

  const isInterviewFinished = currentQuestion >= questionsLength - 1;

    const handleSaveAnswer = () => {
    finishedAnswering();
    if (isInterviewFinished) {
      setCurrentStepComponent(InterviewStep.FINISHED); 
    }
  }

  return (
    <Stack direction='column' alignItems='center'>
      {/* {stream && <Visualizer isRecording={isRecording} stream={stream} style={{ width: '300px', height: '100px' }} />} */}
      <Button onClick={handleSaveAnswer} disabled={isLoadingQuestion}>Save Answer</Button>
    </Stack>
  );
}

const Interview = ({ isRecording, session, startRecording, talking, stream, finishedAnswering, currentQuestion, audioUrls, isLoadingQuestion, setCurrentStepComponent }) => {

  const isMobile = useIsMobileContext();

  useEffect(() => {
    startRecording();
  }, [startRecording]);

  useEffect(() => {
  }, [currentQuestion, session?.plan?.questions?.length]);

  const steps = session?.plan?.questions.map((question, index) => `Question ${index + 1}`) || [];
  const CustomConnector = styled('div')(({ theme }) => ({
    display: 'none',
  }));

  const CustomStepIcon = ({ active, completed, questionCount }) => {
    const backgroundColor = active ? '#000000' : completed ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0.1)';
    return (
      <div
        style={{
          display: 'flex',
          maxWidth: '100px', 
          width: isMobile ? '25px' : '100px' ,
          height: '5px',
          backgroundColor,
        }}
      />
    );
  };
  
  const [activeQuestion, setActiveQuestion] = useState(0);

  useEffect(() => {
    setActiveQuestion(currentQuestion);
  }, [currentQuestion]);


  useEffect(() => {
    const startVapi = async () => {
      const vapi = new Vapi('8e7a31df-146f-47fe-902b-de3bc05a8aa4');
      try {
        const assistantOverrides = {
          firstMessage: 'Hello Nia, how was your day? My little baby baby girl'
        };
        await vapi.start('3b15809d-bea4-4a7e-bdb8-60b84b8fe8ef', assistantOverrides);
        console.log('vapi started successfully');
      } catch (error) {
        console.error('Failed to start vapi:', error);
      }
    };
    startVapi();
  }, []); // Empty dependency array ensures this runs only once after initial render


  return (
    <>
      <Helmet>
        <title> Screening | Teambooster AI </title>
      </Helmet>
      <Grid container direction="column">
        <Grid item xs={12} style={{ height: '100px', background: '#F9FBFC' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' , marginTop:'20px'}}>
          <Stepper activeStep={currentQuestion} alternativeLabel connector={<CustomConnector /> }>
                  {steps.map((label, index) => (
                    <Step key={index}  style={{ flex: 1 }}>
                      <StepLabel StepIconComponent={(props) => <CustomStepIcon {...props} />}>
                        {activeQuestion === index && label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
            </Box>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Grid id={'test'} item xs={12} sm={8} md={6} lg={4} sx={{ padding: '60px 0' }}>
            <Box sx={{ background: '#FFF', borderRadius: '5px', padding: '0 30px', paddingBottom: '30px' }}>
              {session?.plan.questions.map((question, index) =>
                  index === currentQuestion && <InterviewQuestion talking={talking} key={index} audioUrl={audioUrls[index]} textData={question.question} />
              )}
               {/* <Recording isRecording={isRecording} stream={stream} finishedAnswering={finishedAnswering} isLoadingQuestion={isLoadingQuestion} setCurrentStepComponent={setCurrentStepComponent} currentQuestion={currentQuestion} questionsLength={session?.plan?.questions?.length}/> */}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
    );
};

export const Finished = () =>
  <div style={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
    {
      localStorage.getItem('original') ? (
        <Grid container marginTop={'150px'}>
          <Grid item xs={6}>
            <Box
              margin={'0 auto'}
              component="img"
              src="/assets/illustrations/people_talking.svg"
              sx={{ height: 500 }}
            />
          </Grid>
          <Grid item xs={6} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'start'} padding={'0px 60px 0px 0px'}>
            <Typography variant="h4" gutterBottom fontWeight={500} >
              Almost there!
            </Typography>
            <Typography variant="subtitle1" gutterBottom fontWeight={500}>
              Thank you for taking the time to complete a preliminary interview with <span style={{ whiteSpace: 'nowrap' }}>Beyond Finance</span>!
            </Typography>
            <Typography variant="body1" gutterBottom fontWeight={400} marginTop={'14px'} maxWidth={'90%'}>
              In order for your interview to be reviewed, you must complete a brief 10 minute computer literacy test. Please click the link below to complete the assessment. The assessment must be taken on a computer and is not accessible via smartphone. After the assessment is complete, someone from the <span style={{ whiteSpace: 'nowrap' }}>Beyond Finance</span> recruiting team will be in touch with an update. Thank you again for your interest in <span style={{ whiteSpace: 'nowrap' }}>Beyond Finance</span>!
            </Typography>
            <Button
              variant="contained"
              color="primary"
              href="https://www.ondemandassessment.com/link/index/JB-64P6W9SFZ?u=1157074" // Replace with your actual URL
              target="_blank" // Opens the link in a new tab
              sx={{ mt: 2, width: 'auto', px: 4 }} // Controls the button size and padding
            >
              Go to Assessment
            </Button>
          </Grid>

        </Grid>

        ) : (
        <Grid container marginTop={'150px'}>
          <Grid item xs={6}>
            <Box
              margin={'0 auto'}
              component="img"
              src="/assets/illustrations/finish_race.svg"
              sx={{ height: 400 }}
            />
          </Grid>
          <Grid item xs={6} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
            <Typography variant="h4" gutterBottom fontWeight={500} fontSize={'48px !important'}>
              You're all set!
            </Typography>
            <Typography variant="h4" gutterBottom fontWeight={500} fontSize={'25px !important'} maxWidth={'80%'}>
              Thanks for taking the time. We’ll review your answers and get back to you as soon as we can!
            </Typography>
          </Grid>
        </Grid>
          )
    }
  </div>

export const Screening2 = () => {

  const { interviewId } = useParams();
  const [permissionGranted, setPermissionGranted] = useState(false);

  const handlePermissionChange = (isGranted) => {
    setPermissionGranted(isGranted);
  };

  const [state, dispatch] = useReducer(reducer, initialState);
const { loggedIn, currentQuestion, error, disclaimerAgreed, session, audioUrls, finished, micSelected , isLoadingQuestion,  currentStep} = state;

  const handleStartInterview = () => {
    dispatch(hideLoadingScreen());
    dispatch(setCurrentStep(InterviewStep.INTERVIEW));
  };

  const setMicSelected = () => ({ type: 'SET_MIC_SELECTED' });


  if (!interviewId) throw new Error('interviewId is undefined'); // TODO: install error boundaries

  const setCurrentStepComponent = (nextStep) => {
    dispatch(setCurrentStep(nextStep));

  }

  const onRecordingData = useCallback((blob: Blob) => {
    uploadAudioToServer(interviewId, currentQuestion, blob, mimeType);
  }, [interviewId, currentQuestion]);

  const { isRecording, startRecording, snapshotData, stream } = useRecorder(onRecordingData);
  const talking = useSpeechDetection(stream);

  useEffect(() => 
    // Add a cleanup function
     () => {
      if (stream) {
        stream.getTracks().forEach(track => track.stop()); // This will stop the microphone
      }
    }
  , [stream]);

  useEffect(() => {
    if (finished) {
      // Stop the media stream when the interview is finished
      if (stream) {
        stream.getTracks().forEach(track => track.stop()); // This will stop the microphone
      }
    }
  }, [finished, stream]);

  const goToNextQuestion = useCallback(() => {
    dispatch(nextQuestion());
  }, [dispatch]);

  const finishedAnswering = useCallback(() => {
    snapshotData();
    goToNextQuestion();
    dispatch(setLoadingQuestion(true));
    setTimeout(() => {
      dispatch(setLoadingQuestion(false));
    }, NEXT_BUTTON_DELAY);
  }, [goToNextQuestion, snapshotData]);


  useEffect(() => {
    if (!loggedIn || !interviewId) return;
    (async () => {
      const { data } = await fetchInterviewSessionById(interviewId);
      dispatch(setSession(data));
    })().catch((e) => {
      dispatch(setError(`error while fetching interview session: ${e.message}`));
    });

  }, [loggedIn, interviewId]);

  useEffect(() => {
    if (session && loggedIn) {
      dispatch(setLoadingQuestion(true));

      const downloadPromises = session.plan.questions.map((question, index) => new Promise((resolve, reject) => {
          if (question.audio) {
            downloadAudio(question.audio).then(({ data }) => {
              dispatch(setAudioUrls({
                url: URL.createObjectURL(data),
                index
              }));
              resolve(0);
            }).catch(e => {
              dispatch(setError(`error downloading audio: ${e.message}`));
              reject(e);
            });
          } else {
            resolve(0); // Resolve immediately if there's no audio to download.
          }
        }));

      Promise.all(downloadPromises).then(() => {
        setTimeout(() => {
          dispatch(setLoadingQuestion(false));
        }, NEXT_BUTTON_DELAY);
      }).catch(e => {
        console.error("Error in downloading audio files: ", e);
        dispatch(setLoadingQuestion(false));
      });

    }
  }, [session, loggedIn]);


  const onSuccessfullLogin = useCallback(() => {
    dispatch(setLoggedIn());
  }, [dispatch]);

  const onLoginError = useCallback((errorEvent: string) => {
    dispatch(setError(`Error while trying to log in: ${errorEvent}`));
  }, [dispatch]);

  useEffect(() => {
    if (loggedIn && disclaimerAgreed && micSelected) {
      startRecording();
    }
  }, [startRecording, disclaimerAgreed, loggedIn, micSelected]);

  const showError: boolean = !!error;
  const showMicSelect: boolean = loggedIn && !micSelected;
  const showCandidateLogin: boolean = !loggedIn;
  const showDisclaimer: boolean = !disclaimerAgreed && loggedIn;
  const showLoading : boolean = disclaimerAgreed && loggedIn;
  const showInterview: boolean = loggedIn && disclaimerAgreed && !!session && !finished;
  const showTalking: boolean = talking;

  return (
    <>
      {error && <Alert severity='error'>{error}</Alert>}
      <div>
        {showCandidateLogin && <CandidateLogin interviewId={interviewId} onSuccessfullLogin={onSuccessfullLogin}
                                               onLoginError={onLoginError} />}
        <AppBar position='static' className={styles.appBarStyle}>
          <Typography variant='h6' sx={{ flexGrow: 1, textAlign: 'center', maxWidth: '70vw' }}>
            <img src={logo} alt='Logo' style={{ height: '60px' }} />
          </Typography>
        </AppBar>
        <div style={{padding: '50px'}}>
          <IsMobileProvider>
            {currentStep === InterviewStep.INTRO && (
              <div style={{}}>
                <div style={{ width: '100%', paddingLeft: 15, paddingRight: 15 }}>
                  <Disclaimer session={session} isMicSelected={permissionGranted}
                              setCurrentStepComponent={setCurrentStepComponent}
                              MicrophoneSelect={<MicrophoneSelect
                                onMicSelected={(mic) => console.log('Selected Mic:', mic)}
                                permissionGranted={permissionGranted}
                                onPermissionChange={handlePermissionChange}
                              />} onDisclaimerClosed={() => dispatch(hideDisclaimer())} />
                </div>
              </div>
            )}
            {currentStep === InterviewStep.LOADING &&
              <LoadingScreen onStartInterview={handleStartInterview} setCurrentStepComponent={setCurrentStepComponent} />}
            {currentStep === InterviewStep.INTERVIEW && (
              <Interview
                isLoadingQuestion={isLoadingQuestion}
                session={session}
                startRecording={startRecording}
                talking={talking}
                stream={stream}
                finishedAnswering={finishedAnswering}
                currentQuestion={currentQuestion}
                audioUrls={audioUrls}
                isRecording={isRecording}
                setCurrentStepComponent={setCurrentStepComponent}
              />
            )}
            {currentStep === InterviewStep.FINISHED && <Finished />}
          </IsMobileProvider>
        </div>

      </div>
    </>
  );
};