import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { filter, update } from 'lodash';
import { sentenceCase } from 'change-case';

// @mui
import {
  Grid,
  Button,
  Container,
  Stack,
  Typography,
  Badge,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell, Checkbox, Avatar, Paper, TableContainer, TablePagination, Card, MenuItem, Popover,
} from '@mui/material';

import { faker } from "@faker-js/faker";

import ReactMarkdown from "react-markdown";
import { RiseLoader } from "react-spinners";
import { useAuth0 } from "@auth0/auth0-react";

// components
import { useFetchInterviewPlansQuery, useDeleteInterviewPlanMutation } from 'store/api';
import Iconify from 'components/iconify';
import { UserListHead } from "sections/@dashboard/candidate";
import Label from "components/label";
import Scrollbar from "components/scrollbar/Scrollbar";
import { setMetadata } from 'store/interview-plan';
import { companyName } from "store/user";


const TABLE_HEAD = [
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'company', label: 'Team', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  if (array === undefined) return [];
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function WelshInterview() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [openInterviewMenu, setOpenInterviewMenu] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const company = useSelector(companyName);

  const { data: interviewPlans } = useFetchInterviewPlansQuery(null, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  const [ deleteInterviewPlan ] = useDeleteInterviewPlanMutation();

  const handleDeleteInterviewPlan = async () => {
    console.log('openInterviewMenu', openInterviewMenu)
    try {
      const { data: plan } = await deleteInterviewPlan({ planId: openInterviewMenu?.planId });
      console.log('plan', plan)
    } catch (e) {
      console.log('error', e)
    } finally {
      handleCloseMenu();
    }
  }

  const handleAutomatedLiveScreen = () => {
    navigate('/dashboard/welsh/live')
  }

  const handleInterviewDetail = (id) => {
    navigate(`/dashboard/interview/${id}`)
  }

  const handleOpenMenu = (event, id) => {
    event.stopPropagation();
    event.currentTarget.planId = id;
    setOpenInterviewMenu(event.currentTarget)
  };

  const handleCloseMenu = () => {
    setOpenInterviewMenu(null)
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = interviewPlans.map((n) => n.role);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleInterviewClick = (interviewPlan) => {
    console.log('interviewPlan', interviewPlan)
    dispatch(setMetadata({ metadata: interviewPlan }))
    setMetadata(interviewPlan)
    handleInterviewDetail(interviewPlan?._id)
  }


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // const handleFilterByName = (event) => {
  //     setPage(0);
  //     setFilterName(event.target.value);
  // };

  // useEffect(() => {
  //     (async () => {
  //         try {
  //             const res = await fetchInterviewPlans(getAccessTokenSilently())
  //             setInterviewPlans(res);
  //         } catch (e) {
  //             console.log('Interview Plans Network Error')
  //         }

  //     })();
  // }, []);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - interviewPlans?.length) : 0;

  const sortedInterviewPlans = applySortFilter(interviewPlans, getComparator(order, orderBy), filterName);
  const isNotFound = !sortedInterviewPlans?.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Interview | {company} </title>
      </Helmet>

      <div style={{ padding: '0px 40px' }}>

        {
          (location.pathname === "/dashboard/welsh") && (
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              {/* <Button variant="contained" startIcon={<Iconify icon="carbon:circle-filled"/>} onClick={handleNewInter}>* /}
                            {/*    Live Interview* /}
                            {/* </Button> */}
              <Button variant="contained" startIcon={<Iconify icon="eos-icons:trusted-organization" />} onClick={handleAutomatedLiveScreen}>
                Add Welsh Interview
              </Button>
            </Stack>
          )
        }

        {
          (location.pathname === "/dashboard/welsh") && (
            <Card>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Typography variant="h4" paddingTop={2} paddingLeft={2} gutterBottom>
                    Company Interviews - Tax Credit Eligibility
                  </Typography>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={interviewPlans?.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {sortedInterviewPlans.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const { _id: id, role } = row;
                        // TODO: fix this
                        const status = 'active';
                        const candidates = faker.datatype.number({ min: 3, max: 8 });
                        const name = faker.name.fullName();
                        const selectedUser = selected.indexOf(name) !== -1;

                        return (
                          <TableRow hover key={id} tabIndex={-1} role="checkbox" onClick={() => handleInterviewClick(row)} selected={selectedUser}
                                    sx={{
                                      cursor: 'pointer',
                                    }}
                          >

                            {/*
                                                        TODO: enable this when we have the checkbox functionality
                                                        <TableCell padding="checkbox">
                                                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                                                        </TableCell> */}

                            <TableCell component="th" scope="row">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {role}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">{company}</TableCell>

                            <TableCell align="left">
                              <Label color={(status === 'ended' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                            </TableCell>

                            <TableCell align="right">
                              <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, id)}>
                                <Iconify icon={'eva:more-vertical-fill'} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Not found
                              </Typography>

                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Try checking for typos or using complete words.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={interviewPlans?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Scrollbar>
              <Popover
                open={Boolean(openInterviewMenu)}
                anchorEl={openInterviewMenu}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                  sx: {
                    p: 1,
                    width: 140,
                    '& .MuiMenuItem-root': {
                      px: 1,
                      typography: 'body2',
                      borderRadius: 0.75,
                    },
                  },
                }}
              >
                {/* TODO: enable */}
                <MenuItem disabled>
                  <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                  Edit
                </MenuItem>

                <MenuItem sx={{ color: 'error.main' }} onClick={handleDeleteInterviewPlan}>
                  <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                  Delete
                </MenuItem>
              </Popover>
            </Card>
          )
        }
      </div>
      <Grid container sx={{ padding: '0px 40px' }}>
        <Outlet />
      </Grid>

    </>
  );
}