export const mustsExtractions = (preferredAnswer) => {
    const preferences = {
        must: '',
        mustNot: ''
    };

    // Extract the 'Must' and 'Must Not' parts from the string
    const mustMatch = preferredAnswer.match(/Must:([^]+?)(Must Not:|$)/);
    const mustNotMatch = preferredAnswer.match(/Must Not:([^]+)/);

    // If matches are found, trim them and assign to the preferences object
    if (mustMatch) {
        preferences.must = mustMatch[1].trim();
    }
    if (mustNotMatch) {
        preferences.mustNot = mustNotMatch[1].trim();
    }
    return preferences;
}