/* eslint-disable no-debugger */
import { useParams, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import _, { filter } from 'lodash';
import moment from 'moment-mini';
import {
	Avatar, Box,
	Card,
	Grid, IconButton, Paper, Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer, TablePagination,
	TableRow,
	Typography,
	Rating,
	Button, MenuItem, Popover, Checkbox,
	Link,
} from '@mui/material';
import { useNewCandidate } from 'sections/@dashboard/candidate/newCandidate';
import { sendEmail } from 'utils/service';
import Scrollbar from 'components/scrollbar/Scrollbar';
import { KpiWidget } from 'sections/@dashboard/app';
import { UserListHead, UserListToolbar } from 'sections/@dashboard/candidate';
// import USERLIST from '_mock/candidates';
import Label from 'components/label';
import Iconify from 'components/iconify';
import { useAddCandidate } from 'sections/@dashboard/candidate/addCandidate';
import {
	useDeleteSessionMutation,
	useFetchInterviewPlanQuery,
	useFetchJobPostsAtsQuery,
	useFetchSessionsQuery,
} from 'store/api';
import { useDispatch } from 'react-redux'
import { setProcessedData } from 'store/interview-plan';
import { AnyAnswerAcceptablePrompt } from '../utils/constants';


const TABLE_HEAD = [
	{ id: 'candidate.name', label: 'Candidate', alignRight: false },
	// { id: 'redflag', label: 'Red Flags', alignRight: false },
	{ id: 'candidateRating', label: 'Smart Candidate Rating', alignRight: false },
	{ id: 'status', label: 'Status', alignRight: false },
	{ id: 'history', label: 'Email Sent', alignRight: false },
	{ id: '' },
];

const answerPreferences = (qna) => {
	function parseAnswerPreferences(preferencesString) {
		// Initialize an object to hold the parsed preferences
		const preferences = {
			must: '',
			mustNot: ''
		};

		// Extract the 'Must' and 'Must Not' parts from the string
		const mustMatch = preferencesString.match(/Must:([^]+?)(Must Not:|$)/);
		const mustNotMatch = preferencesString.match(/Must Not:([^]+)/);

		// If matches are found, trim them and assign to the preferences object
		if (mustMatch) {
			preferences.must = mustMatch[1].trim();
		}
		if (mustNotMatch) {
			preferences.mustNot = mustNotMatch[1].trim();
		}

		return preferences;
	}

	const answeStr = `Answer Preferences: ${qna.isNotRated ? "Not Rated" : (qna.preferredAnswer === AnyAnswerAcceptablePrompt ? "Any answer is acceptable" : qna.preferredAnswer)}`;
	const parsedPreferences = parseAnswerPreferences(answeStr);
	return parsedPreferences
}

const InterviewQuestions = ({ qna, handleOpenQuestionsMenu, handleCloseQuestionsMenu, questionsMenu, interviewId }) => {
	const [isEdit, setIsEdit] = useState(false)
	const navigate = useNavigate();

	const startEditQuestions = () => {
		setIsEdit(true)
		navigate(`/dashboard/interview/edit/${interviewId}`);
	}

	return (<div style={{ paddingBottom: '20px' }}>
		<Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
			<Typography sx={{ paddingTop: '25px', paddingLeft: '10px' }} variant='h5' gutterBottom>
				Interview Questions
			</Typography>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<IconButton size='large' color='inherit' onClick={handleOpenQuestionsMenu}>
					<Iconify icon={'eva:more-horizontal-fill'} />
				</IconButton>
			</Box>
			<Popover
				open={Boolean(questionsMenu.id)}
				anchorEl={questionsMenu.anchorEl}
				onClose={handleCloseQuestionsMenu}
				anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				PaperProps={{
					sx: {
						p: 1,
						width: 140,
						'& .MuiMenuItem-root': {
							px: 1,
							typography: 'body2',
							borderRadius: 0.75,
						},
					},
				}}
			>
				{/* TODO: enable */}
				<MenuItem onClick={startEditQuestions}>
					<Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
					Edit
				</MenuItem>

				{/* <MenuItem sx={{ color: 'error.main' }} onClick ={() => { deleteSession(candidateMenu.id); handleCloseCandidateMenu(); }}> */}
				{/*	<Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} /> */}
				{/*	Delete */}
				{/* </MenuItem> */}
			</Popover>
		</Grid>
		{
			isEdit ? (
				<div>
					Edit Mode
				</div>
			) : (
				qna?.map((qa, index) =>
					<Box display='flex' marginTop='30px' flexDirection='column' alignItems='start' key={index} sx={{ background: '#f0f4fa', padding: '20px', borderRadius: '4px' }}>
						<Typography variant='h6' align='left'>{index + 1}. {qa.question}</Typography>
						{(qa.preferredAnswer && !qa.isNotRated) && (
							<>
								<Typography variant="body1" align="left" marginTop="10px">
									<>{qna.isNotRated}</>
									<strong>Must:</strong> {answerPreferences(qa).must}
								</Typography>
								<Typography variant="body1" align="left" marginTop="10px">
									<>{qna.isNotRated}</>
									<strong>Must Not:</strong> {answerPreferences(qa).mustNot}
								</Typography>
							</>
						)
						}
						{
							(qa.preferredAnswer && qa.isNotRated) && (
								<Typography variant="body1" align="left" marginTop="10px">Do not rate</Typography>
							)
						}
						{/* {qa.preferredAnswer && <Typography variant='body1' align='left'> */}
						{/*	Preferred Answer: {qa.isNotRated ? "Not Rated" : qa.preferredAnswer === AnyAnswerAcceptablePrompt ? "Any answer is acceptable" : qa.preferredAnswer} */}
						{/*	</Typography> */}
						{/* } */}
					</Box>
				)
			)
		}
	</div>);
}



function descendingComparator(a, b, orderBy) {

	// Check if orderBy includes a period (nested property)
	if (orderBy.includes('.')) {
		const keys = orderBy.split('.');
		// Reduce to access nested property
		a = keys.reduce((obj, key) => obj[key], a);
		b = keys.reduce((obj, key) => obj[key], b);
	} else {
		// Access non-nested property
		a = a[orderBy];
		b = b[orderBy];
	}

	// Compare values
	if (b < a) {
		return -1;
	}
	if (b > a) {
		return 1;
	}
	return 0;
}


function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	if (query) {
		return stabilizedThis.map((el) => el[0]).filter(_session => _session?.candidate?.name?.toLowerCase()?.indexOf(query.toLowerCase()) !== -1);
	}
	return stabilizedThis.map((el) => el[0]);
}


function InterviewDetail() {
	const dispatch = useDispatch()
	const navigate = useNavigate();
	const [filteredUsers, setFilteredUsers] = useState([]);
	const { id: interviewId } = useParams();
	const [candidateMenu, setCandidateMenu] = useState({});
	const [questionsMenu, setQuestionsMenu] = useState({});
	const [page, setPage] = useState(0);
	const [selected, setSelected] = useState([]);
	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('candidateRating');
	const [filterName, setFilterName] = useState('');
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const { NewCandidate, show: showNewCandidateModal } = useNewCandidate(interviewId);
	const { AddCandidate, show: showAddCandidateModal } = useAddCandidate(interviewId);
	const { data: sessions, } = useFetchSessionsQuery(interviewId);
	const { data: interviewPlan } = useFetchInterviewPlanQuery(interviewId,
		{
			refetchOnMountOrArgChange: true,
		});
	const [deleteSession] = useDeleteSessionMutation();
	const { getAccessTokenSilently } = useAuth0()

	useEffect(() => {
		// New determineStatus function inside processData


		const processData = () => {
			
		if (sessions) {
				const enrichedData = sessions.map(c => {
					let average = 0;
					if (c.assessment) {
						c.assessment.forEach((record) => {
							if (record && record.score) average += record.score;
						});
						average /= c.plan?.questions.length || 1; // Prevent division by zero
					}

					// Use the determineStatus function here
					const status = determineStatus(c, interviewPlan);

					return {
						...c,
						avatarUrl: `https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${c.name}`,
						name: c.name,
						candidateRating: average,
						status // Set the status here
					};
				});

				return applySortFilter(enrichedData, getComparator(order, orderBy), filterName);
			}
			return [];
		}

		dispatch(setProcessedData({ processedData: processData() }))
		setFilteredUsers(processData());
	}, [sessions, order, orderBy, filterName, interviewPlan]);

	const determineStatus = (user, plan) => {
		if (!plan) return 'pending';

		// if (user.candidate.status === 'rejected') {
		// 	return 'rejected';
		// }

		let plansCompare = plan.questions

		const userVersion = user.version;

		if(_.find(plan?.previousQuestions, { version: userVersion })) {
			plansCompare = _.find(plan?.previousQuestions, { version: userVersion })?.questions || [];
		}

		const questionIds = new Set(plansCompare.map(q => q._id));

		if (!user.assessment) return 'pending';
		const assessmentIds = new Set(user.assessment.map(a => {
			if (a) return a.feedback;
			return null;
		}));

		if (questionIds.size === assessmentIds.size) {
			return 'complete';
		}
		return 'pending';
	};

	const startEditQuestions = () => {
		console.log('startEditQuestions');
	}

	function escapeCSVField(field) {
		if (field == null) { // Handle null or undefined
			return '';
		}
		let stringField = String(field);
		// Replace double quotes with two double quotes (CSV escaping)
		stringField = stringField.replace(/"/g, '""');
		// Replace newline characters with a space
		stringField = stringField.replace(/(\r\n|\n|\r)/gm, " ");
		// If the field contains commas, newlines, or double quotes, enclose it in double quotes
		if (stringField.search(/("|,|\n)/g) >= 0) {
			stringField = `"${stringField}"`;
		}
		return stringField;
	}

	function downloadCSV(csv, filename) {
		const csvFile = new Blob([csv], { type: "text/csv" });
		const downloadLink = document.createElement("a");
		downloadLink.download = filename;
		downloadLink.href = window.URL.createObjectURL(csvFile);
		downloadLink.style.display = "none";
		document.body.appendChild(downloadLink);
		downloadLink.click();
	}

	const exportSessionsToCSV = (sessions) => {
		// Step 1: Determine the maximum number of questions
		const maxQuestions = Math.max(...sessions.map(session => session.plan.questions.length));

		// Step 2: Dynamically create headers
		const headers = ["Candidate Name", "Email", "Role", "Overall Fit"];
		// eslint-disable-next-line no-plusplus
		for (let i = 1; i <= maxQuestions; i++) {
			headers.push(`Question ${i}`, `Rating for Question ${i}`, `Rating Explained ${i}`, `Candidate Answer ${i}`);
		}

		const csvRows = sessions.map(session => {

			const convertToPercentage = (number = 0) => {
				if (number < 1 || number > 5) {
					return 0;
				}
				return Math.floor(((number - 1) / 4) * 100);
			};

			const receivedRows = session?.plan.questions.map((qna, index) => ({
				isNotRated: qna?.isNotRated,
				question: qna.question,
				answer: qna.expectedAnswer,
				candidateAnswer: session.answers ? session.answers[index]?.transcript : '',
				explain: session.assessment ? session.assessment[index]?.feedback : '',
				ocr: session.assessment ? session.assessment[index]?.score : '',
				id: index
			})) ?? [];

			let average = 0;
			let notRatedCount = 0;
			receivedRows.forEach(({ isNotRated, ocr = 0 }) => {
				average += isNotRated ? 0 : ocr;

				if(isNotRated) {
					notRatedCount += 1
				}
			})
			average /= (receivedRows.length - notRatedCount);
			average = convertToPercentage(average);
			// average = (average / 5) * 100;

			const row = [
				escapeCSVField(session.candidate.name),
				escapeCSVField(session.candidate.email),
				escapeCSVField(session.plan.role),
				escapeCSVField(average || ''), // Assuming there's an overallFit property
			];

			// Add question, rating, and answer data to the row
			session.plan.questions.forEach((q, index) => {
				const assessment = session.assessment[index]; // Match assessment to question by index
				row.push(
					escapeCSVField(q.question),
					escapeCSVField(assessment ? assessment.score.toString() : ''),
					escapeCSVField(assessment ? assessment.feedback : ''),
					escapeCSVField(session.answers?.[index]?.transcript ?? '')
				);
			});

			// Fill in the blanks for sessions with fewer questions than maxQuestions

			// eslint-disable-next-line no-plusplus
			for (let i = session.plan.questions.length; i < maxQuestions; i++) {
				row.push('', '', ''); // Add empty strings for Question, Rating Explained, and Candidate Answer
			}

			return row.join(",");
		});

		// Combine headers and rows into the final CSV string
		const csvString = [headers.join(","), ...csvRows].join("\n");
		// Use moment to format the current date and time
		const timestamp = moment().format('YYYYMMDD-HHmmss');
		// Safely replace spaces with dashes in the interview plan role
		const safeRole = (interviewPlan?.role || 'role').replace(/\s+/g, '-');
		const filename = `interview-report-${safeRole}-${timestamp}.csv`;

		downloadCSV(csvString, filename);
	};

	const handleOpenMenu = (event, id) => {
		event.stopPropagation();
		setCandidateMenu({anchorEl: event.target, id});
	};

	const handleCloseCandidateMenu = () => {
		setCandidateMenu({});
	};

	const handleOpenQuestionsMenu = (event, id) => {
		event.stopPropagation();
		setQuestionsMenu({anchorEl: event.target, id: 'questions-menu'});
	};

	const handleCloseQuestionsMenu = () => {
		setQuestionsMenu({});
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = sessions.map((n) => n._id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleSelect = (event, rowId) => {
		event.stopPropagation();
		const selectedIndex = selected.indexOf(rowId);
		let newSelected = [];
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, rowId);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}
		setSelected(newSelected);
		return false;
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setPage(0);
		setRowsPerPage(parseInt(event.target.value, 10));
	};

	const deleteSelected = async () => {
		selected.forEach(async (id) => {
			deleteSession(id);
		});
	};

	const sendEmailSelected = async () => {
		selected.forEach(async (id) => {
			sendEmail(id, getAccessTokenSilently());
		});
	};

	const handleFilterByName = (event) => {
		setPage(0);
		setFilterName(event.target.value);
	};

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sessions.length) : 0;

	const isNotFound = !filteredUsers.length && !!filterName;

	const handleRowClicked = (session) => {
		const { _id: sessionId } = session;
		navigate(`/dashboard/interview/session/${sessionId}`, { state: { filteredUsers } });
	}

	const totalInterviews = filteredUsers.length;

	let pendingCount = 0;
	let completeCount = 0;
	let rejectedCount = 0;

	filteredUsers.map(user => {
		const s = determineStatus(user, interviewPlan);
		if (s === 'pending') {
			pendingCount += 1;
			user.candidate = {...user.candidate, status: 'pending'};
		} else if (s === 'complete') {
			completeCount += 1;
			user.candidate = {...user.candidate, status: 'complete'};
		} else if (s === 'rejected') {
			rejectedCount += 1;
			user.candidate = {...user.candidate, status: 'rejected'};
		}

		return user;
	});


	return (
		<Grid container rowSpacing={2}>
			<Grid item xs={12}>
				<Card sx={{ padding: '16px' }}>
					<Grid container>
						<Grid item xs={12} sx={{ margin: '20px 0px 0px 16px' }} >
							<Typography variant='h5'>
								{interviewPlan?.role}
							</Typography>
							<Grid sx={{ marginTop: '25px' }} container>


								<Grid item xs={2}>
									<Typography variant='body1'>
										Created
									</Typography>
									<Typography variant="body" display="block" gutterBottom>
										{moment(interviewPlan?.startTime).format('MMM DD, YYYY')}
									</Typography>

								</Grid>
								<Grid item xs={2}>
									<Typography variant='body1'>
										Job Post Synced
									</Typography>
									{
										interviewPlan?.jobPostDetails ? (
											<Label color={'success'}>Active</Label>
										) : (
											<Label color={'default'}>Not Connected</Label>
										)
									}

								</Grid>
								{/* <Grid item xs={2}> */}
								{/*    <Typography variant='body1'> */}
								{/*        Hiring Manager */}
								{/*    </Typography> */}
								{/*    <Typography variant='subtitle1'> */}
								{/*        Richie Smith */}
								{/*    </Typography> */}
								{/* </Grid> */}
								{/* <Grid item xs={2}> */}
								{/*	<Typography variant='body1'> */}
								{/*		Team */}
								{/*	</Typography> */}
								{/*	<Typography variant='subtitle1'> */}
								{/*		{interviewPlan?.company} */}
								{/*	</Typography> */}
								{/* </Grid> */}
								{
									interviewPlan?.jobPostDetails && (
										<Grid item xs={2}>
											<Typography variant='body1'>
												Link to Job Post
											</Typography>
											<Typography variant='subtitle1'>
												<Link href={interviewPlan?.jobPostDetails?.jobPostingUrls[0]?.value} target="_blank" rel="noopener noreferrer" underline="hover">
													Click new tab
												</Link>
											</Typography>

										</Grid>
									)
								}

								<Grid item xs={2}>
									<Typography variant='body1'>
										Download Report
									</Typography>
									<Typography variant='subtitle1' sx={{ cursor: 'pointer'}}>
										<Link onClick={() => exportSessionsToCSV(filteredUsers)} target="_blank" rel="noopener noreferrer" underline="hover">
											Click to Download
										</Link>
									</Typography>

								</Grid>

							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sx={{ marginTop: '20px' }}>
						<Grid container columnSpacing={2} rowSpacing={{ xs: 2 }}>
							<Grid item xs={12} sm={6} md={3}>
								<KpiWidget title='Scheduled Interviews' total={totalInterviews} icon={'mdi:calendar-multiple'} />
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<KpiWidget title='Completed Interviews' total={completeCount} color='success' icon={'mdi:check-circle'} />
							</Grid>

							<Grid item xs={12} sm={6} md={3}>
								<KpiWidget title='Pending Interviews' total={pendingCount} color='warning' icon={'mdi:clock'} />
							</Grid>

							<Grid item xs={12} sm={6} md={3}>
								<KpiWidget title='Cancelled Interviews' total={rejectedCount} color='error' icon={'mdi:cancel'} />
							</Grid>
						</Grid>

					</Grid>
				</Card>
			</Grid>


			<Grid item xs={12}>
				<Grid container rowSpacing={{ xs: 2 }} columnSpacing={2} >
					<Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
						<Stack spacing={{ xs: 1 }} direction={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'start', sm: 'center' }} justifyContent='space-between' mb={5} sx={{ marginBottom: '0px', padding: '15px 0px' }}>
							<Typography variant='h4'>
								Candidates
							</Typography>
							<Stack direction={{ xs: 'column', sm: 'row' }} alignItems='right' justifyContent='space-between' mb={5} spacing={2} sx={{ marginBottom: '0px' }}>
								<Button variant='contained' startIcon={<Iconify icon='eva:plus-fill' />} onClick={showNewCandidateModal}>
									New Candidate
								</Button>
								<Button variant='contained' onClick={showAddCandidateModal}>
									Import
								</Button>
							</Stack>
						</Stack>

						{NewCandidate}
						{AddCandidate}
						{sessions &&

							<Card>
								<UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} onDelete={deleteSelected} sendEmailSelected={sendEmailSelected}/>
								<Scrollbar>
									<TableContainer sx={{ minWidth: 800 }}>
										<Table>
											<UserListHead
												order={order}
												orderBy={orderBy}
												headLabel={TABLE_HEAD}
												rowCount={sessions.length}
												numSelected={selected.length}
												onRequestSort={handleRequestSort}
												onSelectAllClick={handleSelectAllClick}
												selectable
											/>
											<TableBody>
												{filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
													const { _id: id, assessment, candidate: { name, status } } = row;
													const statusLabel = status;
													let average = 0;
													let questionsLength = interviewPlan?.questions?.length;
													const planVersion = row?.version || 1;
													if(_.find(interviewPlan?.previousQuestions, { version: planVersion })) {
														questionsLength = _.find(interviewPlan?.previousQuestions, { version: planVersion })?.questions?.length || 0;
													}
													if (assessment) {
														assessment.forEach((record) => {
															if(record) average += record?.score;

														})
														average /= questionsLength;
													}
													let emailSent = row?.history?.sent
													if(emailSent) emailSent = moment(emailSent).format('MM/DD/YY, h:mm a')
													const candidateRating = average;
													const avatarUrl = `https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${name}`;
													const selectedUser = selected.indexOf(id) !== -1;


													return (
														<TableRow hover key={id} tabIndex={-1} role='checkbox' selected={selectedUser} onClick={() => handleRowClicked(row)} sx={{
															cursor: 'pointer',
														}}>
															<TableCell padding='checkbox'>
																<Checkbox checked={selectedUser} onClick={(event) => handleSelect(event, id)} />
															</TableCell>

															<TableCell component='th' scope='row' >
																<Stack direction='row' alignItems='center' spacing={2}>
																	<Avatar alt={name} src={avatarUrl} />
																	<Typography variant='subtitle2' noWrap>
																		{name}
																	</Typography>
																</Stack>
															</TableCell>

															{/* <TableCell align='left'>{redflag ? 'See Details' : 'None'}</TableCell> */}

															<TableCell align='left'>
																<Rating name='read-only' value={candidateRating} readOnly />
															</TableCell>

															<TableCell align='left'>
																<Label color={(statusLabel === 'pending' && 'warning') || 'success'}>{statusLabel}</Label>
															</TableCell>

															<TableCell align='left'>
																<Typography variant='subtitle3' noWrap>
																	{emailSent}
																</Typography>
															</TableCell>

															<TableCell align='right'>
																<IconButton size='large' color='inherit' onClick={(e)=>handleOpenMenu(e, id)}>
																	<Iconify icon={'eva:more-vertical-fill'} />
																</IconButton>
															</TableCell>


															{/* <TableCell align='right'>
																														<IconButton size='large' color='inherit' onClick={handleOpenMenu}>
																																<Iconify icon={'eva:more-vertical-fill'} />
																														</IconButton>
																												</TableCell> */}
														</TableRow>
													);
												})}
												{emptyRows > 0 && (
													<TableRow style={{ height: 53 * emptyRows }}>
														<TableCell colSpan={6} />
													</TableRow>
												)}
											</TableBody>


											{isNotFound && (
												<TableBody>
													<TableRow>
														<TableCell align='center' colSpan={6} sx={{ py: 3 }}>
															<Paper
																sx={{
																	textAlign: 'center',
																}}
															>
																<Typography variant='h6' paragraph>
																	Not found
																</Typography>

																<Typography variant='body2'>
																	No results found for &nbsp;
																	<strong>&quot;{filterName}&quot;</strong>.
																	<br /> Try checking for typos or using complete words.
																</Typography>
															</Paper>
														</TableCell>
													</TableRow>
												</TableBody>
											)}
										</Table>
									</TableContainer>
								</Scrollbar>
								<TablePagination
									rowsPerPageOptions={[5, 10, 25]}
									component='div'
									count={sessions.length}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
								/>
								<Popover
									open={Boolean(candidateMenu.id)}
									anchorEl={candidateMenu.anchorEl}
									onClose={handleCloseCandidateMenu}
									anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
									transformOrigin={{ vertical: 'top', horizontal: 'right' }}
									PaperProps={{
										sx: {
											p: 1,
											width: 140,
											'& .MuiMenuItem-root': {
												px: 1,
												typography: 'body2',
												borderRadius: 0.75,
											},
										},
									}}
								>
									{/* TODO: enable */}
									<MenuItem disabled>
										<Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
										Edit
									</MenuItem>

									<MenuItem sx={{ color: 'error.main' }} onClick ={() => { deleteSession(candidateMenu.id); handleCloseCandidateMenu(); }}>
										<Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
										Delete
									</MenuItem>
								</Popover>
							</Card>
						}
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
						<Card sx={{ height: '100%', padding: '0px 20px' }}>
							<InterviewQuestions qna={interviewPlan?.questions} handleOpenQuestionsMenu={handleOpenQuestionsMenu} handleCloseQuestionsMenu={handleCloseQuestionsMenu} questionsMenu={questionsMenu} startEditQuestions={startEditQuestions} interviewId={interviewId}/>
						</Card>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}


export default InterviewDetail;
