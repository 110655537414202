import React, { useState, useRef } from 'react';
import ReactMarkdown from "react-markdown";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import SendIcon from '@mui/icons-material/Send';
import { useAuth0 } from '@auth0/auth0-react';

import Iconify from 'components/iconify';
import { chatCandidateSession } from 'utils/service';
import styles from './Home.module.css';



function ChatBox({sessionId}) {
    const {getAccessTokenSilently} = useAuth0()
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [messageState, setMessageState] = useState({
        messages: [
            {
                message: 'Hi, what would you know about this interview? Example questions are "What details about this candidate displays their professionalism?" or "What are the candidate\'s strengths and weaknesses?"',
                type: 'apiMessage',
            },
        ],
        history: [],
    });

    const { messages, history } = messageState;

    async function handleSubmit(e) {
        e.preventDefault();

        setError(null);

        if (!query) {
            alert('Please input a question');
            return;
        }

        const question = query.trim();

        setMessageState((state) => ({
            ...state,
            messages: [
                ...state.messages,
                {
                    type: 'userMessage',
                    message: question,
                },
            ],
        }));

        setLoading(true);
        setQuery('');

        try {
            const data = await chatCandidateSession(sessionId, getAccessTokenSilently(), {question, history})

            if (data.error) {
                setError(data.error);
            } else {
                setMessageState((state) => ({
                    ...state,
                    messages: [
                        ...state.messages,
                        {
                            type: 'apiMessage',
                            message: data.text,
                            sourceDocs: data.sourceDocuments,
                        },
                    ],
                    history: [...state.history, [question, data.text]],
                }));
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError('An error occurred while fetching the data. Please try again.');
        }
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter' && query) {
            handleSubmit(e);
        } else if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    return (
        <main className={styles.main}>
            <div className={styles.cloud}>
                <div className={styles.messagelist}>
                    {messages.map((message, index) => {
                        let icon;
                        let className;
                        if (message.type === 'apiMessage') {
                            icon = (
                                <Iconify icon="fluent-mdl2:chat-bot"/>
                            );
                            className = styles.apimessage;
                        } else {
                            icon = (
                                <Iconify icon="mdi:user"/>
                            );
                            className =
                                loading && index === messages.length - 1
                                    ? styles.usermessagewaiting
                                    : styles.usermessage;
                        }
                        return (
                            <>
                                <div key={`chatMessage-${index}`} className={className}>
                                    {icon}
                                    <div className={styles.markdownanswer}>
                                        <ReactMarkdown linkTarget="_blank" className={styles.messageCustom}>
                                            {message.message}
                                        </ReactMarkdown>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </div>
            <div className={styles.center}>
                <div className={styles.cloudform}>
                    <form className={styles.chatForm} onSubmit={handleSubmit}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                id="userInput"
                                name="userInput"
                                placeholder={loading ? 'Waiting for response...' : 'Ask any questions regarding the interview'}
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                disabled={loading}
                                multiline
                                rows={2} // Set default rows to 2
                                maxLength={512}
                                variant="outlined"
                                fullWidth
                                style={{ flex: 1, marginRight: '10px' }} // Expand the entire container and add margin to the right
                            />
                            <Button
                                type="submit"
                                style={{ padding: '27px' }}
                                disabled={loading}
                                variant="contained"
                                color="primary"
                                endIcon={loading ? <CircularProgress size={24} /> : <SendIcon />}
                            >
                                {loading ? 'Loading' : 'Submit'}
                            </Button>
                        </div>


                    </form>
                </div>
            </div>
            {error && (
                <div className="border border-red-400 rounded-md p-4">
                    <p className="text-red-500">{error}</p>
                </div>
            )}
        </main>
    );
}

export default ChatBox;
