import React from 'react';
import { Box } from '@mui/material';

function NumberCircle({ number, backgroundColor = 'blue', size = 50 }) {
    return (
        <Box
            sx={{
                backgroundColor,
                color: 'white',
                width: size,
                height: size,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                padding: '15px', // Adjust padding based on your needs
                fontSize: '20px', // Adjust font size based on your needs
                fontWeight: '400'
            }}
        >
            {number}
        </Box>
    );
}

export default NumberCircle;
