import { useSelector } from 'react-redux';
import { companyATSDetailsSelector } from 'store/company';
import LockedView from './LockedView';

export const RequireATSDetails = ({ children }) => {
  const companyATSDetails = useSelector(companyATSDetailsSelector);

  if (!companyATSDetails?.integrationDetails) {
    // Render LockedView if ATS details are not present
    return <LockedView />;
  }

  return children;
};

