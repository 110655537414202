import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Typography,
  Button,
  Grid,
  Container,
  Box,
  TextField,
  ButtonGroup,
  Alert,
  IconButton,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import LoadingButton from '@mui/lab/LoadingButton';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import Lottie from "lottie-react";
import 'react-loading-skeleton/dist/skeleton.css'
import _ from 'lodash';
import QuestionAnimation from 'animations/questions-animation.json';
import { useMergeLink } from "@mergeapi/react-merge-link";



import {
  useFetchJobs,
  useFetchPregeneratedQuestions, fetchLinkTokenStart, useGenerateQuestionsForRoleAndGroup, saveAccountToken,
} from 'utils/service';
import { AnyAnswerAcceptablePrompt } from 'utils/constants';
import { useFetchInterviewPlanQuery, useSendInterviewSetupMutation, useEditInterviewPlanMutation } from 'store/api';
import QnAForm from "./QandAForm";
import { mustsExtractions } from '../utils/utils';

const FooterContainer = styled.div`
    display: flex;
    justify-content: end;
    margin-top: 40px;
`

const GROUPS = ['pre-screening', 'technical', 'behavioral', 'culture'];
type GroupsType = typeof GROUPS;
type GroupType = GroupsType[number];
type Group = { [subgroup: string]: string[] };
type GrouppedQuestions = { [group: string]: Group };

function deepMergeGroupedQuestions(...objects: GrouppedQuestions[]): GrouppedQuestions {
  const result: GrouppedQuestions = {};

  objects
    .filter(obj => !!obj)
    .forEach(obj => {
      Object.entries(obj)
        .filter(([, subgroups]) => !!subgroups)
        .forEach(([group, subgroups]) => {
          result[group] = result[group] || {};
          Object.entries(subgroups!)
            .filter(([, questions]) => !!questions)
            .forEach(([subgroup, questions]) => {
              const existingQuestions = result[group][subgroup] || [];
              const newQuestions = questions.filter(question => !existingQuestions.includes(question));
              result[group][subgroup] = [...existingQuestions, ...newQuestions]
            });
        });
    });

  return result;
}

type QnA = { question: string, isNotRated?: boolean, preferredAnswer: string };

const EditInterview = () => {
  const navigate = useNavigate();
  const { id: interviewId } = useParams();
  const [grouppedQuestions, setGrouppedQuestions] = useState<GrouppedQuestions>({});
  const [questions, setQuestions] = useState<string[]>([]);
  const [subgroups, setSubgroups] = useState<string[]>([]);
  const [group, setGroup] = useState<GroupType>('pre-screening');
  const [subgroup, setSubgroup] = useState<string>();
  const [role, setRole] = useState<string>('');
  const [roleEntered, setRoleEntered] = useState(false);
  const [editingIndex, setEditingIndex] = useState<any>(null);
  const [linkToken, setLinkToken] = useState<any>(null);
  const [publicToken, setPublicToken] = useState<any>('');
  const [currentEditQuestion, setCurrentEditQuestion] = useState('')
  const [currentEditQuestionDoNotRate, setCurrentEditQuestionDoNotRate] = useState(false)
  const [currentEditQuestionPrefMust, setCurrentEditQuestionPrefMust] = useState('')
  const [currentEditQuestionPrefMustNot, setCurrentEditQuestionPrefMustNot] = useState('')
  const isEditingMode = editingIndex !== null;

  const [{ data: linkTokenStart, error: errorLinkTokenStart }] = useState<any>('');
  const { data: interviewPlan } = useFetchInterviewPlanQuery(interviewId,
      {
        refetchOnMountOrArgChange: true,
      });

  useEffect(() => {
    if (interviewPlan) {
      const { role, questions } = interviewPlan;
      generateQuestions({ role });
      // Create a deep copy of the questions to ensure they are mutable
      const mutableQuestions = questions.map(question => ({
        ...question
      }));
      setSelectedQuestions(mutableQuestions);
      setRole(role);
      setRoleEntered(true);
    }
  }, [interviewPlan]);


  const [selectedQuestions, setSelectedQuestions] = useState<QnA[]>([]);
  const [error, setError] = useState<string>();
  const [{ data: pregeneratedQuestions, error: pregeneratedQuestionsError }] = useFetchPregeneratedQuestions();
  // const [{ data: jobs = [], error: fetchJobsError }] = useFetchJobs(publicToken);
  const [{ data: generatedQuestions, loading: isLoadingGeneratedQuestions, error: generatedQuestionsError }, generateQuestions]
    = useGenerateQuestionsForRoleAndGroup();
  const [{ data: generatedQuestionsForGroup, loading: isLoadingGeneratedQuestionsForGroup, error: generatedQuestionsForGroupError },
    generateQuestionsForGroup]
    = useGenerateQuestionsForRoleAndGroup();
  const [sendInterviewSetup, { isSuccess: isPlanSaved, error: savingPlanError, data: savedPlan }] = useSendInterviewSetupMutation();
  const [editInterviewSetup, { isSuccess: isInterviewEdited, error: editingInterviewError, data: interviewEditComplete }] = useEditInterviewPlanMutation();


  useEffect(() => {
    if (isInterviewEdited) {
      navigate(`/dashboard/interview/${interviewId}`);
    }
  }, [isInterviewEdited]);

  useEffect(() => {
    // Check if there is new data
    if (linkTokenStart) {
      setLinkToken(linkTokenStart.linkToken); // Update the linkToken state with the new data
    }

    // Optionally, handle the error state
    if (errorLinkTokenStart) {
      console.error('Error fetching link token:', error);
      // Implement error handling logic
    }
  }, [linkTokenStart, errorLinkTokenStart]);

  // Callback for useMergeLink
  const onSuccess = useCallback(async (public_token) => {
    await saveAccountToken(public_token);
    // Send public_token to server (Step 3)
  }, []);

  // Use the linkToken in useMergeLink
  const { open, isReady } = useMergeLink({
    linkToken, // Use the state here
    onSuccess,
  });

  // Function to reorder the list after a drag ends
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  // Handler for when drag ends
  const onDragEnd = (result) => {
    if (!result.destination) {
      return; // If dropped outside the list
    }

    const items = reorder(
      selectedQuestions,
      result.source.index,
      result.destination.index
    );

    // @ts-ignore
    setSelectedQuestions(items);
  };

  useEffect(() => setError(pregeneratedQuestionsError?.message || generatedQuestionsError?.message || generatedQuestionsForGroupError?.message
    || savingPlanError as string),
    [generatedQuestionsError?.message, generatedQuestionsForGroupError?.message, pregeneratedQuestionsError?.message, savingPlanError]);
  useEffect(() => {

    setGrouppedQuestions((original) => deepMergeGroupedQuestions(original, pregeneratedQuestions, generatedQuestions, generatedQuestionsForGroup));

  }, [pregeneratedQuestions, generatedQuestions, generatedQuestionsForGroup]);

  useEffect(() => {
    if (grouppedQuestions[group]) {
      setSubgroups(Object.keys(grouppedQuestions[group]));
    }


  }, [grouppedQuestions, group]);


  useEffect(() => {
    // If the group or subgroup changes, update the questions with X questions from the selected subgroup
    const x = 7;

    if (grouppedQuestions[group]) {

      let q;
      if (subgroup) {
        q = (grouppedQuestions[group][subgroup]);
      } else {
        q = Object.values(grouppedQuestions[group]).flatMap((facet) => facet);
      }
      q = q ?? [];
      q.sort((a, b) => a.length - b.length);

      q = q.slice(0, x);
      setQuestions(q);
    }

  }, [grouppedQuestions, group, subgroup]);

  const handleSubgroupClick = async (subG: string) => {
    setSubgroup(subG);

    if (grouppedQuestions[group][subG] && grouppedQuestions[group][subG].length >= 10) {
      return;
    }
    if (subG) {
      generateQuestionsForGroup({ role: role!, group, subgroup: subG });
    }
  };

  const handleGroupClick = (newGroup: GroupType) => {
    setGroup(newGroup);
    setSubgroup(undefined);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleRoleEntered = async () => {
    try {
      if (role) {
        generateQuestions({ role });

        setRoleEntered(true);
      }

    } catch (e) {
      console.error("Error fetching role:", e);
    }
  };

  useEffect(() => {
    if(savedPlan) {
      navigate(`/dashboard/interview/${savedPlan._id}`)
    }
  }, [savedPlan, navigate]);

  const editPlan = async () => {
    if (selectedQuestions.length === 0) {
      setError('Please select at least one question');
      return;
    }
    editInterviewSetup({ interview: {role, questions: selectedQuestions}, planId: interviewId });
  }

  const onCancel = () => {
    navigate(`/dashboard/interview/${interviewId}`);
  }

  const handleEditQuestion = (index, newQuestion) => {
    const updatedQuestions = [...selectedQuestions];
    updatedQuestions[index].question = newQuestion;
    setSelectedQuestions(updatedQuestions);
  };


  const handleEditQuestionMusts = (index, newMust, mustNot) => {
    const updatedQuestions = [...selectedQuestions];
    updatedQuestions[index].preferredAnswer = `Must: ${newMust}\n Must Not: ${mustNot}`;
    setSelectedQuestions(updatedQuestions);
  };

  const handleEditQuestionDoNotRate = (index, isNotRate) => {
    const updatedQuestions = [...selectedQuestions];
    updatedQuestions[index].isNotRated = isNotRate;
    setSelectedQuestions(updatedQuestions);
  };

  const startEditQuestion = (index) => {
    if (editingIndex === index) {
      setEditingIndex(null);
      return;
    }
    setEditingIndex(index);
    setCurrentEditQuestion(selectedQuestions[index].question);
    // @ts-ignore
    setCurrentEditQuestionDoNotRate(selectedQuestions[index].isNotRated)
    const preferences = mustsExtractions(selectedQuestions[index].preferredAnswer)
    setCurrentEditQuestionPrefMust(preferences.must);
    setCurrentEditQuestionPrefMustNot(preferences.mustNot);

    // @ts-ignore
    document.getElementById('question-start').scrollIntoView({ behavior: 'smooth' });
  }

  const doneEditQuestion = () => {
    setEditingIndex(null)
    setCurrentEditQuestion('');
    setCurrentEditQuestionPrefMust('');
    setCurrentEditQuestionPrefMustNot('');
    setCurrentEditQuestionDoNotRate(false);
  }

  const handleDeleteQuestion = (index) => {
    // Filter out the question at the specified index
    const updatedQuestions = selectedQuestions.filter((_, questionIndex) => questionIndex !== index);
    // Update the state
    setSelectedQuestions(updatedQuestions);
    setEditingIndex(null);
  };


  const answerPreferences = (qna) => {
    function parseAnswerPreferences(preferencesString) {
      // Initialize an object to hold the parsed preferences
      const preferences = {
        must: '',
        mustNot: ''
      };

      // Extract the 'Must' and 'Must Not' parts from the string
      const mustMatch = preferencesString.match(/Must:([^]+?)(Must Not:|$)/);
      const mustNotMatch = preferencesString.match(/Must Not:([^]+)/);

      // If matches are found, trim them and assign to the preferences object
      if (mustMatch) {
        preferences.must = mustMatch[1].trim();
      }
      if (mustNotMatch) {
        preferences.mustNot = mustNotMatch[1].trim();
      }

      return preferences;
    }

    const answeStr = `Answer Preferences: ${qna.isNotRated ? "Not Rated" : (qna.preferredAnswer === AnyAnswerAcceptablePrompt ? "Any answer is acceptable" : qna.preferredAnswer)}`;
    const parsedPreferences = parseAnswerPreferences(answeStr);
    return parsedPreferences
  }

  return (
    <>
      <Helmet>
        <title> Live Interview | Teambooster AI </title>
      </Helmet>

      <Container>
        <div className="error-banner">
          {
            error && (
              <Alert severity="error">{JSON.stringify(error)}</Alert>
            )
          }
        </div>

        <>
          <Box marginTop="70px">
            <Grid item xs={12}>
              <Typography variant="h5" marginBottom="10px">{_.upperFirst(role)}</Typography>
              <Typography fontWeight={400} variant="subtitle1">Edit Interview Plan</Typography>
            </Grid>
            <Grid item marginTop="30px" marginBottom="20px" xs={12}>
              <ButtonGroup sx={{ marginBottom: '20px' }} variant="contained" aria-label="outlined primary button group">
                <Button
                    size="large"
                    color={group === 'pre-screening' ? 'secondary' : 'primary'}
                    onClick={() => handleGroupClick('pre-screening')}
                >
                  Pre-screen
                </Button>
                <Button
                    size="large"
                    color={group === 'culture' ? 'secondary' : 'primary'}
                    onClick={() => handleGroupClick('culture')}
                >
                  Culture Fit
                </Button>
                <Button
                    size="large"
                    color={group === 'behavioral' ? 'secondary' : 'primary'}
                    onClick={() => handleGroupClick('behavioral')}
                >
                  Behaviorial
                </Button>
                <LoadingButton loading={isLoadingGeneratedQuestions}
                               size="large"
                               loadingIndicator="Loading…"
                               variant='contained'
                               color={group === 'technical' ? 'secondary' : 'primary'}
                               onClick={() => handleGroupClick('technical')}
                >
                  Technical
                </LoadingButton>

              </ButtonGroup>

              <Box marginTop="10px">
                {
                  subgroups.map((tagText, index) =>
                      <Button
                          size='small'
                          key={index}
                          variant={tagText === subgroup ? 'outlined' : undefined}
                          color='primary'
                          style={{ marginRight: '10px', marginBottom: '10px' }}
                          onClick={() => handleSubgroupClick(tagText)}
                      >
                        {tagText}
                      </Button>
                  )
                }
                {
                    isLoadingGeneratedQuestions && <LoadingButton loading />
                }
              </Box>

            </Grid>

            <QnAForm isLoading={isLoadingGeneratedQuestionsForGroup || isLoadingGeneratedQuestions} questions={selectedQuestions} setQuestions={setSelectedQuestions} roleQs={questions} selected={subgroup}
                     editIndex={editingIndex}
                     handleEditQuestion={handleEditQuestion}
                     handleEditQuestionMusts={handleEditQuestionMusts}
                     handleEditQuestionDoNotRate={handleEditQuestionDoNotRate}
                     initialQuestion={currentEditQuestion}
                     initialDoNotRate={currentEditQuestionDoNotRate}
                     initialMustHave={currentEditQuestionPrefMust}
                     initialMustNotHave={currentEditQuestionPrefMustNot}
                     doneEditQuestion={doneEditQuestion}
                     handleDeleteQuestion={handleDeleteQuestion}
            />
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="selectedQuestionsDroppable">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {selectedQuestions.map((qna, index) => (
                          <Draggable key={index} draggableId={`item-${index}`} index={index}>
                            {(provided, snapshot) => (
                                <Box
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="start"
                                    marginTop="30px"
                                    key={index}
                                    position="relative"
                                    style={{
                                      marginBottom: '8px',
                                      cursor: 'grab',
                                      boxShadow: snapshot.isDragging ? 'rgba(0, 0, 0, 0.16) 0px 1px 4px' : 'none',
                                      background: '#f4f5f6',
                                      padding: '20px',
                                      borderRadius: '6px',
                                      // Apply the provided style here safely
                                      ...provided.draggableProps.style,
                                    }}
                                    onMouseOver={(e) => {
                                      if (e.currentTarget) {
                                        e.currentTarget.style.boxShadow = 'rgba(0, 0, 0, 0.16) 0px 1px 4px';
                                      }
                                    }}
                                    onMouseOut={(e) => {
                                      if (e.currentTarget) {
                                        e.currentTarget.style.boxShadow = snapshot.isDragging ? 'rgba(0, 0, 0, 0.16) 0px 1px 4px' : 'none';
                                      }
                                    }
                                    } // Adjusted line
                                >
                                  <Box position="absolute" top={0} right={0}>
                                    <IconButton onClick={() => startEditQuestion(index)}>
                                      <ModeEditIcon />
                                    </IconButton>
                                  </Box>
                                  <Typography variant="h6" align="left">{index + 1}. {qna.question}</Typography>
                                  {(qna.preferredAnswer && !qna.isNotRated) && (
                                      <>
                                        <Typography variant="body1" align="left" marginTop="10px">
                                          <>{qna.isNotRated}</>
                                          <strong>Must:</strong> {answerPreferences(qna).must}
                                        </Typography>
                                        <Typography variant="body1" align="left" marginTop="10px">
                                          <>{qna.isNotRated}</>
                                          <strong>Must Not:</strong> {answerPreferences(qna).mustNot}
                                        </Typography>
                                      </>
                                  )
                                  }
                                  {
                                      (qna.preferredAnswer && qna.isNotRated) && (
                                          <Typography variant="body1" align="left" marginTop="10px">Do not rate</Typography>
                                      )
                                  }
                                </Box>
                            )}
                          </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </>

        <FooterContainer>
          <Button variant="contained" color="inherit" onClick={onCancel} sx={{marginRight: '20px'}}>
            Cancel
          </Button>
          {
            roleEntered && questions && questions.length > 0 && !isPlanSaved && ( // Don't show the button if the plan is already saved
              <Button variant="contained" color="primary" onClick={editPlan} disabled={(selectedQuestions.length === 0 || isEditingMode)}>
                Finish Editing
              </Button>
            )
          }
        </FooterContainer>
      </Container>
    </>
  );
};

export default EditInterview;