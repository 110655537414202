import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Typography,
  Button,
  Grid,
  Container,
  Stack,
  Box,
  Card,
  CardContent,
  CardActions,
  Avatar,
  Tab,
  Tabs,
  TextField,
  IconButton,
  Menu,
  Snackbar
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDeleteWelshClientMutation, useFetchWelshClientsQuery } from 'store/api';
import moment from "moment-mini";
import Label from "../label";


const ClientsView = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [clientInEdit, setClientInEdit] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [clientMenuOpen, setClientMenuOpen] = useState(false);
  const [openNotify, setOpenNotify] = useState(false);
  const [notifyMessage, setNotifyMessage] = useState('');
  const navigate = useNavigate();
  const { data: clientsData = {} } = useFetchWelshClientsQuery({
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    pollingInterval: 60000,
  });
  const vertical = 'top';
  const horizontal = 'center';

    const [deleteWelshClient, { isSuccess: deleteWelshClientSuccess, error: deleteWelshClientError, data: deleteWelshClientResponse, isLoading: deleteWelshClientLoading }] = useDeleteWelshClientMutation();

  const response = clientsData?.response;

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const filteredClients = response?.filter((client) => {
    if(client && client.companyName) {
        return client?.companyName.toLowerCase().includes(searchQuery.toLowerCase())
    }
    return false;

  });

  const addNewClient = () => {
    navigate(`/dashboard/agent-welsh/add`);
  }

  const viewClient = (client) => {
    const id = client?._id || null;
    if(id) navigate(`/dashboard/agent-welsh/client/${id}`);

  }

  const truncatedTypography = (text) => {
    const MAX_CHAR_LIMIT = 200;
    const truncatedText = text.length > MAX_CHAR_LIMIT
        ? `${text.substring(0, MAX_CHAR_LIMIT)  }...`
        : text;

    return truncatedText;
  }

  const handleDeleteClient = async () => {
    console.log('clientInEdit', clientInEdit)
    setClientMenuOpen(false);
    setOpenNotify(true);
    setNotifyMessage('Deleting client');
    await deleteWelshClient(clientInEdit._id);
    setClientInEdit(null);
  }

  const handleCloseNotify = () => {
    setOpenNotify(false);
    setNotifyMessage('');
  }

  const handleCloseClientMenu = () => {
    setClientMenuOpen(false);
  }

  const clientMenuClick = (event, client) => {
    setClientInEdit(client);
    setAnchorEl(event.currentTarget);
    setClientMenuOpen(true);
  }

  const renderClientCard = (client) => (
    <Grid item xs={12} sm={6} md={4} key={client.id}>
      <Card>
        <CardContent sx={{height: 200}}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Box sx={{marginLeft: '20px !important'}} width={'100%'}>
              <Grid container>
                <Grid item xs={6} md={6} justifyContent={'start'} alignContent={'center'}>
                  <Typography variant="h6">{client.companyName}</Typography>
                </Grid>
                <Grid item xs={6} md={6} display="grid" justifyContent={'end'} alignItems={'center'}>
                  <IconButton aria-label="menu" onClick={(event) => clientMenuClick(event, client)}>
                  <MoreVertIcon />
                  </IconButton>
                  <Menu
                    key={client._id}
                    anchorEl={anchorEl}
                    open={clientMenuOpen}
                    onClose={handleCloseClientMenu}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                      <Button onClick={handleDeleteClient} color="error" startIcon={<DeleteIcon />}>Delete</Button>
                  </Menu>
                </Grid>
              </Grid>
              <Typography variant="subtitle2" color="textSecondary" gutterBottom>{client.companyWebsite}</Typography>
              <Typography variant="body2">{truncatedTypography(client.companyDescription)}</Typography>
            </Box>
          </Stack>
        </CardContent>
        <CardActions sx={{padding: '20px'}}>
          <Button size="medium" variant="contained" fullWidth onClick={() => viewClient(client)}>View</Button>
        </CardActions>
      </Card>
    </Grid>
  );

  return (
    <>
      <Helmet>
        <title>Clients View</title>
      </Helmet>
      <Container>
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={openNotify}
            onClose={handleCloseNotify}
            message={notifyMessage}
            autoHideDuration={3000}
            key={`${vertical + horizontal}`}
        />
        <Box display="flex" flexDirection="column" sx={{ marginTop: '30px' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} width="100%">
             <Typography variant="h4" gutterBottom align="center">
              Clients
             </Typography>
             <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={addNewClient}>
              Add new client
             </Button>
          </Stack>
          <Tabs value={tabValue} onChange={handleTabChange} centered>
            <Tab label={`All Clients (${response?.length})`} />
          </Tabs>
          <Box mt={3} mb={3} width="100%">
            <TextField
              fullWidth
              placeholder="Search here"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon position="start" />,
              }}
            />
          </Box>
          <Grid container spacing={3}>
            {response && filteredClients.map(renderClientCard)}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default ClientsView;
