import SvgColor from 'components/svg-color';
import ArticleIcon from '@mui/icons-material/Article';
import WorkIcon from '@mui/icons-material/Work';
import React from 'react';

// Function to generate icon component
const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

// Read the welsh-ai-enable flag from localStorage
export const welshEnabled = localStorage.getItem('welsh-ai-enable');

// Define the navConfig based on the welshEnabled flag
// eslint-disable-next-line import/no-mutable-exports
let preConfig;

if (welshEnabled) {
  // If Welsh AI is enabled, only include the Welsh AI nav item
  preConfig = [
    {
      title: 'Welsh AI',
      path: '/dashboard/agent-welsh',
      icon: icon('ic_lock'),
    }
  ];
} else {
  // If Welsh AI is not enabled, include the default nav items
  preConfig = [
    {
      title: 'dashboard',
      path: '/dashboard/app',
      icon: icon('ic_analytics'),
    },
    {
      title: 'interview plans',
      path: '/dashboard/interview',
      icon: <ArticleIcon />,
    },
    {
      title: 'job posts',
      path: '/dashboard/jobs',
      icon: <WorkIcon />,
    },
    {
      title: 'candidates',
      path: '/dashboard/candidates',
      icon: icon('ic_user'),
    }
  ];
}

const navConfig = [
  {
    title: 'Welsh AI',
    path: '/dashboard/agent-welsh',
    icon: icon('ic_lock'),
  }
];

// Export the navConfig
export default navConfig;
