import React, { useEffect, useRef, useState } from 'react';
import Lottie, { LottieRef, LottieRefCurrentProps } from 'lottie-react';
import AiMic from 'animations/ai-mic.json';
import { Box } from '@mui/system';
import { BoxProps, Avatar, Typography } from '@mui/material';

type QuestinyParams = {
  play: boolean,
  handleEnded?: () => void,
  quiet: boolean,
  vapi?: any // You might want to replace 'any' with a more specific type for vapi
} & BoxProps;

const Questiny = ({
                    play,
                    handleEnded,
                    quiet,
                    vapi,
                    ...props
                  }: QuestinyParams) => {
  const [startAnswering, setStartAnswering] = useState(false);
  const [showText, setShowText] = useState(false);
  const lottieRef: LottieRef = useRef<LottieRefCurrentProps>(null);

  const visibleStyle = {
    opacity: 1,
    transition: 'opacity 2s ease-in-out'
  };

  const hiddenStyle = {
    opacity: 0,
    transition: 'opacity 2s ease-in-out'
  };

  useEffect(() => {
    if (quiet) {
      lottieRef.current?.setSpeed(0.7);
    } else {
      lottieRef.current?.setSpeed(1);
    }
  }, [quiet]);

  useEffect(() => {
    if (!lottieRef.current) return;

    if (play) {
      lottieRef.current?.setSpeed(0.8);
      lottieRef.current.play();
    } else {
      lottieRef.current.pause();
    }
  }, [play]);

  useEffect(() => {
    const handleSpeechStart = () => {
      console.log("Assistant speech has started.");
      if (lottieRef.current) {
        lottieRef.current.play();
      }
    };

    const handleSpeechEnd = () => {
      console.log("Assistant speech has ended.");
      if (lottieRef.current) {
        lottieRef.current.pause();
      }
      setStartAnswering(true);
      setShowText(true);

      setTimeout(() => {
        setStartAnswering(false);

        setTimeout(() => {
          setShowText(false);
        }, 2000);
      }, 5000);

      handleEnded?.();
    };

    vapi.on("speech-start", handleSpeechStart);
    vapi.on("speech-end", handleSpeechEnd);

    return () => {
      vapi.off("speech-start", handleSpeechStart);
      vapi.off("speech-end", handleSpeechEnd);
    };
  }, [vapi, handleEnded]);

  return (
    <Box {...props}>
      <Avatar
        src="/assets/images/avatars/ashley.jpg"
        sx={{ width: 170, height: 170, border: '5px solid white', zIndex: 100, position: 'absolute' }}
      />
      <Lottie
        lottieRef={lottieRef}
        autoplay={false}
        animationData={AiMic}
        style={{width: '210px'}}
      />
      <Typography
        variant="body1"
        component="h2"
        color={'#2165d1'}
        position={'absolute'}
        top={'550px'}
        style={showText ? visibleStyle : hiddenStyle}
      >
        Start Answering
      </Typography>
    </Box>
  );
};

export default Questiny;