import PropTypes from 'prop-types';
// @mui
import { Box, Checkbox, TableRow, TableCell, TableHead, TableSortLabel } from '@mui/material';

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

UserListHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  sortable: PropTypes.bool, // new prop to control if the table is sortable
};

UserListHead.defaultProps = {
  sortable: true, // by default, the table is sortable
};

export default function UserListHead({
                                       order,
                                       orderBy,
                                       rowCount,
                                       headLabel,
                                       numSelected,
                                       onRequestSort,
                                       onSelectAllClick,
                                       selectable = false,
                                       sortable, // include the new prop here
                                     }) {
  const createSortHandler = (property) => (event) => {
    if (sortable) {
      onRequestSort(event, property);
    }
  };

  return (
      <TableHead>
        <TableRow>
          {selectable &&
              <TableCell padding="checkbox">
                <Checkbox
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={onSelectAllClick}
                />
              </TableCell>
          }
          {headLabel.map((headCell) => (
              <TableCell
                  key={headCell.id}
                  align={headCell.alignRight ? 'right' : 'left'}
                  sortDirection={sortable && orderBy === headCell.id ? order : false}
              >
                {sortable ? (
                    <TableSortLabel
                        hideSortIcon
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                          <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
                      ) : null}
                    </TableSortLabel>
                ) : (
                    // If not sortable, just render the label without the sort handler
                    headCell.label
                )}
              </TableCell>
          ))}
        </TableRow>
      </TableHead>
  );
}
