/* eslint-disable no-debugger */
import { useParams, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { filter } from 'lodash';
import moment from 'moment-mini';
import {
  Avatar, Box,
  Card,
  Checkbox,
  Container,
  Grid, IconButton, Paper, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer, TablePagination,
  TableRow,
  Typography,
  Rating,
  Button,
  Tabs, Tab, MenuItem, Select,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useAuth0 } from '@auth0/auth0-react';


import { sendEmail } from 'utils/service';
import {
  useCreateSessionMutation,
  useFetchInterviewPlanQuery,
  useFetchJobPostApplicationAtsQuery,
  useFetchJobPostAtsQuery,
  useUpdateStatusApplicantAtsMutation,
  useCreateSessionForAppMutation,
  useUpdateSessionPlanIdMutation,
  useFetchJobPostCandidatesAtsQuery
} from 'store/api';
import { UserListHead } from '../sections/@dashboard/candidate';
import Scrollbar from '../components/scrollbar/Scrollbar';
import Iconify from '../components/iconify';
import ChatBox from './Chatbox';
import styles from './Home.module.css';
import DonutChart from '../components/DonutChart';
import { userInfoSelector } from '../store/user';



const TABLE_HEAD = [
  { id: 'question', label: 'Question', alignRight: false },
  { id: 'candidateAnswer', label: "Candidate's Answer", alignRight: false },
  { id: 'explain', label: 'Rating Explained', alignRight: false },
  { id: 'ocr', label: 'Answer Rating', alignRight: false },
];

function JobPostSession() {
  const navigate = useNavigate();
  const metadata = useSelector((state) => state.interviewPlan.metadata)
  const processData = useSelector((state) => state.interviewPlan.processedData)
  const { applicationId, jobId } = useParams();
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tabActive, setTabActive] = useState(0);
  const [rows, setRows] = useState([]);
  const [potentialFit, setPotentialFit] = useState('Potential Fit')
  const [averageCalc, setAverageCalc] = useState(0)
  const [candidateIndex, setCandidateIndex] = useState(0)
  const [interviewPlanId, setInterviewPlanId] = useState(null);
  const userInfo = useSelector(userInfoSelector)
  const { getAccessTokenSilently } = useAuth0()
  const NOT_RATED = "Not Rated"


  // const { data: session, refetch } = useFetchSessionQuery(id, {
  //   refetchOnMountOrArgChange: true,
  //   refetchOnReconnect: true,
  //   pollingInterval: 60000,
  // });

  const { data: application, refetch: applicationRefetch } = useFetchJobPostApplicationAtsQuery(applicationId, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  const { data: jobPost, refetch: jobPostRefetch } = useFetchJobPostAtsQuery(jobId, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  const { data: interviewPlan, refetch: interviewPlanRefetch } = useFetchInterviewPlanQuery(interviewPlanId, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  const { data: candidates, refetch: candidatesRefetch } = useFetchJobPostCandidatesAtsQuery(jobId, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  } );

  const { applications } = candidates || {};

  const [createSessionForApp, { data: appSessionData, error: appSessionError }] = useCreateSessionForAppMutation();
  const [updatePlanIdSession, { data: updatedSessionData, error: updatedSessionError }] = useUpdateSessionPlanIdMutation();

  useEffect(() => {
    if (jobPost && jobPost.interviewPlanId) {
      setInterviewPlanId(jobPost.interviewPlanId);
    }
  } , [jobPost])


  const { jobInterviewStages } = jobPost || {};

  const { session } = application || {};

  const [updateStatusApplicantAts, { isLoading, error }] = useUpdateStatusApplicantAtsMutation();
  const convertToPercentage = (number = 0) => {
    if (number < 1 || number > 5) {
      return 0;
    }
    return Math.floor(((number - 1) / 4) * 100);
  };

  useEffect(() => {
    if(processData) {
      processData.map((candidate, idx) => {
        if(candidate._id === applicationId) {
          setCandidateIndex(idx)
        }
        return candidate
      })
    }
  }, [navigate])

  // useEffect(() => {
  //   refetch();
  //   welshRefetch();
  // }, [refetch, welshRefetch]);

  const renderDonutChart = () => {
    if (Array.isArray(rows) && rows.length > 0) {
      let average = 0;
      let notRatedCount = 0;
      rows.forEach(({ isNotRated, ocr = 0 }) => {
        average += isNotRated ? 0 : ocr;

        if(isNotRated) {
          notRatedCount += 1
        }
      })
      average /= (rows.length - notRatedCount);
      return (
        <DonutChart num={convertToPercentage(average)} label={potentialFit} />
      )
    }
    return <DonutChart num={0} label='Potential Fit' />
  }

  const renderQuestionRatings = () => {
    if (Array.isArray(rows) && rows.length > 0) {
      let average = 0;
      let notRatedCount = 0;
      rows.forEach(({ isNotRated, ocr = 0 }) => {
        average += isNotRated ? 0 : ocr;

        if(isNotRated) {
          notRatedCount += 1
        }
      })
      average /= (rows.length - notRatedCount);

      return (
        <Box sx={{ marginLeft: '20px' }}>
          <Typography sx={{ marginBottom: '12px', fontWeight: 600 }} variant='body1'>Smart Candidate Rating: <b>{convertToPercentage(average)}%</b></Typography>
          {rows.map((q, i) => (
            <Typography key={i} sx={{ marginBottom: '5px' }} variant='body1'>
              Question {i + 1}: <b>{q?.isNotRated ? NOT_RATED : `${convertToPercentage(q.ocr)}%`}</b>
            </Typography>
          ))}
        </Box>
      )
    }

    return (
      <Box sx={{ marginLeft: '20px' }}>
        <Typography sx={{ marginBottom: '12px', fontWeight: 600 }} variant='body1'>Smart Candidate Ratings: <b>N/A</b></Typography>
      </Box>
    )
  }

  const handleTabChange = (event, newTabValue) => {
    setTabActive(newTabValue);
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const prevButtonClick = () => {
    if(processData) {
      navigate(`/dashboard/jobs/${jobId}/${processData[candidateIndex-1].idFromMerge}`);
    }
    else navigate(`/dashboard/jobs/${jobId}/${applications[candidateIndex-1].idFromMerge}`);
  }

  const nextButtonClick = () => {
    if(processData) {
      navigate(`/dashboard/jobs/${jobId}/${processData[candidateIndex+1].idFromMerge}`);
    }
    else navigate(`/dashboard/jobs/${jobId}/${applications[candidateIndex+1].idFromMerge}`);

  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // Helper to handle session retrieval or creation
  const getSessionId = async (applicationId, interviewPlanId) => {
    if (session) {
      if(session.planId !== interviewPlanId) {
        await updatePlanIdSession({ sessionId: session._id, planId: interviewPlanId });
      }
      return application.sessionId;
    } else {
      const newSession = await createSessionForApp({ planId: interviewPlanId, applicationId });
      if (newSession.error) {
        console.error('Error creating new session', newSession.error);
        return null;
      }
      return newSession.data.sessionId;
    }
  };

// Helper to create a URL based on the session
  const createSessionUrl = (sessionId) => {
    const { origin } = window.location;
    return `${origin}/screening/${sessionId}`;
  };

// Send an email with the session ID
  const setUpSendEmail = async (applicationId, accessToken, interviewPlanId) => {
    if (!interviewPlanId) return;
    const sessionId = await getSessionId(applicationId, interviewPlanId);
    if (!sessionId) return;
    await sendEmail(sessionId, accessToken);
    await applicationRefetch();
  };

// Open a new link based on the session
  const openNewLinkWith = (applicationId, interviewPlanId) => async () => {
    console.log('whats')
    debugger
    if (!interviewPlanId) return;
    const sessionId = await getSessionId(applicationId, interviewPlanId);

    if (!sessionId) return;
    const url = createSessionUrl(sessionId);
    window.open(url, '_blank');
    await applicationRefetch();
  };

// Copy session ID to the clipboard
  const copySessionId = (applicationId, interviewPlanId) => async () => {
    const sessionId = await getSessionId(applicationId, interviewPlanId);

    if (!sessionId) return;
    const url = createSessionUrl(sessionId);
    if (navigator.clipboard) {
      navigator.clipboard.writeText(url)
          .then(() => console.log('URL copied to clipboard'))
          .catch(err => console.error('Could not copy text: ', err));
    } else {
      console.warn('Clipboard API not available');
    }
    await applicationRefetch();
  };


  function applySortFilter(array, comparator, query) {
    if (!array) {
      return [];
    }
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const handleStatusChange = (event) => {
    console.log('event', event.target.value)
    updateStatusApplicantAts({ applicationId, stageId: event.target.value, email: userInfo.email })
  }
  const handleFilterByName = (event) => {
    // setPage(0);
    // setFilterName(event.target.value);
  };

  useEffect(() => {
    const generateRows = (assessment, answers, questions) => {
      if (!questions) return [];
      return questions.map((qna, index) => ({
        isNotRated: qna?.isNotRated,
        question: qna.question || '',
        candidateAnswer: answers[index]?.transcript || '',
        explain: assessment[index]?.feedback || '',
        ocr: assessment[index]?.score || '',
        id: index
      }));
    };

    function updateRows() {
      if (!interviewPlan && !session) {
        setRows([]);
        return;
      }

      if (!session) {
        setRows([]);
        return;
      }

      let questions = null;

      if (session && interviewPlan && session.planId === interviewPlan._id) {
        questions = interviewPlan.questions;
      }

      else if (session && interviewPlan && session.planId !== interviewPlan._id) {
        questions = session.oldPlan?.questions;
      }

      const receivedRows = generateRows(session.assessment, session.answers, questions);
      setRows(receivedRows);
    }

    updateRows();
  }, [session, interviewPlan]);
  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const isNotFound = !filteredUsers.length && !!filterName;

  const data = {
    labels: ['Red', 'Yellow', 'Blue'],
    datasets: [{
      data: [10, 20, 30],
      backgroundColor: ['red', 'yellow', 'blue']
    }]
  };

  let emailSent = session?.history?.sent
  if(emailSent) emailSent = moment(session?.history?.sent).format('MM/DD/YY, h:mm a')

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Grid item xs={12} sx={{ marginBottom: '10px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={() => {
              navigate(`/dashboard/jobs/${jobId}/`);
            }} variant='contained' startIcon={<Iconify icon='icon-park-outline:back-one' />}>
              Back
            </Button>
            <Grid item>
              <Button onClick={prevButtonClick} disabled={candidateIndex === 0} variant='contained' startIcon={<Iconify icon='ooui:previous-ltr' />}>
                Prev
              </Button>
              <Button
                disabled={processData && processData.length - 1 === candidateIndex}
                style={{ marginLeft: '10px' }} onClick={nextButtonClick}
                variant='contained' endIcon={<Iconify icon='ooui:next-ltr' />}>
                Next
              </Button>
            </Grid>
          </div>
        </Grid>
        <Card sx={{ padding: '20px' }}>
          <Grid container >
            <Grid item xs={12} >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar sx={{ bgcolor: '#2D82FE', marginRight: '14px' }}>J</Avatar>
                <Typography variant='h5'>
                  {`${application?.candidate.firstName} ${application?.candidate.lastName}`}
                </Typography>
              </div>

              <Grid sx={{ marginTop: '25px' }} container>

                {/* <Grid item xs={3}> */}
                {/*    <Typography variant='body1'> */}
                {/*        Phone Number */}
                {/*    </Typography> */}
                {/*    <Typography variant='subtitle1'> */}
                {/*        (805) 668-9895 */}
                {/*    </Typography> */}
                {/* </Grid> */}
                <Grid item xs={2}>
                  <Typography variant='body1'>
                    Role
                  </Typography>
                  <Typography variant='subtitle1'>
                    {application?.job?.name}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant='body1'>
                    ATS Status
                  </Typography>
                  <Typography variant='subtitle1' style={{ wordBreak: 'break-word' }}>
                    <Select
                      labelId="applicant-status-select-label"
                      id="applicant-status-select"
                      value={application?.currentStage?.id || '0'}
                      label="Status"
                      onChange={handleStatusChange}
                      sx={{marginBottom: '20px'}}
                    >
                      {
                        jobInterviewStages?.map((stage, index) => (
                          <MenuItem key={index} value={stage.id}>{stage.name}</MenuItem>
                        ))
                      }
                    </Select>
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant='body1'>
                    Contact Email
                  </Typography>
                  <Typography variant='subtitle1' style={{ wordBreak: 'break-word' }}>
                    {application?.candidate?.emailAddresses[0]?.value}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {
                      emailSent ? <Typography variant='subtitle1'>
                        Interview Link was sent via email {emailSent}
                      </Typography> : <Typography variant='subtitle1'>
                        Interview Link has not been sent via email
                      </Typography>
                    }

                    <div style={{ display: 'flex', gap: '10px' }}>
                      <Button variant='outlined' endIcon={<ContentCopyIcon />} onClick={copySessionId(applicationId, interviewPlanId)}>
                        Copy Interview Link
                      </Button>
                      <Button variant='contained' endIcon={<OpenInNewIcon />} onClick={() => {

                        openNewLinkWith(applicationId, interviewPlanId)();
                      }}>
                        Open in New Tab
                      </Button>
                      <Button variant='contained' endIcon={<OpenInNewIcon />} onClick={() => setUpSendEmail(applicationId, getAccessTokenSilently(), interviewPlanId)}>
                        Send Email
                      </Button>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Card>
      </Grid>
      <Grid item xs={12}>
        <Grid container columnSpacing={2} >

          <Grid item xs={6}>
            <Card sx={{ height: '100%' }}>
              <Grid container spacing={3}>
                {/* Candidate Info */}
                {/* Create Role Button */}

                <Grid item xs={12} className={styles.candidateHeader}>
                  <div className={styles.candidateInfo}>
                    <div className={styles.candidateContact}>
                      <Typography variant='h6'>Candidate Analysis</Typography>
                    </div>
                  </div>
                </Grid>



                {/* Candidate Ranking and Scores */}
                <Grid id='tab-content' item xs={12} className={styles.candidateRanking}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '10px', marginLeft: '20px' }}>
                    <Tabs value={tabActive} onChange={handleTabChange} aria-label='candidate tabs'>
                      <Tab label='SmartScreen Rating' />
                      {/* <Tab label='Summary' /> */}
                      {/* <Tab label='Notes' /> */}
                      {/* Add more tabs as needed */}
                    </Tabs>
                  </Box>
                  {tabActive === 0 && (
                    <div className={styles.candidateTabContent}>
                      {renderQuestionRatings()}
                      {renderDonutChart()}
                    </div>

                  )}
                  {/* {tabActive === 1 && (
										<div className={styles.candidateTabContent}>
											<Box sx={{ marginLeft: '20px', height: '300px' }}>
												<Typography sx={{ marginBottom: '12px', fontWeight: 600 }} variant='body1'>Summary:</Typography>
												<Typography sx={{ marginBottom: '5px' }} variant='body1'>Salary Expectations - <b>$60,000</b></Typography>
												<Typography sx={{ marginBottom: '5px' }} variant='body1'>Willing to Relocate - <b>Yes</b></Typography>
												<Typography sx={{ marginBottom: '5px' }} variant='body1'>Red Flags - <b>None</b></Typography>
											</Box>
										</div>
									)}
									{tabActive === 2 && (
										<div className={styles.candidateTabContent}>
											<Box sx={{ marginLeft: '20px', height: '300px' }}>
												<Typography sx={{ marginBottom: '12px', fontWeight: 600 }} variant='body1'>Notes:</Typography>
												<Typography sx={{ marginBottom: '5px' }} variant='body1'>08/26/2023 - Candidate is available after Sep. 14th</Typography>
												<Typography sx={{ marginBottom: '5px' }} variant='body1'>08/23/2023 - Candidate is very responsive</Typography>
											</Box>
										</div>
									)} */}
                  {/* Add more tab content as needed */}
                </Grid>
              </Grid>
            </Card>
          </Grid>


          <Grid item xs={6}>
            <Card sx={{ height: '100%', padding: '0px 20px' }}>
              <ChatBox sessionId={session?._id} />
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container columnSpacing={2} rowSpacing={{ xs: 2 }} xs={12}>
          {/* { */}
          {/*	localStorage.getItem('welsh-ai-enable') && ( */}
          {/*		<Grid item xs={12} sm={12} md={12}> */}
          {/*			<Card sx={{ padding: '20px' }}> */}
          {/*				<Grid container> */}
          {/*					<Grid item xs={12}> */}
          {/*						<Typography variant='h5' marginBottom="20px"> */}
          {/*							Tax Credit Eligibility Report Summary */}
          {/*						</Typography> */}
          {/*						<Typography variant='h6'> */}
          {/*							{welshSession?.answer} */}
          {/*						</Typography> */}
          {/*						<Typography variant='body'> */}
          {/*							{welshSession?.explainRating} */}
          {/*						</Typography> */}
          {/*					</Grid> */}
          {/*				</Grid> */}
          {/*			</Card> */}
          {/*		</Grid> */}
          {/*	) */ }
          {/* } */ }
          <Grid item xs={12} sm={12} md={12}>
            <Card>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={rows.length}
                      numSelected={selected.length}
                      sortable={false}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                        const { id, isNotRated, name, ocr, question, candidateAnswer, explain } = row;
                        const selectedUser = selected.indexOf(name) !== -1;
                        return (
                          <TableRow hover key={id} tabIndex={-1} role='checkbox' selected={selectedUser}>
                            {/* <TableCell padding='checkbox'>
															<Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
														</TableCell> */}
                            <TableCell component='th' scope='row'>
                              {index + 1}. {question}
                            </TableCell>

                            <TableCell align='left'>{candidateAnswer}</TableCell>

                            <TableCell align='left'>
                              <Typography variant='body2' sx={{ minWidth: 100 }}>
                                {isNotRated ? NOT_RATED : explain}
                              </Typography>
                            </TableCell>

                            <TableCell align='left'>
                              {isNotRated ? NOT_RATED : <Rating name='read-only' value={ocr} readOnly />}
                            </TableCell>


                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align='center' colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant='h6' paragraph>
                                Not found
                              </Typography>

                              <Typography variant='body2'>
                                No results found for &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Try checking for typos or using complete words.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  );
}

export default JobPostSession;
