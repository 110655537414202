import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Typography,
  Grid,
  Container,
  Box,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { useAddNewWelshClientMutation } from 'store/api';


const AddWelshClient = () => {
  const [companyName, setCompanyName] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [companyDescription, setCompanyDescription] = useState('');
  const [addNewWelshClient, { isSuccess: addedWelshClientSuccess, error: addWelshClientError, data: newWelshClient, isLoading }] = useAddNewWelshClientMutation();
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (newWelshClient && newWelshClient.response) {
      navigate(`/dashboard/agent-welsh/client/${newWelshClient.response.insertedId}`)
    }
  }, [addedWelshClientSuccess]);

  return (
    <>
      <Helmet>
        <title>Agent Welsh</title>
      </Helmet>
      <Container id="add-welsh-container" style={{ background: '#F9F9F9', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ padding: '40px', backgroundColor: '#FFF', borderRadius: '8px', boxShadow: theme.shadows[3], maxWidth: '500px', width: '100%' }}>
          <Typography variant="h5" gutterBottom align="left">
            Add a new client
          </Typography>
          <Typography variant="subtitle1" gutterBottom align="left" sx={{ fontWeight: 300 }}>
            Only requirement is company name
          </Typography>

          <Grid container spacing={2} sx={{ marginTop: '20px' }} rowGap='15px'>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Company Name"
                variant="outlined"
                fullWidth
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Company Website (optional)"
                variant="outlined"
                fullWidth
                value={companyWebsite}
                onChange={(e) => setCompanyWebsite(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Company Description (optional)"
                variant="outlined"
                fullWidth
                multiline
                minRows={3}
                maxRows={4}
                value={companyDescription}
                onChange={(e) => setCompanyDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => addNewWelshClient({ companyName, companyWebsite, companyDescription })}
                startIcon={isLoading && <CircularProgress size={30}/>}
              >
                Submit
              </Button>

            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default AddWelshClient;
