import { User } from '@auth0/auth0-react';
import { PayloadAction, createSlice, createSelector } from '@reduxjs/toolkit';

export type Company = {
  display_name: string;
  metadata: {};
}

export type TbUser = User & {
  tb_org?: Company;
  token?: string;
}

const initialState: TbUser = {
  name: undefined,
  email: undefined,
  nickname: undefined,
  preferred_username: undefined,
  picture: undefined,
  token: undefined,
  tb_org: undefined
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<TbUser>) => payload
  },
})

export const companyName = createSelector(
  (state: { user: User }) => state.user.tb_org ?? {},
  (company: Company) => company.display_name ?? 'Teambooster'
);

export const usernameSelector = createSelector(
  (state: { user: User }) => state.user,
  (user: User) => user?.nickname?.replaceAll('.', ' ')?.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
  ));

export const userInfoSelector = createSelector(
    (state: { user: User }) => state.user,
    (user: User) => user
);


export const { setUser } = userSlice.actions
