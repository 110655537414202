import { PayloadAction, createSlice, createSelector } from '@reduxjs/toolkit';
import { User } from '@auth0/auth0-react';


export interface Company {
    companyId: string | undefined,
    integrationDetails: object | undefined
}

const initialState: Company = {
    companyId: undefined,
    integrationDetails: undefined
}

export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        loadCompany: (state, { payload }: PayloadAction<Company>) => payload
    }
})


export const companyATSDetailsSelector = createSelector(
  // First, select the part of the state you're interested in, which is the company state
  (state: { company: Company }) => state.company,
  // Then, from the company state, extract the integrationDetails
  (company) => ({ ...company, ...company.integrationDetails })
);

export const { loadCompany } = companySlice.actions
