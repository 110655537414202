import { Box, Typography, CircularProgress, Button, Container, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';

const baseStyle = (theme) => ({
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: theme.palette.secondary,
    color: theme.palette.grey[600],
    transition: 'border .3s ease-in-out'
});

const activeStyle = (theme) => ({
    // borderColor: '#2196f3'
});

const acceptStyle = (theme) => ({
    borderColor: theme.palette.success.main
});

const rejectStyle = (theme) => ({
    borderColor: theme.palette.warning.main
});

const DropzoneAreaInterview = ({ onDrop, sx }) => {
  const [files, setFiles] = useState([]);

    const { getRootProps, getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        multiple: false,
        onDrop: acceptedFiles => {
          setFiles(acceptedFiles);
          onDrop(acceptedFiles);
        },
        accept: { 'text/*': ['.txt'] },
    });
    const theme = useTheme();

    const style = useMemo(() => ({
        ...baseStyle(theme),
        ...(isDragActive ? activeStyle(theme) : {}),
        ...(isDragAccept ? acceptStyle(theme) : {}),
        ...(isDragReject ? rejectStyle(theme) : {})
    }), [
        theme,
        isDragActive,
        isDragReject,
        isDragAccept
    ]);
    const thumbs = files.map(file => (
      <div key={file.name}>
        <div>
          {file.path} - {file.size} bytes
        </div>
      </div>
    ));

    return (

        <Box sx={sx}>
            <Box {...getRootProps({ style })} p={10}>
                <input {...getInputProps()} />
                <Typography variant="subtitle1">Drop your resume here, or click to select upload resume</Typography>
                <Typography>
                    Accepted file types: <strong>.txt</strong>
                </Typography>
                <Typography>
                    Maximum file size: <strong>20MB</strong>
                </Typography>
            </Box>
            <aside>
              {thumbs}
            </aside>
        </Box>
    );


}
export default DropzoneAreaInterview;
