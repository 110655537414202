import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

export const Auth0ProviderWithRedirectCallback = ({ children, domain, clientId, ...props }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState, user) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider domain={domain} clientId={clientId} onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};