import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useState, useEffect } from "react";
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import { usernameSelector } from 'store/user';

// sections
import { useFetchSessionsStatsQuery } from 'store/api';
import {
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary,
} from 'sections/@dashboard/app';

// ----------------------------------------------------------------------

type PlanBySessionCount = { label: string, value: number };

const monthNames = [] as string[];
for (let i = 0; i < 12; i += 1) {
  const d = new Date();
  d.setMonth(d.getMonth() - i);
  d.setDate(1);

  monthNames[i] = d.toLocaleString('default', { day: '2-digit', month: 'short', year: 'numeric' });
}

type TimeSeries = {sent: number[], archived: number[], completed: number[], created: number[], started: number[]};
const DashboardAppPage = () => {
  const theme = useTheme();
  const username = useSelector(usernameSelector);
  const [interviewPlansToSessions, setInterviewPlansToSessions] = useState<PlanBySessionCount[]>([]);
  const [timeseries, setTimeseries] = useState({} as TimeSeries);


  const { data } = useFetchSessionsStatsQuery(undefined, {
    refetchOnMountOrArgChange: true,
    pollingInterval: 60000,
  });

  useEffect(() => {
    (async () => {
      if (!data) return;
      const { created } = data;

      let plansBySessions = Object.entries(created.aggregateByRole).map(([label, value]) => (
        { label, value } as PlanBySessionCount
      ))
      plansBySessions.sort((a, b) => b.value - a.value);
      plansBySessions = plansBySessions.slice(0, 7);
      const totalInTop = plansBySessions.reduce((acc, plan) => plan.value + acc, 0);
      plansBySessions.push({ label: 'other', value: 100 - totalInTop });

      setInterviewPlansToSessions(plansBySessions);


      const now = new Date();

      const result = {} as TimeSeries;
      Object.entries(data).forEach(([key]) => {
        result[key] = [] as number[];
        for (let i = 0; i < 12; i += 1) {
          result[key][i] = 0;
        }
      });

      Object.entries(data).forEach(([key, value]) => {
        result[key] = value.history.reduce((acc, dateString) => {
          if (!dateString) return acc;
          const date = new Date(dateString);
          const pos = now.getMonth() - date.getMonth();
          if (acc[pos] !== undefined) {
            acc[pos] += 1;
          }
          return acc;

        }, result[key]);
      });
      setTimeseries(result);

    })();
  }, [data]);

  // const activeInterviewsPieData = () => interviewPlansToSessions.map(plan => ({
  //   label: plan?.role,
  //   value: plan?.completedCount
  // }));

  return (
    <>
      <Helmet>
        <title> Home | Teambooster AI </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi {username}, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Scheduled Interviews" total={data?.created?.total || 0} icon={'mdi:calendar-multiple'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Completed Interviews" total={data?.completed?.total || 0} color="info" icon={'mdi:check-circle'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Pending Interviews" total={data?.sent?.total || 0} color="warning" icon={'mdi:clock'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Deleted Interviews" total={data?.archived?.total || 0} color="error" icon={'mdi:cancel'} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Interviews"
              subheader="(+12%) than last month"
              chartLabels={monthNames}
              chartData={[
                {
                  name: 'Created',
                  type: 'column',
                  fill: 'solid',
                  data: timeseries.created,
                },
                {
                  name: 'Complete',
                  type: 'area',
                  fill: 'gradient',
                  data: timeseries.completed,
                },
                {
                  name: 'Pending',
                  type: 'line',
                  fill: 'solid',
                  data: timeseries.sent,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Most Active Interviews"
              chartData={interviewPlansToSessions}
              subheader="Number of interviews completed last month"
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>

        </Grid>

      </Container>
    </>
  );
}


export default DashboardAppPage;