import { useParams, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { filter } from 'lodash';
import moment from 'moment-mini';
import _ from 'lodash';
import {
	Avatar, Box,
	Card,
	Checkbox,
	Container,
	Grid, IconButton, Paper, Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer, TablePagination,
	TableRow,
	Typography,
	Rating,
	Button,
	Tabs, Tab
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useAuth0 } from '@auth0/auth0-react';


import { sendEmail } from 'utils/service';
import { useFetchSessionQuery, useFetchSessionsQuery, useFetchWelshSessionQuery } from 'store/api';
import { UserListHead } from '../sections/@dashboard/candidate';
import Scrollbar from '../components/scrollbar/Scrollbar';
import Iconify from '../components/iconify';
import ChatBox from './Chatbox';
import styles from './Home.module.css';
import DonutChart from '../components/DonutChart';



const TABLE_HEAD = [
	{ id: 'question', label: 'Question', alignRight: false },
	{ id: 'candidateAnswer', label: "Candidate's Answer", alignRight: false },
	{ id: 'explain', label: 'Rating Explained', alignRight: false },
	{ id: 'ocr', label: 'Answer Rating', alignRight: false },
];

function InterviewSession() {
	const navigate = useNavigate();
	const metadata = useSelector((state) => state.interviewPlan.metadata)
	const processData = useSelector((state) => state.interviewPlan.processedData)
	const { id } = useParams();
	const [open, setOpen] = useState(null);
	const [page, setPage] = useState(0);
	const [order, setOrder] = useState('asc');
	const [selected, setSelected] = useState([]);
	const [orderBy, setOrderBy] = useState('name');
	const [filterName, setFilterName] = useState('');
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [tabActive, setTabActive] = useState(0);
	const [rows, setRows] = useState([]);
	const [potentialFit, setPotentialFit] = useState('Potential Fit')
	const [averageCalc, setAverageCalc] = useState(0)
	const [candidateIndex, setCandidateIndex] = useState(0)
	const { getAccessTokenSilently } = useAuth0()
	const NOT_RATED = "Not Rated"



  const { data: session, refetch } = useFetchSessionQuery(id, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    pollingInterval: 60000,
  });

  // const { data: welshSession, refetch: welshRefetch } = useFetchWelshSessionQuery(id, {
  //   refetchOnMountOrArgChange: true,
  //   refetchOnReconnect: true,
  // });


  const isWelsh = session?.plan.role === 'Welsh AI';
  const convertToPercentage = (number = 0) => {
    if (number < 1 || number > 5) {
      return 0;
    }
    return Math.floor(((number - 1) / 4) * 100);
  };

	useEffect(() => {
		if(processData) {
			processData.map((candidate, idx) => {
				if(candidate._id === id) {
					setCandidateIndex(idx)
				}
				return candidate
			})
		}
	}, [navigate])

  // useEffect(() => {
  //   refetch();
  //   welshRefetch();
  // }, [refetch, welshRefetch]);

	const renderDonutChart = () => {
		if (Array.isArray(rows) && rows.length > 0) {
			let average = 0;
			let notRatedCount = 0;
			rows.forEach(({ isNotRated, ocr = 0 }) => {
				average += isNotRated ? 0 : ocr;

				if(isNotRated) {
					notRatedCount += 1
				}
			})
			average /= (rows.length - notRatedCount);
			return (
				<DonutChart num={convertToPercentage(average)} label={potentialFit} />
			)
		}
		return <DonutChart num={0} label='Potential Fit' />
	}

	const renderQuestionRatings = () => {
		if (Array.isArray(rows) && rows.length > 0) {
			let average = 0;
			let notRatedCount = 0;
			rows.forEach(({ isNotRated, ocr = 0 }) => {
				average += isNotRated ? 0 : ocr;

				if(isNotRated) {
					notRatedCount += 1
				}
			})
			average /= (rows.length - notRatedCount);

			return (
				<Box sx={{ marginLeft: '20px' }}>
					<Typography sx={{ marginBottom: '12px', fontWeight: 600 }} variant='body1'>Smart Candidate Rating: <b>{convertToPercentage(average)}%</b></Typography>
					{rows.map((q, i) => (
						<Typography key={i} sx={{ marginBottom: '5px' }} variant='body1'>
							Question {i + 1}: <b>{q?.isNotRated ? NOT_RATED : `${convertToPercentage(q.ocr)}%`}</b>
						</Typography>
					))}
				</Box>
			)
		}

		return (
			<Box sx={{ marginLeft: '20px' }}>
				<Typography sx={{ marginBottom: '12px', fontWeight: 600 }} variant='body1'>Smart Candidate Ratingss: <b>N/A</b></Typography>
			</Box>
		)
	}

	const handleTabChange = (event, newTabValue) => {
		setTabActive(newTabValue);
	};

	const handleOpenMenu = (event) => {
		setOpen(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setOpen(null);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = rows.map((n) => n.name);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}
		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setPage(0);
		setRowsPerPage(parseInt(event.target.value, 10));
	};

	const prevButtonClick = () => {
		navigate(`/dashboard/interview/session/${processData[candidateIndex-1]._id}`);
	}

	const nextButtonClick = () => {
		navigate(`/dashboard/interview/session/${processData[candidateIndex+1]._id}`);
	}

	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}

	function getComparator(order, orderBy) {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

  const CopySessionId = (sessionId) => () => {
    if (sessionId) {
      const {origin} = window.location;
      let url = `${origin}/screening/${sessionId}`;
      if(isWelsh) {
        url = `${origin}/welshscreening/${sessionId}`;
      }
      if (navigator.clipboard) {
        navigator.clipboard.writeText(url)
          .then(() => {
            console.log('URL copied to clipboard');
          })
          .catch(err => {
            console.error('Could not copy text: ', err);
          });
      } else {
        console.warn('Clipboard API not available');
      }
    }
  }

  const openNewLinkWith = (sessionId) => () => {
    if (sessionId) {
      const {origin} = window.location;
      let url = `${origin}/screening/${sessionId}`;

      if(isWelsh) {
        url = `${origin}/welshscreening/${sessionId}`;
      }
      window.open(url, '_blank');
    }
  }

	function applySortFilter(array, comparator, query) {
		if (!array) {
			return [];
		}
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		if (query) {
			return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
		}
		return stabilizedThis.map((el) => el[0]);
	}

	const handleFilterByName = (event) => {
		// setPage(0);
		// setFilterName(event.target.value);
	};

	useEffect(() => {
		(async () => {
			let questions = session?.plan.questions || [];
			const sessionVersion = session?.version;
			if(_.find(session?.plan?.previousQuestions, { version: sessionVersion })) {
				questions = _.find(session?.plan?.previousQuestions, { version: sessionVersion })?.questions || [];
			}

			const receivedRows = questions.map((qna, index) => ({
					isNotRated: qna?.isNotRated,
					question: qna.question,
					answer: qna.expectedAnswer,
					candidateAnswer: session.answers ? session.answers[index]?.transcript : '',
					explain: session.assessment ? session.assessment[index]?.feedback : '',
					ocr: session.assessment ? session.assessment[index]?.score : '',
					id: index
				})) ?? [];
			setRows(receivedRows)
		})();
	}, [session]);
	const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const isNotFound = !filteredUsers.length && !!filterName;

	// Now you can use the `id` to fetch and display the interview details

	const data = {
		labels: ['Red', 'Yellow', 'Blue'],
		datasets: [{
			data: [10, 20, 30],
			backgroundColor: ['red', 'yellow', 'blue']
		}]
	};

	const sessionId = session?._id;
	let emailSent = session?.history?.sent
	if(emailSent) emailSent = moment(session?.history?.sent).format('MM/DD/YY, h:mm a')
	return (
		<Grid container rowSpacing={2}>
			<Grid item xs={12}>
				<Grid item xs={12} sx={{ marginBottom: '10px' }}>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Button onClick={() => navigate(-1)} variant='contained' startIcon={<Iconify icon='icon-park-outline:back-one' />}>
							 Back
						</Button>
						<Grid item>
							<Button onClick={prevButtonClick} disabled={candidateIndex === 0} variant='contained' startIcon={<Iconify icon='ooui:previous-ltr' />}>
									Prev
							</Button>
							<Button
								disabled={processData && processData.length - 1 === candidateIndex}
								style={{ marginLeft: '10px' }} onClick={nextButtonClick}
								variant='contained' endIcon={<Iconify icon='ooui:next-ltr' />}>
									Next
							</Button>
						</Grid>
					</div>
				</Grid>
				<Card sx={{ padding: '20px' }}>
					<Grid container >
						<Grid item xs={12} >
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Avatar sx={{ bgcolor: '#2D82FE', marginRight: '14px' }}>J</Avatar>
								<Typography variant='h5'>
									{session?.candidate.name}
								</Typography>
							</div>

							<Grid sx={{ marginTop: '25px' }} container columnGap='5px'>

								<Grid item xs={2}>
									<Typography variant='body1'>
										Role
									</Typography>
									<Typography variant='subtitle1'>
										{session?.plan.role}
									</Typography>
								</Grid>
								<Grid item xs={2}>
									<Typography variant='body1'>
										Contact Email
									</Typography>
									<Typography variant='subtitle1' style={{ wordBreak: 'break-word' }}>
										{session?.candidate.email}
									</Typography>
								</Grid>
								{
									session?.candidate?.email && (
										<Grid item xs={2}>
											<Typography variant='body1'>
												Email Sent
											</Typography>
											<Typography variant='subtitle1' style={{ wordBreak: 'break-word' }}>
												{
													emailSent ? <Typography variant='subtitle1'>
														{emailSent}
													</Typography> : <Typography variant='subtitle1'>
														Not sent
													</Typography>
												}
											</Typography>
										</Grid>
									)
								}
								<Grid item xs={5}>
									<Box sx={{ display: 'flex', flexDirection: 'column' }}>
										<div style={{ display: 'flex', gap: '10px' }}>
											<Button variant='outlined' endIcon={<ContentCopyIcon />} onClick={CopySessionId(sessionId)}>
												Copy Interview Link
											</Button>
											<Button variant='contained' endIcon={<OpenInNewIcon />} onClick={openNewLinkWith(sessionId)}>
												Open New Interview Tab
											</Button>
											<Button variant='contained' endIcon={<OpenInNewIcon />} onClick={() => sendEmail(sessionId, getAccessTokenSilently())}>
												Send Interview Email
											</Button>
										</div>
									</Box>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

				</Card>
			</Grid>
			<Grid item xs={12}>
				<Grid container columnSpacing={2} >

					<Grid item xs={6}>
						<Card sx={{ height: '100%' }}>
							<Grid container spacing={3}>
								{/* Candidate Info */}
								{/* Create Role Button */}

								<Grid item xs={12} className={styles.candidateHeader}>
									<div className={styles.candidateInfo}>
										<div className={styles.candidateContact}>
											<Typography variant='h6'>Candidate Analysis</Typography>
										</div>
									</div>
								</Grid>



								{/* Candidate Ranking and Scores */}
								<Grid id='tab-content' item xs={12} className={styles.candidateRanking}>
									<Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '10px', marginLeft: '20px' }}>
										<Tabs value={tabActive} onChange={handleTabChange} aria-label='candidate tabs'>
											<Tab label='SmartScreen Rating' />
											{/* <Tab label='Summary' /> */}
											{/* <Tab label='Notes' /> */}
											{/* Add more tabs as needed */}
										</Tabs>
									</Box>
									{tabActive === 0 && (
										<div className={styles.candidateTabContent}>
											{renderQuestionRatings()}
											{renderDonutChart()}
										</div>

									)}
									{/* {tabActive === 1 && (
										<div className={styles.candidateTabContent}>
											<Box sx={{ marginLeft: '20px', height: '300px' }}>
												<Typography sx={{ marginBottom: '12px', fontWeight: 600 }} variant='body1'>Summary:</Typography>
												<Typography sx={{ marginBottom: '5px' }} variant='body1'>Salary Expectations - <b>$60,000</b></Typography>
												<Typography sx={{ marginBottom: '5px' }} variant='body1'>Willing to Relocate - <b>Yes</b></Typography>
												<Typography sx={{ marginBottom: '5px' }} variant='body1'>Red Flags - <b>None</b></Typography>
											</Box>
										</div>
									)}
									{tabActive === 2 && (
										<div className={styles.candidateTabContent}>
											<Box sx={{ marginLeft: '20px', height: '300px' }}>
												<Typography sx={{ marginBottom: '12px', fontWeight: 600 }} variant='body1'>Notes:</Typography>
												<Typography sx={{ marginBottom: '5px' }} variant='body1'>08/26/2023 - Candidate is available after Sep. 14th</Typography>
												<Typography sx={{ marginBottom: '5px' }} variant='body1'>08/23/2023 - Candidate is very responsive</Typography>
											</Box>
										</div>
									)} */}
									{/* Add more tab content as needed */}
								</Grid>
							</Grid>
						</Card>
					</Grid>


          <Grid item xs={6}>
            <Card sx={{ height: '100%', padding: '0px 20px' }}>
              <ChatBox sessionId={session?._id} />
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container columnSpacing={2} rowSpacing={{ xs: 2 }} xs={12}>
					{/* { */}
					{/*	localStorage.getItem('welsh-ai-enable') && ( */}
					{/*		<Grid item xs={12} sm={12} md={12}> */}
					{/*			<Card sx={{ padding: '20px' }}> */}
					{/*				<Grid container> */}
					{/*					<Grid item xs={12}> */}
					{/*						<Typography variant='h5' marginBottom="20px"> */}
					{/*							Tax Credit Eligibility Report Summary */}
					{/*						</Typography> */}
					{/*						<Typography variant='h6'> */}
					{/*							{welshSession?.answer} */}
					{/*						</Typography> */}
					{/*						<Typography variant='body'> */}
					{/*							{welshSession?.explainRating} */}
					{/*						</Typography> */}
					{/*					</Grid> */}
					{/*				</Grid> */}
					{/*			</Card> */}
					{/*		</Grid> */}
					{/*	) */ }
					{/* } */ }
          <Grid item xs={12} sm={12} md={12}>
            <Card>

							<Scrollbar>
								<TableContainer sx={{ minWidth: 800 }}>
									<Table>
										<UserListHead
											order={order}
											orderBy={orderBy}
											headLabel={TABLE_HEAD}
											rowCount={rows.length}
											numSelected={selected.length}
											sortable={false}
											onSelectAllClick={handleSelectAllClick}
										/>
										<TableBody>
											{filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
												const { id, isNotRated, name, ocr, question, answer, candidateAnswer, explain } = row;
												const selectedUser = selected.indexOf(name) !== -1;
												return (
													<TableRow hover key={id} tabIndex={-1} role='checkbox' selected={selectedUser}>
														<TableCell component='th' scope='row'>
															{index + 1}. {question}
														</TableCell>

														<TableCell align='left'>{candidateAnswer}</TableCell>

														<TableCell align='left'>
															<Typography variant='body2' sx={{ minWidth: 100 }}>
																{isNotRated ? NOT_RATED : explain}
															</Typography>
														</TableCell>

														<TableCell align='left'>
															{isNotRated ? NOT_RATED : <Rating name='read-only' value={ocr} readOnly />}
														</TableCell>


													</TableRow>
												);
											})}
											{emptyRows > 0 && (
												<TableRow style={{ height: 53 * emptyRows }}>
													<TableCell colSpan={6} />
												</TableRow>
											)}
										</TableBody>

										{isNotFound && (
											<TableBody>
												<TableRow>
													<TableCell align='center' colSpan={6} sx={{ py: 3 }}>
														<Paper
															sx={{
																textAlign: 'center',
															}}
														>
															<Typography variant='h6' paragraph>
																Not found
															</Typography>

															<Typography variant='body2'>
																No results found for &nbsp;
																<strong>&quot;{filterName}&quot;</strong>.
																<br /> Try checking for typos or using complete words.
															</Typography>
														</Paper>
													</TableCell>
												</TableRow>
											</TableBody>
										)}
									</Table>
								</TableContainer>
							</Scrollbar>

							<TablePagination
								rowsPerPageOptions={[5, 10, 25]}
								component='div'
								count={rows.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</Card>
					</Grid>
				</Grid>
			</Grid>

		</Grid>
	);
}

export default InterviewSession;
