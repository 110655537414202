import { useState } from 'react';
import { useForm, useController, UseControllerProps, SubmitHandler } from 'react-hook-form';
import {
  Autocomplete, DialogActions, DialogContent, TextField,
  Button,
  DialogTitle,
  TextFieldProps,
  Typography
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useSelector } from 'react-redux';

// components
import { Candidate } from 'store/candidates';
import DropzoneAreaInterview from 'components/interview-upload';
import { useAddCandidateMutation } from 'store/api';
import { companyName } from 'store/user';
import { sendEmail } from 'utils/service';
import { useAuth0 } from '@auth0/auth0-react';

function TextField2(props: UseControllerProps<Candidate> & Partial<TextFieldProps>) {
  const { ...controllerProps } = props as UseControllerProps<Candidate>;
  const { ...muiProps } = props as TextFieldProps;

  const { field, fieldState: { error } } = useController(controllerProps);

  return (
    <TextField
      {...field}
      {...muiProps}
      error={!!error}
    />
  );
}

const NewCandidate = ({ open, close, planId }) => {
  const [ addCandidate, ] = useAddCandidateMutation();
  const { getAccessTokenSilently } = useAuth0()
  const company = useSelector(companyName);

  const { handleSubmit, control, setValue, reset, watch } = useForm<Candidate>({
    defaultValues: {
      name: "",
      email: "",
      roles: [] as string[],
      client: company,
      status: 'active',
      planId,
      sendEmail: false,
    },
    mode: "onChange",
  });

  // Watch the 'name' field
  const nameValue = watch("name");

  const onSubmit: SubmitHandler<Candidate> = async (data) => {

    const response = await addCandidate(data);
    // Check if the response is a successful response
    if ('data' in response && response.data) {
      // @ts-ignore
      const email = response.data?.candidate?.email;
      // @ts-ignore
      const sessionId = response.data?.session?.insertedId;
      if(email) await sendEmail(sessionId, getAccessTokenSilently());

    } else if ('error' in response && response.error) {
      // Handle the error case here
      console.error('An error occurred:', response.error);

      // Depending on the error structure, access more detailed information if needed
      // ...
    }

    reset({
      name: "",
      email: "",
      roles: [] as string[],
      client: company,
      status: 'active',
      planId,
    });
    close();
  }

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>New Candidate</DialogTitle>
      <DialogContent>
        <TextField2
          control={control}
          name="name"
          label="Name"
          autoFocus
          margin="dense"
          type="name"
          fullWidth
          variant="standard"
        />
        <TextField2
          control={control}
          name="email"
          label="Email"
          margin="dense"
          type="email"
          fullWidth
          variant="standard"
        />
        <Typography variant="body1" marginTop={1}>Candidate's with email will be sent interview link</Typography>
        
        {/* <Autocomplete */}
        {/*  disabled */}
        {/*  id="team" */}
        {/*  options={[company]} */}
        {/*  fullWidth */}
        {/*  renderInput={(params) => <TextField label={company} variant="standard" {...params} />} */}
        {/* /> */ }
        <DropzoneAreaInterview sx={{ mt: 3 }} onDrop={(files: File) => setValue("resume", files)} />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        {/* Disable the button if 'name' field is empty */}
        <Button onClick={handleSubmit(onSubmit)} disabled={!nameValue || nameValue.trim() === ""}>Add</Button>
      </DialogActions>
    </Dialog>
  );
}

export const useNewCandidate = (planId?: string) => {
  const [visible, setVisible] = useState(false);

  const show = () => setVisible(true);
  const close = () => setVisible(false);
  return { visible, show, close, NewCandidate: <NewCandidate open={visible} close={close} planId={planId}/> };
};
