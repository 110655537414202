import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';

const MicrophoneSelect =  ({ onMicSelected, permissionGranted, onPermissionChange })  => {
  const [devices, setDevices] = useState([]);
  const [selectedMic, setSelectedMic] = useState('');

  // Function to fetch the list of microphones
  const fetchMicrophones = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true }); // Prompt for permission
      onPermissionChange(true);

      const devices = await navigator.mediaDevices.enumerateDevices();
      const mics = devices.filter(device => device.kind === 'audioinput');
      setDevices(mics);
      if (mics.length > 0) {
        setSelectedMic(mics[0].deviceId);
      }
    } catch (err) {
      console.error('Error fetching devices:', err);
      onPermissionChange(false);
    }
  };

  // Handles the selection of a microphone
  const handleMicChange = (event) => {
    setSelectedMic(event.target.value);
    if (onMicSelected) {
      onMicSelected(event.target.value);
    }
  };

  useEffect(() => {
    fetchMicrophones();
  }, []);

  return (
    <div>
      {!permissionGranted ? (
        <Button variant="contained" onClick={fetchMicrophones}>
          Grant Microphone Access
        </Button>
      ) : (
        <FormControl fullWidth>
          <InputLabel id="microphone-select-label">Choose a Microphone</InputLabel>
          <Select
            labelId="microphone-select-label"
            id="microphone-select"
            value={selectedMic}
            label="Choose a Microphone"
            onChange={handleMicChange}
          >
            {devices.map(device => (
              <MenuItem key={device.deviceId} value={device.deviceId}>
                {device.label || `Microphone ${device.deviceId}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
};

export default MicrophoneSelect;
