import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";
import styled from "styled-components";

const Thanks = styled.div`
  color: #0c1f3b;
  text-align: center;
`

const WaitingQueue = () => {
  const {logout} = useAuth0();

  const logoutAndNavigate = () => {
    // logout({logoutParams: {returnTo: window.location.origin}});
    logout();
  }
  return <Thanks>
    <h1>Thank you for choosing us! Your registration is greatly appreciated.</h1>
    <h2>Our dedicated Customer Service team is ready to rocket you through the upcoming steps.<br/>
    Prepare for liftoff – we're thrilled to have you on board!</h2>

    <Button sx={{fontSize: '1.4em'}} onClick={logoutAndNavigate}>Retry Login</Button>
  </Thanks>
}

export default WaitingQueue;