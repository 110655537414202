import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// icons
import { Icon, IconifyIcon } from '@iconify/react';
// @mui
import { Box, SxProps } from '@mui/material';

// ----------------------------------------------------------------------

type IconifyParamType = {
  sx?: SxProps,
  width: number | string,
  icon: IconifyIcon | string,
};

const Iconify = forwardRef(({ icon, width = 20, sx, ...other }: IconifyParamType, ref) => (
  <Box ref={ref} component={Icon} icon={icon} sx={{ width, height: width, ...sx }} {...other} />
));


export default Iconify;
